import PropTypes from "prop-types";
import React from "react";
import { Controller, useWatch } from "react-hook-form";

import countries from "../../../../../../../../common/countries-code";
import PaiInputs from "./pai";
import PaeInputs from "./pae";

import { Container, GridSelect, InputsContainer } from "./styled";

const PaInputs = ({ control, index, item, unregister }) => {
  const watchCountryId = useWatch({
    control,
    name: `details.soggetti.${index}.id_paese`,
  });

  return (
    <Container>
      <Controller
        name={`details.soggetti.${index}.id_paese`}
        control={control}
        defaultValue={item.id_paese || "IT"}
        render={({ field }) => (
          <GridSelect
            area={"country"}
            label={"Paese"}
            options={countries}
            placeholder={"Scegli"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <InputsContainer>
        {watchCountryId !== "IT" ? (
          <PaeInputs {...{ control, index, item, unregister }} />
        ) : (
          <PaiInputs {...{ control, index, item, unregister }} />
        )}
      </InputsContainer>
    </Container>
  );
};

PaInputs.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default PaInputs;
