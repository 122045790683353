import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

import { taxId, vatNumber } from "../../../../../../../../../utils/regex";
import ErrorLabel from "../../../../../../../../components/error-label";

import { Container, EmailsInput, GridInput } from "./styled";

const PaiInputs = ({ control, index, item, unregister }) => {
  useEffect(() => {
    return function cleanup() {
      unregister(
        [
          `details.soggetti.${index}.codice_ipa`,
          `details.soggetti.${index}.denominazione`,
          `details.soggetti.${index}.codice_ipa_aoo`,
          `details.soggetti.${index}.denominazione_amministrazione_aoo`,
          `details.soggetti.${index}.codice_ipa_uor`,
          `details.soggetti.${index}.denominazione_amministrazione_uor`,
          `details.soggetti.${index}.codice_fiscale`,
          `details.soggetti.${index}.partita_iva`,
          `details.soggetti.${index}.indirizzi_digitali_riferimento`,
        ],
        { keepValue: false }
      );
    };
  }, [index, unregister]);

  return (
    <Container>
      <Controller
        name={`details.soggetti.${index}.codice_ipa`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.codice_ipa || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"ipaCode"}
            id={`details.soggetti.${index}.codice_ipa`}
            label={"Codice IPA"}
            placeholder={"Inserisci il codice IPA"}
            required={true}
            error={!!error}
            errorMessage={<ErrorLabel>{"Inserisci il codice IPA"}</ErrorLabel>}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.denominazione`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.denominazione || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"name"}
            id={`details.soggetti.${index}.denominazione`}
            label={"Amministrazione"}
            placeholder={"Inserisci la denominazione dell’amministrazione"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {"Inserisci la denominazione dell’amministrazione"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_ipa_aoo`}
        control={control}
        defaultValue={item.codice_ipa_aoo || ""}
        render={({ field }) => (
          <GridInput
            area={"ipaCodeAoo"}
            id={`details.soggetti.${index}.codice_ipa_aoo`}
            label={"Codice AOO"}
            placeholder={"Inserisci il codice AOO"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.denominazione_amministrazione_aoo`}
        control={control}
        defaultValue={item.denominazione_amministrazione_aoo || ""}
        render={({ field }) => (
          <GridInput
            area={"nameAoo"}
            id={`details.soggetti.${index}.denominazione_amministrazione_aoo`}
            label={"Organizzazione"}
            placeholder={"Inserisci la denominazione dell’organizzazione"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_ipa_uor`}
        control={control}
        defaultValue={item.codice_ipa_uor || ""}
        render={({ field }) => (
          <GridInput
            area={"ipaCodeUor"}
            id={`details.soggetti.${index}.codice_ipa_uor`}
            label={"Codice UOR"}
            placeholder={"Inserisci il codice UOR"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.denominazione_amministrazione_uor`}
        control={control}
        defaultValue={item.denominazione_amministrazione_uor || ""}
        render={({ field }) => (
          <GridInput
            area={"nameUor"}
            id={`details.soggetti.${index}.denominazione_amministrazione_uor`}
            label={"Ufficio"}
            placeholder={"Inserisci la denominazione dell’ufficio"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_fiscale`}
        control={control}
        defaultValue={item.codice_fiscale || ""}
        rules={{ pattern: taxId }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"taxId"}
            id={`details.soggetti.${index}.codice_fiscale`}
            label={"Codice fiscale"}
            placeholder={"Inserisci il codice fiscale"}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Codice fiscale non valido"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.partita_iva`}
        control={control}
        defaultValue={item.partita_iva || ""}
        rules={{ pattern: vatNumber }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"vatNumber"}
            id={`details.soggetti.${index}.partita_iva`}
            label={"Partita IVA"}
            placeholder={"Inserisci la partita IVA"}
            error={!!error}
            errorMessage={<ErrorLabel>{"Partita IVA non valida"}</ErrorLabel>}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
        control={control}
        defaultValue={item.indirizzi_digitali_riferimento || []}
        render={({ field }) => (
          <EmailsInput
            id={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
            label={"Indirizzi digitali di riferimento"}
            placeholder={"Inserisci un indirizzo e-mail"}
            maximum={5}
            onChange={field.onChange}
            values={field.value}
          />
        )}
      />
    </Container>
  );
};

PaiInputs.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default PaiInputs;
