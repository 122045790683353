import PropTypes from "prop-types";
import React from "react";

import { Container, Description, Title } from "./styled.js";

const RadioNode = ({ title, description }) => (
  <Container>
    <Title variant="bodyRegular">{title}</Title>
    <Description variant="bodyRegular">{description}</Description>
  </Container>
);

RadioNode.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default RadioNode;
