import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { Modal } from "@ts-digital/vrc";
import React from "react";
import PropTypes from "prop-types";

import { ButtonsContainer, CloseButton, Container, ErrorIcon } from "./styled";

const ErrorModal = ({ correlationId, message, isVisible, onClose }) => {
  return (
    <Modal
      visible={isVisible}
      title={"Errore nell'esecuzione della richiesta"}
      type="danger"
      onClose={onClose}
      footer={
        <ButtonsContainer>
          <CloseButton kind={"secondary"} onClick={onClose}>
            {"Chiudi"}
          </CloseButton>
        </ButtonsContainer>
      }
    >
      <Container>
        <ErrorIcon icon={faExclamationCircle} />
        <p>{message}</p>
        <p>{"Si prega di riprovare più tardi."}</p>
        {correlationId && <p>{`Identificativo errore: ${correlationId}`}</p>}
      </Container>
    </Modal>
  );
};

ErrorModal.propTypes = {
  correlationId: PropTypes.string,
  message: PropTypes.string,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ErrorModal;
