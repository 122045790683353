import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

import TypologiesSpecializationsMapOrig from "../../../../../common/typologies-specializations-map.json";
import getMissingRequiredSubjects from "../../../../../utils/get-missing-required-subjects";
import getTypologiesSpecializationsMap from "../../../../../utils/get-typologies-specializations-map";
import TooltipSelect from "../../../../components/tooltip-select";
import Subject from "./subject";
import { Container } from "./styled";
import {
  rolesLabelMapping,
  rolesMapping,
  rolesMappingToLowerCase,
  rolesTypesMapping,
} from "../../../../../common/subject-mapping";

const TypologiesSpecializationsMap = getTypologiesSpecializationsMap(
  TypologiesSpecializationsMapOrig
);

const baseSubjects = [
  "assegnatari",
  "autori",
  "destinatari",
  "mittenti",
  "operatori",
  "produttori",
  "rgd",
  "rsp",
  "altri",
];

const Step7 = ({ control, watch, unregister }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "details.soggetti",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watch("details.soggetti")[index],
    };
  });

  // list of required subjects
  const requiredSubjects = Object.entries(
    TypologiesSpecializationsMap[watch("details.tipologia_documentale")]
      .subjects.properties
  )
    .filter(([key, value]) => value.minItems > 0)
    .map((subject) => subject[0]);

  // add required subjects to the controlled fields
  useEffect(() => {
    const missingRequiredSubjects = getMissingRequiredSubjects(
      requiredSubjects,
      controlledFields.map((subject) => rolesMappingToLowerCase[subject.ruolo])
    );

    append(
      missingRequiredSubjects.map((subject) => ({
        required: true,
        ruolo: rolesMapping[subject],
        tipo: rolesTypesMapping[subject][0],
      }))
    );

    const operatorIndex = controlledFields.findIndex(
      (subject) => subject.ruolo === "OPERATORE"
    );

    const changesTrackingType = watch("details.tracciature_modifiche.tipo");

    // add required operator if the user adds change tracking info
    if (
      changesTrackingType &&
      changesTrackingType !== "NESSUNO" &&
      (operatorIndex === -1 || controlledFields[operatorIndex].isManual)
    ) {
      append({
        required: true,
        ruolo: "OPERATORE",
        tipo: "PF",
      });
    }

    // remove required operator if the user removes change tracking info
    if (
      changesTrackingType &&
      changesTrackingType === "NESSUNO" &&
      operatorIndex !== -1 &&
      !controlledFields[operatorIndex].isManual
    ) {
      remove(operatorIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // list of subjects that need to be excluded from the select
  const subjectsToExclude = Object.entries(
    TypologiesSpecializationsMap[watch("details.tipologia_documentale")]
      .subjects.properties
  )
    .filter(([key, value]) => key === "ser" || value.maxItems === 0)
    .map((subject) => subject[0]);

  // list of subjects that can be selected for the current typology
  const enabledSubject = baseSubjects
    .filter((subject) => !subjectsToExclude.includes(subject))
    .map((subject) => ({
      value: subject,
      description: rolesLabelMapping[subject],
    }));

  const handleAddSubject = (value) => {
    append({
      isManual: true,
      required: false,
      ruolo: rolesMapping[value],
      tipo: rolesTypesMapping[value][0],
    });
  };

  const handleRemoveSubject = (index) => {
    remove(index);
  };

  return (
    <Container>
      {controlledFields.map((item, index) => (
        <div key={item.id}>
          <Subject
            {...{ control, item, index, unregister }}
            onDelete={handleRemoveSubject}
          />
        </div>
      ))}
      <TooltipSelect
        options={enabledSubject}
        icon={faPlus}
        label={"Aggiungi soggetto"}
        onClick={handleAddSubject}
      />
    </Container>
  );
};

Step7.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default Step7;
