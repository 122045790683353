import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as HumanEmptySearch } from "../../../../svgs/humanEmptySearch.svg";
import ReceivedInputPackagePanel from "./panel";
import {
  Container,
  DocumentsCount,
  DocumentsCountTitle,
  DocumentsCountSubtitle,
  EmptyList,
  EmptyListMsg,
  EmptyListTitle,
  Error,
  Loading,
  LoadMoreAnchor,
  SendInputPackageButton,
} from "./styled";

const ReceivedInputPackagesList = ({
  className,
  documentsCount,
  fetchNextPage,
  receivedInputPackages,
  hasNextPage,
  holderDescription,
  isFetching,
  isFetchingNextPage,
  isError,
}) => {
  const history = useHistory();
  const loader = useRef(null);

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  });

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      fetchNextPage();
    }
  };

  if (isFetching && !isFetchingNextPage) {
    return <Loading data-testid={"loading"} />;
  }

  if (isError) {
    return (
      <Error
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
      />
    );
  }

  if (
    !receivedInputPackages ||
    receivedInputPackages.pages[0].length === 0 ||
    receivedInputPackages.pages[0].data.length === 0
  ) {
    return (
      <EmptyList>
        <HumanEmptySearch />
        <EmptyListTitle variant={"subtitleLarge"}>
          {"Nessun nuovo documento"}
        </EmptyListTitle>
        <EmptyListMsg variant="bodyLarge">
          {holderDescription
            ? `Per il titolare ${holderDescription} non ci sono nuovi documenti da processare`
            : "Seleziona un titolare per visualizzare i nuovi documenti"}
        </EmptyListMsg>
        <SendInputPackageButton
          kind={"secondary"}
          onClick={() => history.push(`/upload-input-package`)}
        >
          {"Invia documento"}
        </SendInputPackageButton>
      </EmptyList>
    );
  }

  return (
    <Container data-testid={"container"} className={className}>
      <DocumentsCount>
        <DocumentsCountTitle variant="h4">{`${documentsCount} documenti da processare per ${holderDescription}`}</DocumentsCountTitle>
        <DocumentsCountSubtitle variant="bodyRegular">
          {
            'Tra pochi giorni potrai verificare il loro stato in "Documenti conservati"'
          }
        </DocumentsCountSubtitle>
      </DocumentsCount>
      {receivedInputPackages.pages.map((receivedInputPackagePage) =>
        receivedInputPackagePage.data.map((receivedInputPackage) => (
          <ReceivedInputPackagePanel
            number={receivedInputPackage.number}
            typologyDescription={receivedInputPackage.typologyDescription}
            documentDate={receivedInputPackage.documentDate}
            rectifiedInputPackageId={
              receivedInputPackage.rectifiedInputPackageId
            }
            rectificationReason={receivedInputPackage.rectificationReason}
            receivedDate={receivedInputPackage.receivedDate}
          />
        ))
      )}
      {isFetchingNextPage && <Loading data-testid="loading-more" />}
      {!isFetchingNextPage && hasNextPage && <LoadMoreAnchor ref={loader} />}
    </Container>
  );
};

ReceivedInputPackagesList.propTypes = {
  className: PropTypes.string,
  documentsCount: PropTypes.number,
  fetchNextPage: PropTypes.func.isRequired,
  receivedInputPackages: PropTypes.object,
  hasNextPage: PropTypes.bool,
  holderDescription: PropTypes.string,
  isFetching: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  isError: PropTypes.bool,
};

export default ReceivedInputPackagesList;
