import PropTypes from "prop-types";
import React from "react";

import { DocumentsCount } from "./styled";

const DocumentCountBadge = React.memo(({ count }) => {
  if (count === 0) {
    return null;
  }

  return (
    <DocumentsCount variant={"bodyLarge"}>
      {`${count} ${count === 1 ? "documento trovato" : "documenti trovati"}`}
    </DocumentsCount>
  );
});

DocumentCountBadge.propTypes = {
  count: PropTypes.number,
};

DocumentCountBadge.defaultProps = {
  count: 0,
};

export default DocumentCountBadge;
