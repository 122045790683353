import styled from "styled-components";

import BaseLoading from "../../components/loading";
import Select from "../../components/select";
import ReceivedInputPackagesListComponent from "./list";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Paper = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  width: ${({ theme }) => `calc(100% - ${theme.spacing.unit * 8}px`});
  box-sizing: border-box;
  background-color: #fff;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const HolderSelect = styled(Select)`
  grid-area: holder;
  max-width: 300px;
  margin-bottom: 40px;
`;

export const HoldersLoading = styled(BaseLoading)``;

export const ReceivedInputPackagesList = styled(
  ReceivedInputPackagesListComponent
)``;
