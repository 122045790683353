import { Button, Typography } from "@ts-digital/vrc";
import styled, { keyframes } from "styled-components";

import FaIcon from "../fa-icon";
import FileUploader from "../file-uploader";

export const Container = styled.div`
  padding-top: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  width: 480px;
`;

export const Description = styled(Typography)`
  display: block;
  margin-bottom: 24px;
`;

export const ButtonsContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing.unit}px`};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const SendingIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  font-size: 32px;
  animation: ${rotate} 2s linear infinite;
`;

export const DiscardButton = styled(Button)`
  margin-right: ${({ theme }) => `${theme.spacing.unit * 2}px`};
`;

export const AttachmentUploader = styled(FileUploader)`
  max-height: 250px;
`;
