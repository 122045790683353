module.exports = (requiredSubjects, currentSubjects) => {
  return requiredSubjects.filter(
    function (v) {
      return !this.get(v) || !this.set(v, this.get(v) - 1);
    },
    currentSubjects.reduce(
      (acc, v) => acc.set(v, (acc.get(v) || 0) + 1),
      new Map()
    )
  );
};
