import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";

import Aggregation from "./aggregation";
import Labels from "./labels";

import { AggregationContainer } from "./styled.js";

const AggregationsAdder = ({ control, watch }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "details.id_agg",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watch("details.id_agg")[index],
    };
  });

  // add the default value if the last field
  // gets deleted
  useEffect(() => {
    if (fields.length === 0) {
      append({ tipo_aggregazione: "NESSUNO", id_aggregazione: "" });
    }
  }, [append, fields]);

  const handleOnAddClick = () => {
    if (fields.length < 10) {
      append({ tipo_aggregazione: "NESSUNO", id_aggregazione: "" });
    }
  };
  const handleOnDeleteClick = (index) => {
    remove(index);
  };

  const handleOnAggregationTypeChange = (value, index, onChange) => {
    onChange(value);

    // remove values from the form state to avoid
    // unexpected values
    if (value === "NESSUNO") {
      remove(index);
    }
  };

  return (
    <>
      <Labels control={control} />
      {controlledFields.map((item, index) => (
        <AggregationContainer key={item.id}>
          <Aggregation
            {...{
              control,
              count: fields.length,
              handleOnAddClick,
              handleOnAggregationTypeChange,
              handleOnDeleteClick,
              index,
              item,
            }}
          />
        </AggregationContainer>
      ))}
    </>
  );
};

AggregationsAdder.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
};

export default AggregationsAdder;
