import convertCustomMetadata from "./convert-custom-metadata";
import convertSubject from "./convert-subject";

const getTypologiesSpecializationsMap = (descriptors) => {
  return Object.entries(descriptors).reduce(
    (acc, [typology, value]) => ({
      ...acc,
      [typology]: {
        subjects: {
          properties: value.subjects
            .map(convertSubject)
            .reduce((acc, i) => ({ ...acc, ...i }), {}),
        },
        custom_metadata: {
          properties: value.tags
            .filter((tag) => tag.path === "details.customMetadata")
            .map(convertCustomMetadata)
            .reduce((acc, i) => ({ ...acc, ...i }), {}),
          required: value.tags
            .filter((tag) => tag.path === "details.customMetadata")
            .filter((tag) => tag.required)
            .map((tag) => tag.code),
        },
        enabledExtensions: [...value.enabledExtensions, "p7m"],
        description: value.documentTypeDescription,
      },
    }),
    {}
  );
};

export default getTypologiesSpecializationsMap;
