import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

import countries from "../../../../../../../../common/countries-code.json";
import { sdiCode, taxId, vatNumber } from "../../../../../../../../utils/regex";
import ErrorLabel from "../../../../../../../components/error-label";

import { Container, EmailsInput, GridInput, GridSelect } from "./styled";

const PgInputs = ({ control, index, item, unregister }) => {
  useEffect(() => {
    return function cleanup() {
      unregister(
        [
          `details.soggetti.${index}.denominazione`,
          `details.soggetti.${index}.denominazione_ufficio`,
          `details.soggetti.${index}.codice_fiscale`,
          `details.soggetti.${index}.partita_iva`,
          `details.soggetti.${index}.id_paese`,
          `details.soggetti.${index}.codice_sdi`,
          `details.soggetti.${index}.indirizzi_digitali_riferimento`,
        ],
        { keepValue: false }
      );
    };
  }, [index, unregister]);

  return (
    <Container>
      <Controller
        name={`details.soggetti.${index}.denominazione`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.denominazione || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"name"}
            id={`details.soggetti.${index}.denominazione`}
            label={"Denominazione"}
            placeholder={"Inserisci la denominazione"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {"Inserisci la denominazione del soggetto"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.denominazione_ufficio`}
        control={control}
        defaultValue={item.denominazione_ufficio || ""}
        render={({ field }) => (
          <GridInput
            area={"office"}
            id={`details.soggetti.${index}.denominazione_ufficio`}
            label={"Ufficio"}
            placeholder={"Inserisci il nome dell'ufficio"}
            errorMessage={
              <ErrorLabel>
                {"Inserisci il nome dell'ufficio del soggetto"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_fiscale`}
        control={control}
        defaultValue={item.codice_fiscale || ""}
        rules={{ pattern: taxId }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"taxId"}
            id={`details.soggetti.${index}.codice_fiscale`}
            label={"Codice fiscale"}
            placeholder={"Inserisci il codice fiscale"}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Codice fiscale non valido"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.partita_iva`}
        control={control}
        defaultValue={item.partita_iva || ""}
        rules={{ pattern: vatNumber }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"vatNumber"}
            id={`details.soggetti.${index}.partita_iva`}
            label={"Partita IVA"}
            placeholder={"Inserisci la partita IVA"}
            error={!!error}
            errorMessage={<ErrorLabel>{"Parita IVA non valida"}</ErrorLabel>}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.id_paese`}
        control={control}
        defaultValue={item.id_paese || "IT"}
        render={({ field }) => (
          <GridSelect
            area={"country"}
            label={"Paese"}
            options={countries}
            placeholder={"Scegli"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_sdi`}
        control={control}
        defaultValue={item.codice_sdi || ""}
        rules={{ pattern: sdiCode }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"sdiCode"}
            id={`details.soggetti.${index}.codice_sdi`}
            label={"Codice SDI"}
            placeholder={"Inserisci il codice destinatario SDI"}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {"Il formato del valore non è corretto, esempio: 'abc1234'"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
        control={control}
        defaultValue={item.indirizzi_digitali_riferimento || []}
        render={({ field }) => (
          <EmailsInput
            area={"emails"}
            id={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
            label={"Indirizzi digitali di riferimento"}
            placeholder={"Inserisci un indirizzo e-mail"}
            maximum={5}
            onChange={field.onChange}
            values={field.value}
          />
        )}
      />
    </Container>
  );
};

PgInputs.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default PgInputs;
