import {
  faFileDownload,
  faCircleNotch,
} from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ErrorModal from "../error-modal";
import { DownloadIcon, LoadingIcon } from "./styled";

const FileDownloadButton = ({
  correlationId,
  isDisabled,
  isError,
  isFetching,
  onClick,
  text,
  tooltipTextIfEnabled,
  tooltipTextIfDisabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleClick = () => {
    setIsModalOpen(true);
    onClick();
  };

  return (
    <>
      <ErrorModal
        correlationId={correlationId}
        message={"Errore nel download del file."}
        isVisible={isError && isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Tooltip
        title={isDisabled ? tooltipTextIfDisabled : tooltipTextIfEnabled}
      >
        <div>
          <Button
            data-testid="file-download-button"
            disabled={isDisabled || isFetching}
            kind={"tertiary"}
            onClick={handleClick}
          >
            {isFetching ? (
              <LoadingIcon data-testid={"loading"} icon={faCircleNotch} />
            ) : (
              <DownloadIcon data-testid={"download"} icon={faFileDownload} />
            )}
            {text}
          </Button>
        </div>
      </Tooltip>
    </>
  );
};

FileDownloadButton.propTypes = {
  correlationId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  tooltipTextIfEnabled: PropTypes.string,
  tooltipTextIfDisabled: PropTypes.string,
};

export default FileDownloadButton;
