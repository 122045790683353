import PropTypes from "prop-types";
import React from "react";
import { useWatch } from "react-hook-form";

import PF from "../types/pf";
import PG from "../types/pg";
import PA from "../types/pa";
import AS from "../types/as";
import SW from "../types/sw";

const subjectType = (type) => {
  switch (type) {
    case "PF": {
      return PF;
    }
    case "PG": {
      return PG;
    }
    case "PA": {
      return PA;
    }
    case "AS": {
      return AS;
    }
    case "SW": {
      return SW;
    }
    default: {
      return PF;
    }
  }
};

// wrapper components used to isolate the re-render
// caused by the useWatch hook, only at the inputs level,
// avoiding the re-render of the subject type select that
// was causing problems
const InputsWrapper = ({ control, index, item, unregister }) => {
  const watchSubjectType = useWatch({
    control,
    name: `details.soggetti.${index}.tipo`,
  });
  const Component = subjectType(watchSubjectType);

  return <Component {...{ control, index, item, unregister }} />;
};

InputsWrapper.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default InputsWrapper;
