import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useWatch } from "react-hook-form";

import ErrorLabel from "../../../../../../components/error-label/index.jsx";

import {
  AddButton,
  AggregationIdInput,
  AggregationTypeSelect,
  DeleteButton,
} from "./styled.js";

const aggregationTypesMapping = [
  {
    label: "Nessuno",
    value: "NESSUNO",
  },
  {
    label: "Fasciolo",
    value: "FASCICOLO",
  },
  {
    label: "Serie di fascicoli",
    value: "SERIE_FASCICOLI",
  },
  {
    label: "Serie di documenti",
    value: "SERIE_DOCUMENTALE",
  },
];

const Aggregation = ({
  control,
  count,
  handleOnAddClick,
  handleOnAggregationTypeChange,
  handleOnDeleteClick,
  index,
  item,
}) => {
  // use the useWatch hook because watch doesn't seem to
  // work on nested field arrays
  const watchAggregationType = useWatch({
    control,
    name: `details.id_agg.${index}.tipo_aggregazione`,
  });

  return (
    <>
      <Controller
        name={`details.id_agg.${index}.tipo_aggregazione`}
        control={control}
        defaultValue={item.tipo_aggregazione}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <AggregationTypeSelect
            id={`${item.id}_tipo_aggregazione`}
            error={!!error}
            errorMessage={"Seleziona un tipo di aggregazione"}
            placeholder={"Seleziona il tipo di aggregazione"}
            options={aggregationTypesMapping.map(({ label, value }) => ({
              value,
              label,
            }))}
            onChange={(value) =>
              handleOnAggregationTypeChange(value, index, field.onChange)
            }
            value={field.value}
          />
        )}
      />
      {watchAggregationType && watchAggregationType !== "NESSUNO" && (
        <>
          <Controller
            name={`details.id_agg.${index}.id_aggregazione`}
            control={control}
            defaultValue={item.id_aggregazione}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <AggregationIdInput
                id={`${item.id}_id_aggregazione`}
                error={!!error}
                errorMessage={
                  <ErrorLabel>
                    {"Inserisci un identificativo dell'aggregazione"}
                  </ErrorLabel>
                }
                placeholder={"Inserisci l'identificativo dell'aggregazione"}
                required={true}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Tooltip title={"Elimina"}>
            <DeleteButton
              disabled={!watchAggregationType}
              kind={"tertiary"}
              onClick={() => handleOnDeleteClick(index)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </DeleteButton>
          </Tooltip>
          {index === count - 1 && (
            <Tooltip title={"Aggiungi"}>
              <AddButton
                disabled={!watchAggregationType || count === 10}
                kind={"tertiary"}
                onClick={handleOnAddClick}
              >
                <FontAwesomeIcon icon={faPlus} />
              </AddButton>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

Aggregation.propTypes = {
  control: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  handleOnAddClick: PropTypes.func.isRequired,
  handleOnAggregationTypeChange: PropTypes.func.isRequired,
  handleOnDeleteClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
};

export default Aggregation;
