import React, { useState } from "react";
import { useRecoilState } from "recoil";

import userAtom from "../../../core/atoms/user";
import useHolders from "../../../core/hooks/holders";
import useDistributionPackages from "../../../core/hooks/distribution-packages";
import Error from "../../components/error";
import {
  Container,
  DistributionPackagesList,
  HolderSelect,
  HoldersLoading,
  Paper,
} from "./styled.js";

const DistributionPackagesView = () => {
  const [user] = useRecoilState(userAtom);
  const [holder, setHolder] = useState(null);

  const holders = useHolders(user.token);

  const distributionPackages = useDistributionPackages(
    user.token,
    holder && holder.uuid
  );

  if (holders.isIdle || holders.isFetching) {
    return <HoldersLoading />;
  }

  if (holders.isError) {
    return (
      <Error
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
        onRetry={holders.refetch}
      />
    );
  }

  return (
    <Container>
      <Paper>
        <HolderSelect
          label={"Titolare"}
          onChange={setHolder}
          options={holders.data.map((holder) => ({
            value: holder,
            label: `${holder.vatNumber} ${holder.description}`,
          }))}
          placeholder={"Seleziona un titolare cercando per nome o partita IVA"}
          required={true}
          value={holder}
        />
        <DistributionPackagesList
          distributionPackages={distributionPackages.data}
          fetchNextPage={distributionPackages.fetchNextPage}
          hasNextPage={distributionPackages.hasNextPage}
          holderId={holder ? holder.uuid : ""}
          isFetching={distributionPackages.isFetching}
          isFetchingNextPage={distributionPackages.isFetchingNextPage}
          isError={distributionPackages.isError}
        />
      </Paper>
    </Container>
  );
};

export default DistributionPackagesView;
