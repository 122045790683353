import { Dropdown, Menu } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";

import { faDownload } from "@fortawesome/pro-regular-svg-icons";

import { Icon, StyledButtonWithIcon } from "./styled";

const DropdownMenu = ({ items }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMenuToggle = () => {
    setIsVisible(!isVisible);
  };

  const closeOnExternalClick = (visible) => {
    setIsVisible(visible);
  };

  const menu = (
    <Menu>
      {items.map((item, index) => (
        <Menu.Item key={index}>{item}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      visible={isVisible}
      trigger={["click"]}
      onVisibleChange={closeOnExternalClick}
      overlay={menu}
      onClick={handleMenuToggle}
    >
      <StyledButtonWithIcon
        kind={"secondary"}
        icon={<Icon icon={faDownload} />}
        onClick={handleMenuToggle}
      />
    </Dropdown>
  );
};

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
};

export default DropdownMenu;
