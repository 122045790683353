import PropTypes from "prop-types";
import React, { useState } from "react";

import {
  AddButton,
  AdditionalLabel,
  Container,
  Label,
  Keyword,
  Keywords,
  StyledInput,
} from "./styled";

const KeywordInput = ({
  className,
  id,
  label,
  additionalLabel,
  placeholder,
  onChange,
  maximum,
  values,
  disabled,
}) => {
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [isAdditionalLabelVisible, setAdditionalLabelVisible] = useState(false);

  const handleOnAdd = () => {
    if (maximum > values.length && currentKeyword) {
      onChange([...values, currentKeyword]);
      setCurrentKeyword("");
    }
  };

  const handleOnRemove = (index) => {
    onChange([...values.slice(0, index), ...values.slice(index + 1)]);
  };

  return (
    <Container
      className={className}
      onMouseEnter={() => setAdditionalLabelVisible(true)}
      onMouseLeave={() => setAdditionalLabelVisible(false)}
    >
      <Label disabled={disabled}>
        {label}
        {additionalLabel && isAdditionalLabelVisible && (
          <AdditionalLabel>{additionalLabel}</AdditionalLabel>
        )}
      </Label>
      <StyledInput
        id={id}
        placeholder={placeholder}
        onChange={setCurrentKeyword}
        value={currentKeyword}
        disabled={disabled}
      />
      <AddButton kind={"secondary"} onClick={handleOnAdd} disabled={disabled}>
        {"Aggiungi"}
      </AddButton>
      <Keywords>
        {values.map((keyword, index) => (
          <Keyword
            closable={true}
            key={index}
            color={"BlueSapphire"}
            onClose={() => handleOnRemove(index)}
          >
            {keyword}
          </Keyword>
        ))}
      </Keywords>
    </Container>
  );
};

KeywordInput.defaultProps = {
  values: [],
};

KeywordInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  additionalLabel: PropTypes.node,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maximum: PropTypes.number,
  values: PropTypes.array,
  disabled: PropTypes.bool,
};

export default KeywordInput;
