import PropTypes from "prop-types";
import React from "react";

import getTypologiesSpecializationsMap from "../../../utils/get-typologies-specializations-map";
import typologiesSpecializationsMapOriginal from "../../../common/typologies-specializations-map.json";
import config from "../../../config";
import Select from "../../components/select";
import InfoTooltip from "../info-tooltip";

const typologiesSpecializationsMap = getTypologiesSpecializationsMap(
  typologiesSpecializationsMapOriginal
);

const TypologySelect = ({ error, value, onChange, required, ...rest }) => (
  <Select
    label={"Tipo documento"}
    additionalLabel={
      <InfoTooltip
        title={"Tipo documento"}
        text={
          "Descrizione della tipologia (preimpostato in base alla tipologia documentale scelta e prevista in Scheda di Servizio)."
        }
      />
    }
    error={error}
    errorMessage={"Seleziona una tipologia"}
    onChange={onChange}
    options={Object.keys(typologiesSpecializationsMap)
      .filter((typology) => config.enabledTypologies.includes(typology))
      .map((typology) => ({
        value: typology,
        label: `${typology} - ${typologiesSpecializationsMap[typology].description}`,
      }))}
    placeholder={"Seleziona una tipologia documentale"}
    required={required}
    value={value}
    {...rest}
  />
);

TypologySelect.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default TypologySelect;
