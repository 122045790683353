import moment from "moment";
import omit from "lodash.omit";

import TypologiesSpecializationsMapOrig from "../../../../common/typologies-specializations-map.json";
import { rolesMapping } from "../../../../common/subject-mapping";
import getTypologiesSpecializationsMap from "../../../../utils/get-typologies-specializations-map";

const TypologiesSpecializationsMap = getTypologiesSpecializationsMap(
  TypologiesSpecializationsMapOrig
);

const convertDates = (payload) => {
  return {
    ...payload,
    details: {
      ...payload.details,
      dati_registrazione: {
        ...payload.details.dati_registrazione,
        data: moment(payload.details.dati_registrazione.data).toISOString(),
      },
      ...(payload.details.tracciature_modifiche
        ? {
            tracciature_modifiche: {
              ...payload.details.tracciature_modifiche,
              data: moment(
                payload.details.tracciature_modifiche.data
              ).toISOString(),
            },
          }
        : {}),
      ...(payload.details.custom_metadata
        ? {
            custom_metadata: Object.keys(
              payload.details.custom_metadata
            ).reduce((acc, currentKey) => {
              const currentMetadata =
                TypologiesSpecializationsMap[
                  payload.details.tipologia_documentale
                ].custom_metadata.properties[currentKey];

              if (!currentMetadata) {
                return {
                  ...acc,
                  [currentKey]: undefined,
                };
              }

              if (currentMetadata.format !== "date-time") {
                return {
                  ...acc,
                  [currentKey]: payload.details.custom_metadata[currentKey],
                };
              }

              return {
                ...acc,
                [currentKey]: moment(
                  payload.details.custom_metadata[currentKey]
                ).toISOString(),
              };
            }, {}),
          }
        : {}),
    },
  };
};

const buildFile = (payload) => {
  return {
    ...payload,
    externalApplicationId: payload.file.identificativo_applicativo_esterno,
    file: {
      ...omit(payload.file, ["identificativo_applicativo_esterno", "nome"]),
      name: payload.file.nome,
    },
  };
};

const buildSubjects = (payload) => {
  return {
    ...payload,
    details: {
      ...payload.details,
      soggetti: payload.details.soggetti
        .filter((subject) => {
          const subjectLabel = Object.keys(
            TypologiesSpecializationsMap[payload.details.tipologia_documentale]
              .subjects.properties
          ).find((role) => rolesMapping[role] === subject.ruolo);

          if (!subjectLabel) {
            return subject;
          }

          const currentSubject =
            TypologiesSpecializationsMap[payload.details.tipologia_documentale]
              .subjects.properties[subjectLabel];

          return currentSubject.minItems !== 0 && currentSubject.maxItems !== 0;
        })
        .map((subject) => ({
          ...subject,
          required: Object.keys(
            TypologiesSpecializationsMap[payload.details.tipologia_documentale]
              .subjects.properties
          ).find((role) => rolesMapping[role] === subject.ruolo)
            ? true
            : undefined,
        })),
    },
  };
};

const buildHolder = (payload) => {
  return {
    ...payload,
    holder: {
      taxId: payload.holder.tax_id,
      vatNumber: payload.holder.vat_number,
      countryCode: payload.holder.country_id,
      uuid: payload.holder.legacy_identifier,
    },
  };
};

export { convertDates, buildFile, buildSubjects, buildHolder };
