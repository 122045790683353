const metadataNamesMap = {
  DataEmissione: "data_emissione",
  NumeroFattura: "numero_fattura",
  Sezionale: "sezionale",
  Destinatario_CodiceFiscale: "codice_fiscale_destinatario",
  Destinatario_PartitaIVA: "partita_iva_destinatario",
  Fornitore_CodiceFiscale: "codice_fiscale_fornitore",
  Fornitore_PartitaIVA: "partita_iva_fornitore",
  DataDocumento: "data_documento",
  NomeFileOriginale: "nome_file_originale",
};

const convertInputPackageMetadata = (metadata) => {
  const correctMetadataKeys = Object.keys(metadataNamesMap);
  return Object.keys(metadata).reduce(
    (acc, i) =>
      correctMetadataKeys.includes(i)
        ? { ...acc, [metadataNamesMap[i]]: metadata[i] }
        : { ...acc, [i]: metadata[i] },
    {}
  );
};

export default convertInputPackageMetadata;
