import axios from "axios";
import { useQuery } from "react-query";
import { v4 } from "uuid";

import config from "../../../config/index";

export const fetchInputPackagePreviewById = async ({ token, id, holderId }) => {
  const requestId = v4();
  const { data } = await axios.get(
    `${config.archiveApiBasePath}/v1/archive/${holderId}/documents/${id}/preview?type=asso`,
    {
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );
  return data;
};

const usePreview = (token, inputPackageIdentifier, holderId) =>
  useQuery(
    ["preview", inputPackageIdentifier],
    () =>
      fetchInputPackagePreviewById({
        token,
        id: inputPackageIdentifier,
        holderId,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      initialData: "",
    }
  );

export default usePreview;
