import { Button, Input } from "@ts-digital/vrc";
import styled, { keyframes } from "styled-components";

import FaIcon from "../../../components/fa-icon";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 480px;
  height: 164px;
`;

export const ChangeReasonInput = styled(Input)`
  width: 100%;
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const SendingIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  font-size: 32px;
  animation: ${rotate} 2s linear infinite;
`;

export const SuccessIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.accent.islamicGreen};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  font-size: 32px;
`;

export const ErrorIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.status.maximumRed};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  font-size: 32px;
`;

export const ButtonsContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing.unit}px`};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
`;

export const ConfirmButton = styled(Button)``;

export const BackToSearchButton = styled(Button)``;

export const DiscardButton = styled(Button)`
  margin-right: ${({ theme }) => `${theme.spacing.unit * 2}px`};
`;
