import { chain } from "lodash";

import { rolesMapping } from "../../../common/subject-mapping";

const convertSubject = (subject) => ({
  [Object.entries(rolesMapping).find(
    ([key, value]) => value === subject.role
  )[0]]: chain(subject)
    .omit("role")
    .pickBy((value) => !(value === undefined || value === null))
    .value(),
});

export default convertSubject;
