import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import BaseTag from "../../../../components/tag";
import FaIcon from "../../../../components/fa-icon";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  box-shadow: 0px 0px 7px 0px #bfbfbf;
  ${(props) => props.theme.colors.background.whiteSmoke};
  ${(props) =>
    !props.isPanelOpen &&
    `background: 
        -webkit-linear-gradient(right, ${props.theme.colors.background.whiteSmoke}, ${props.theme.colors.background.whiteSmoke} 64px, transparent 64px, transparent 100%)`}
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 220px 104px 48px;
  grid-template-rows: auto;
  height: 32px;
  align-items: center;
`;

export const DocumentDate = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.quickSilver};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
  padding: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  flex-grow: 1;
`;

export const Metadata = styled.div`
  width: 220px;
`;

export const MetadataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
  margin-top: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-left: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  display: block;
`;

export const Value = styled(Typography)`
  margin-left: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  display: block;
`;

export const AccordionIconContainer = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  font-size: 24px;
  &:hover {
    cursor: pointer;
  }
  width: 24px;
  justify-self: end;
`;

export const PreviewIcon = styled(FaIcon)`
  margin-right: ${({ theme }) => `${theme.spacing.unit}px`};
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Tag = styled(BaseTag)`
  margin-right: 8px;
`;

export const TagsContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 8px;
`;
