import { omit } from "lodash";

const convertCustomMetadata = (customMetadata) => ({
  [customMetadata.code]: {
    ...omit(customMetadata, [
      "code",
      "serviceCardLabel",
      "path",
      "fullPath",
      "wcfPath",
      "wcfOnly",
      "required",
    ]),
    label: customMetadata.serviceCardLabel,
  },
});

export default convertCustomMetadata;
