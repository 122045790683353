import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

import DatePicker from "../../../../components/date-picker";
import Select from "../../../../components/select";
import { PreviousDocumentContainer } from "./previous-document-adder/styled";

export const Container = styled.div`
  width: 100%;
  margin-right: 64px;
`;

export const GridContainer = styled.div`
  display: grid;
  justify-items: start;
  grid-column-gap: 32px;
  grid-template-areas:
    "version previousDocument"
    "changeType changeDate"
    "notes notes";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100px 100px;
`;

export const VersionInput = styled(Input)`
  grid-area: version;
  width: 100%;
`;

export const StyledPreviousDocumentAdder = styled(PreviousDocumentContainer)`
  grid-area: previousDocument;
`;

export const ChangeTypeSelect = styled(Select)`
  grid-area: changeType;
  width: 100%;
`;

export const ChangeDatePicker = styled(DatePicker)`
  grid-area: changeDate;
  width: 100%;
`;

export const NotesInput = styled(Input)`
  grid-area: notes;
  width: 100%;
`;
