import { Button as ButtonBase } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../../../../../components/fa-icon";
import TooltipSelect from "../../../../../components/tooltip-select";
import Select from "../../../../../components/select";

export const Container = styled.div`
  margin-bottom: 64px;
`;

export const Actions = styled.div`
  margin-left: auto;
`;

export const ChangeButton = styled(TooltipSelect)`
  height: 28px;
  max-width: 200px;
`;

export const DeleteButton = styled(ButtonBase)`
  height: 28px;
  max-width: 200px;
`;

export const DeleteButtonIcon = styled(FaIcon)`
  font-size: 16px;
  margin-right: 12px;
`;

export const Header = styled.div`
  display: flex;
`;

export const TypeSelect = styled(Select)`
  height: 88px;
`;
