import { get } from "lodash";
import React, { useState } from "react";
import { useRecoilState } from "recoil";

import userAtom from "../../../core/atoms/user";
import useHolders from "../../../core/hooks/holders";
import useReceivedInputPackages from "../../../core/hooks/received-input-packages";
import Error from "../../components/error";
import {
  Container,
  ReceivedInputPackagesList,
  HolderSelect,
  HoldersLoading,
  Paper,
} from "./styled.js";

const ReceivedInputPackagesView = () => {
  const [user] = useRecoilState(userAtom);
  const [holder, setHolder] = useState(null);

  const holders = useHolders(user.token);

  const receivedInputPackages = useReceivedInputPackages(
    user.token,
    holder && holder.uuid
  );

  if (holders.isIdle || holders.isFetching) {
    return <HoldersLoading />;
  }

  if (holders.isError) {
    return (
      <Error
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
        onRetry={holders.refetch}
      />
    );
  }

  return (
    <Container>
      <Paper>
        <HolderSelect
          label={"Titolare"}
          onChange={setHolder}
          options={holders.data.map((holder) => ({
            value: holder,
            label: `${holder.vatNumber} ${holder.description}`,
          }))}
          placeholder={"Seleziona un titolare cercando per nome o partita IVA"}
          required={true}
          value={holder}
        />
        <ReceivedInputPackagesList
          documentsCount={get(receivedInputPackages, "data.pages[0].total")}
          receivedInputPackages={receivedInputPackages.data}
          fetchNextPage={receivedInputPackages.fetchNextPage}
          hasNextPage={receivedInputPackages.hasNextPage}
          holderId={holder ? holder.uuid : ""}
          holderDescription={holder ? holder.description : ""}
          isFetching={receivedInputPackages.isFetching}
          isFetchingNextPage={receivedInputPackages.isFetchingNextPage}
          isError={receivedInputPackages.isError}
        />
      </Paper>
    </Container>
  );
};

export default ReceivedInputPackagesView;
