import { Input, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import DatePicker from "../../../../components/date-picker";

export const Container = styled.div`
  width: 100%;
  margin-right: 64px;
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
`;

export const GridInput = styled(Input)`
  grid-column: ${({ gridparameter }) => gridparameter};
`;

export const GridDatePicker = styled(DatePicker)`
  grid-column: ${({ gridparameter }) => gridparameter};
`;

export const EmptyContent = styled(Typography)`
  font-weight: normal;
  line-height: 16px;
`;
