import {
  faExclamationCircle,
  faCircleNotch,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Modal } from "@ts-digital/vrc";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

import ErrorLabel from "../../../components/error-label";

import {
  Container,
  SendingIcon,
  SuccessIcon,
  ErrorIcon,
  ButtonsContainer,
  ConfirmButton,
  DiscardButton,
  ChangeReasonInput,
  BackToSearchButton,
} from "./styled";

const RectifyModal = ({
  correlationId,
  isError,
  isSending,
  isSuccess,
  onClose,
  onConfirm,
  onGoBackToSearch,
  visible,
}) => {
  const [isChangeReasonError, setIsChangeReasonError] = useState(false);
  const [changeReason, setChangeReason] = useState("");

  const initialContent = (
    <ChangeReasonInput
      required={true}
      label={"Qual è il motivo della rettifica?"}
      error={isChangeReasonError}
      errorMessage={
        <ErrorLabel>{"Indica una motivazione per la rettifica"}</ErrorLabel>
      }
      placeholder={"Inserisci il motivo della rettifica"}
      value={changeReason}
      onChange={setChangeReason}
    />
  );

  const errorText = (
    <>
      <ErrorIcon icon={faExclamationCircle} />
      <p>{"Errore nella richiesta di rettifica."}</p>
      <p>{"Si prega di riprovare più tardi o di contattare l'assistenza."}</p>
      <p>{`Identificativo errore: ${correlationId}`}</p>
    </>
  );
  const successText = (
    <>
      <SuccessIcon icon={faCheckCircle} />
      <p>{"Rettifica presa in carico correttamente."}</p>
    </>
  );
  const sendingText = (
    <>
      <SendingIcon icon={faCircleNotch} />
      <p>{"Invio della rettifica in corso."}</p>
    </>
  );

  const handleOnConfirm = () => {
    if (changeReason.length === 0) {
      setIsChangeReasonError(true);
      return;
    }

    onConfirm(changeReason);
  };

  return (
    <Modal
      visible={visible}
      title={"Invia"}
      onClose={onClose}
      closable={false}
      outsideClosable={false}
      footer={
        <ButtonsContainer>
          {isSuccess ? (
            <BackToSearchButton kind={"primary"} onClick={onGoBackToSearch}>
              {"Torna alla ricerca"}
            </BackToSearchButton>
          ) : (
            <>
              <DiscardButton kind={"secondary"} onClick={onClose}>
                {"Torna alla rettifica"}
              </DiscardButton>
              <ConfirmButton
                kind={"primary"}
                disabled={isSending}
                onClick={handleOnConfirm}
              >
                {"Invia"}
              </ConfirmButton>
            </>
          )}
        </ButtonsContainer>
      }
    >
      <Container>
        {isSending
          ? sendingText
          : isSuccess
          ? successText
          : isError
          ? errorText
          : initialContent}
      </Container>
    </Modal>
  );
};

RectifyModal.propTypes = {
  correlationId: PropTypes.string,
  isError: PropTypes.bool,
  isSending: PropTypes.bool,
  isSuccess: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onGoBackToSearch: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

RectifyModal.defaultProps = {
  isError: false,
  isSending: false,
  isSuccess: false,
  visible: false,
};

export default RectifyModal;
