import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../../fa-icon";

export const Content = styled.div`
  max-width: 820px;
`;

export const NoPreviewContainer = styled.div`
  display: flex;
  max-width: 400px;
  align-items: center;
  flex-direction: column;
`;

export const FileIcon = styled(FaIcon)`
  color: #e3eaf1;
  font-size: 56px;
  margin-bottom: 24px;
`;

export const Text = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
`;
