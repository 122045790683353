import { faFileDownload } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { v4 } from "uuid";

import userAtom from "../../../../core/atoms/user";
import useArticle52Html from "../../../../core/hooks/article-52-html";
import useArticle52Pdf from "../../../../core/hooks/article-52-pdf";
import Error from "../../../components/error";
import Loading from "../../../components/loading";
import ErrorModal from "../../../components/error-modal";
import {
  Container,
  DownloadButton,
  DownloadIcon,
  PreviewContainer,
} from "./styled";

const Preview = ({ holderId, ...rest }) => {
  const [user] = useRecoilState(userAtom);

  const [pdfExportRequestId, setPDFExportRequestId] = useState("");
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(true);

  const article52Html = useArticle52Html(user.token, holderId, v4());
  const article52Pdf = useArticle52Pdf(
    user.token,
    holderId,
    pdfExportRequestId
  );

  const handleOnGeneratePdfClick = () => {
    setIsErrorModalVisible(true);
    setPDFExportRequestId(v4());
  };

  if (article52Html.isFetching) {
    return <Loading {...rest} data-testid={"loading"} />;
  }

  if (article52Html.isError) {
    return (
      <Error
        {...rest}
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
      />
    );
  }

  if (!article52Html.data) {
    return null;
  }

  return (
    <Container>
      <ErrorModal
        message={"Errore nella generazione del documento PDF."}
        isVisible={
          !article52Pdf.isFetching &&
          article52Pdf.isError &&
          isErrorModalVisible
        }
        onClose={() => setIsErrorModalVisible(false)}
      />
      <DownloadButton kind={"primary"} onClick={handleOnGeneratePdfClick}>
        <DownloadIcon
          icon={faFileDownload}
          isLoading={article52Pdf.isFetching}
        />
        {"Scarica il PDF"}
      </DownloadButton>
      <PreviewContainer
        {...rest}
        title={"Preview"}
        srcDoc={article52Html.data}
      />
    </Container>
  );
};

Preview.propTypes = {
  holderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Preview;
