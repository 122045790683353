import React from "react";

const ContextProvider = React.createContext({ openElevioSidebar: () => {} });

export const ElevioContextProvider = ({ openElevioSidebar, children }) => {
  return (
    <ContextProvider.Provider value={{ openElevioSidebar }}>
      {children}
    </ContextProvider.Provider>
  );
};

export const useElevioContext = () => React.useContext(ContextProvider);
