import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import BaseError from "../../../components/error";
import BaseLoading from "../../../components/loading";

export const Container = styled.div`
  height: 100%;
`;

export const EmptyList = styled.div`
  grid-area: cards;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

export const EmptyListMsg = styled(Typography)`
  margin-top: 8px;
`;

export const EmptyListTitle = styled(Typography)`
  margin-top: 32px;
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
  font-weight: 500;
`;

export const Loading = styled(BaseLoading)`
  grid-area: cards;
  height: 50px;
`;

export const Error = styled(BaseError)`
  grid-area: cards;
`;

export const LoadMoreAnchor = styled.div`
  height: 10px;
  margin-bottom: 10px;
`;
