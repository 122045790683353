import styled, { keyframes } from "styled-components";

import FaIcon from "../fa-icon";

export const Icon = styled(FaIcon)`
  cursor: pointer;
`;

export const Error = styled(FaIcon)`
  cursor: pointer;
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Loading = styled(FaIcon)`
  animation: ${rotate} 2s linear infinite;
`;
