import PropTypes from "prop-types";
import React from "react";

import { ButtonNext, ButtonPrevious, Container } from "./styled";

const ActionFooter = ({
  onPreviousClick,
  onNextClick,
  previousLabel,
  nextLabel,
  disableNextButton,
  className,
}) => {
  return (
    <Container className={className}>
      <ButtonPrevious kind={"secondary"} onClick={onPreviousClick}>
        {previousLabel}
      </ButtonPrevious>
      <ButtonNext
        kind={"primary"}
        onClick={onNextClick}
        disabled={disableNextButton}
      >
        {nextLabel}
      </ButtonNext>
    </Container>
  );
};

ActionFooter.defaultProps = {
  previousLabel: "Indietro",
  nextLabel: "Avanti",
};

ActionFooter.propTypes = {
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  disableNextButton: PropTypes.bool,
  className: PropTypes.string,
};

export default ActionFooter;
