import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import ButtonWithIcon from "../button-with-icon";

export const LoadingContainer = styled.div`
  width: 100%;
  max-width: 850px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const PreviewContent = styled.div`
  display: flex;
  align-content: center;
`;

export const DefaultContent = styled.div`
  display: flex;
  align-content: center;
`;

export const Container = styled.div`
  width: 100%;
  margin-bottom: 8px;
  max-width: 850px;
  border: ${({ theme, disabled, error }) =>
    `1px dashed ${
      error
        ? theme.colors.status.maximumRed
        : disabled
        ? theme.colors.text.quickSilver
        : theme.colors.primary.richElectricBlue
    }`};
  padding: ${({ showPreview, value }) =>
    `${showPreview && value && value.content ? "16px" : "48px"}`};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Label = styled(Typography)`
  font-weight: 500;
  margin-bottom: 16px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.cadet : `inerith`};
  opacity: ${({ disabled }) => (disabled ? "0.64" : "1")};
`;

export const FileName = styled(Typography)`
  font-weight: normal;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  margin-bottom: 8px;
`;

export const SwitchButton = styled(ButtonWithIcon)`
  margin-right: 16px;
`;

export const DeleteButton = styled(ButtonWithIcon)``;

export const Hint = styled(Typography)`
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.text.quickSilver
      : theme.colors.primary.richElectricBlue};
  font-weight: 500;
  margin-bottom: 12px;
`;

export const Error = styled(Typography)`
  color: ${({ theme }) => theme.colors.status.maximumRed};
  font-weight: 500;
  margin-bottom: 12px;
`;

export const InputContainer = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 850px;
  height: 100%;
  max-height: 800px;
  display: flex;
  flex-direction: column;
`;
