import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import SectionHeader from "../../../../components/section-header";
import CreationModeRadio from "./creation-mode-radio";

import {
  AuditGridContainer,
  Container,
  NameInput,
  SoftwareInput,
  SpacedLabeledToggle,
  Spacing,
  VersionInput,
} from "./styled.js";

const Step3 = ({ control }) => {
  return (
    <Container>
      <Spacing>
        <SectionHeader
          title={"SICUREZZA E VERIFICA"}
          subtitle={"Marcatori di sicurezza e verifica del documento."}
        />
        <Controller
          name={"details.riservato"}
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { error } }) => (
            <SpacedLabeledToggle
              error={!!error}
              label={"Riservato"}
              onChange={field.onChange}
              checked={field.value}
              infoTooltipTitle={"Riservato"}
              infoTooltipText={`Cliccare sul bottone se nel sistema documentale o nel gestionale di provenienza l’accesso al documento è riservato, oppure se il documento è stato oggetto di soluzioni per garantirne la riservatezza tramite tecniche di cifratura del file o simili (come specificato al capitolo 3.13 della Scheda Servizio, restano a carico del Titolare del trattamento dei dati eventuali obblighi e misure di sicurezza richiesti in relazione alla specifica natura dei dati trattati quali ad esempio l’adozione di idonee tecniche di cifratura, di pseudonimizzazione o altre misure volte a rendere inintelligibili i dati prima del loro trasferimento alla piattaforma di TeamSystem Service).
              Si precisa che il check non implica alcun intervento di limitazione degli accessi al file da parte del conservatore.`}
            />
          )}
        />
        <Controller
          name={"details.verifica.firmato_digitalmente"}
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { error } }) => (
            <SpacedLabeledToggle
              error={!!error}
              label={"Firmato digitalmente"}
              onChange={field.onChange}
              checked={field.value}
              infoTooltipTitle={"Firmato digitalmente"}
              infoTooltipText={
                "Cliccare sul bottone se al documento informatico è stata associata una firma digitale prima dell’invio in conservazione. Si precisa che il conservatore non effettua alcun controllo sulla validità o sulla presenza della firma."
              }
            />
          )}
        />
        <Controller
          name={"details.verifica.marcatura_temporale"}
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { error } }) => (
            <SpacedLabeledToggle
              error={!!error}
              label={"Marcatura temporale"}
              onChange={field.onChange}
              checked={field.value}
              infoTooltipTitle={"Marcatura temporale"}
              infoTooltipText={
                "Cliccare sul bottone se al documento informatico è stata associata una marca temporale prima dell’invio in conservazione. Si precisa che il conservatore non effettua alcun controllo sulla validità o sulla presenza della marcatura temporale."
              }
            />
          )}
        />
        <Controller
          name={"details.verifica.sigillato_elettronicamente"}
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { error } }) => (
            <SpacedLabeledToggle
              error={!!error}
              label={"Sigillato elettronicamente"}
              onChange={field.onChange}
              checked={field.value}
              infoTooltipTitle={"Sigillato elettronicamente"}
              infoTooltipText={
                "Cliccare sul bottone se al documento informatico è stato associato un sigillo elettronico prima dell’invio in conservazione. Si precisa che il conservatore non effettua alcun controllo sulla validità o sulla presenza del sigillo."
              }
            />
          )}
        />
        <Controller
          name={"details.verifica.conformita_copie_immagine"}
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { error } }) => (
            <SpacedLabeledToggle
              error={!!error}
              label={"Conformità copie immagine su supporto informatico"}
              onChange={field.onChange}
              checked={field.value}
              infoTooltipTitle={
                "Conformità copie immagine su supporto informatico"
              }
              infoTooltipText={
                "Cliccare sul bottone se esiste l’attestazione di conformità o il rapporto di verificazione della copia per immagine su supporto informatico del documento analogico, effettuata tramite raffronto dei documenti o attraverso certificazione di processo svolta da un privato, un notaio o un pubblico ufficiale."
              }
            />
          )}
        />
      </Spacing>
      <Spacing>
        <SectionHeader
          title={"MODALITÀ DI FORMAZIONE *"}
          subtitle={
            "Modalità in cui è stato creato o si è formato il documento."
          }
        />
        <Controller
          name={"details.modalita_formazione"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <CreationModeRadio
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
              error={!!error}
              errorMessage={"Seleziona una modalità di formazione"}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <SectionHeader
          title={"PRODOTTO SOFTWARE"}
          subtitle={
            "Informazioni sul prodotto software utilizzato per creare il documento."
          }
        />
        <AuditGridContainer>
          <Controller
            name={"details.prodotto_software.nome"}
            control={control}
            defaultValue={""}
            render={({ field, fieldState: { error } }) => (
              <NameInput
                id={"details.prodotto_software.nome"}
                label={"Nome prodotto"}
                error={!!error}
                placeholder={"Inserisci il nome del software"}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Controller
            name={"details.prodotto_software.versione"}
            control={control}
            defaultValue={""}
            render={({ field, fieldState: { error } }) => (
              <VersionInput
                id={"details.prodotto_software.versione"}
                label={"Versione"}
                error={!!error}
                placeholder={"Inserisci la versione"}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Controller
            name={"details.prodotto_software.produttore"}
            control={control}
            defaultValue={""}
            render={({ field, fieldState: { error } }) => (
              <SoftwareInput
                id={"details.prodotto_software.produttore"}
                label={"Produttore del software"}
                error={!!error}
                placeholder={"Iserisci il produttore del software"}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </AuditGridContainer>
      </Spacing>
    </Container>
  );
};

Step3.propTypes = {
  control: PropTypes.object.isRequired,
};

export default Step3;
