import { Button } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  height: 72px;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0px, 1fr) auto auto;
  grid-template-areas: ". previous next";
  column-gap: 16px;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.colors.background.whiteSmoke};
`;

export const ButtonPrevious = styled(Button)`
  width: 100px;
  height: 48px;
  grid-area: previous;
`;

export const ButtonNext = styled(Button)`
  width: 100px;
  height: 48px;
  grid-area: next;
`;
