import { Button, Input } from "@ts-digital/vrc";
import styled from "styled-components";

import Select from "../../../../../../components/select";

export const AggregationTypeSelect = styled(Select)`
  width: 100%;
  margin-bottom: 8px;
`;

export const AggregationIdInput = styled(Input)`
  width: 100%;
  margin-bottom: 0;
`;

export const DeleteButton = styled(Button)``;

export const AddButton = styled(Button)``;
