import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faCircleNotch,
} from "@fortawesome/pro-regular-svg-icons";
import { Modal } from "@ts-digital/vrc";
import PropTypes from "prop-types";
import React from "react";

import {
  Container,
  InfoIcon,
  SendingIcon,
  SuccessIcon,
  ErrorIcon,
  ButtonsContainer,
  ConfirmButton,
  CloseButton,
  DiscardButton,
} from "./styled";

const DistributionPackageRequestModal = ({
  correlationId,
  isError,
  isSending,
  isSuccess,
  onClose,
  onConfirm,
  visible,
}) => {
  const initialText = (
    <>
      <InfoIcon icon={faInfoCircle} />
      <p>
        {
          "Stai richiedendo il PdD per l'esibizione a norma dei documenti che hai ricercato."
        }
      </p>
      <p>
        {
          'Potrai scaricarlo dal tab "pacchetti di distribuzione" una volta disponibile.'
        }
      </p>
    </>
  );
  const errorText = (
    <>
      <ErrorIcon icon={faExclamationCircle} />
      <p>{"Errore nella richiesta del pacchetto di distribuzione."}</p>
      <p>{"Si prega di riprovare più tardi."}</p>
      <p>{`Identificativo errore: ${correlationId}`}</p>
    </>
  );
  const sendingText = (
    <>
      <SendingIcon icon={faCircleNotch} />
      <p>{"Richiesta in corso."}</p>
    </>
  );
  const successText = (
    <>
      <SuccessIcon icon={faCheckCircle} />
      <p>{"Richiesta avvenuta con successo."}</p>
    </>
  );

  return (
    <Modal
      visible={visible}
      title={"Richiedi il pacchetto di distribuzione"}
      onClose={onClose}
      abortText={"Annulla"}
      confirmText={"Continua"}
      closable={false}
      footer={
        <ButtonsContainer>
          {isSending ? null : isSuccess || isError ? (
            <CloseButton kind={"secondary"} onClick={onClose}>
              {"Chiudi"}
            </CloseButton>
          ) : (
            <>
              <DiscardButton kind={"secondary"} onClick={onClose}>
                {"Annulla"}
              </DiscardButton>
              <ConfirmButton kind={"primary"} onClick={onConfirm}>
                {"Conferma"}
              </ConfirmButton>
            </>
          )}
        </ButtonsContainer>
      }
    >
      <Container>
        {isSending
          ? sendingText
          : isSuccess
          ? successText
          : isError
          ? errorText
          : initialText}
      </Container>
    </Modal>
  );
};

DistributionPackageRequestModal.propTypes = {
  correlationId: PropTypes.string,
  isError: PropTypes.bool,
  isSending: PropTypes.bool,
  isSuccess: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

DistributionPackageRequestModal.defaultProps = {
  isError: false,
  isSending: false,
  isSuccess: false,
  visible: false,
};

export default DistributionPackageRequestModal;
