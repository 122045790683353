import styled from "styled-components";

import IconButtonBase from "../../../components/icon-button";

export const DownloadButtons = styled.div`
  grid-area: icon-buttons;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconButton = styled(IconButtonBase)`
  margin-right: ${({ theme }) => `${theme.spacing.unit}px`};
`;
