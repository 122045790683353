import { Button, Typography } from "@ts-digital/vrc";

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LoadingText = styled(Typography)`
  color: ${({ theme }) => theme.colors.status.maximumRed};
`;

export const RetryButton = styled(Button)`
  width: 140px;
  margin-top: ${({ theme }) => `${theme.spacing.unit * 3.5}px`};
`;
