import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";
import ReactSelect from "react-select";

import FaIcon from "../fa-icon";

export const Container = styled.div``;

export const AdditionalLabelContainer = styled.div`
  margin-left: 8px;
  display: inline;
`;

export const Label = styled(Typography)`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colors.text.cadet : `inerith`};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.64" : "1")};
`;

export const Action = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  font-weight: 200;
`;

export const SearchIcon = styled(FaIcon)`
  font-size: 16px;
  margin: 0 auto;
  font-weight: 400;
`;

export const BaseSelect = styled(ReactSelect)`
  .vrc-select__control {
    cursor: pointer;
    margin-bottom: 8px;
    box-sizing: border-box;
    border-radius: 0px;
    width: 100%;
    height: 40px;
    background: ${({ theme }) => theme.colors.background.whiteSmoke};
    border: 1px solid
      ${({ error, theme }) => {
        if (error) {
          return theme.colors.status.maximumRed;
        } else {
          return theme.colors.primary.columbiaBlue;
        }
      }};
    font-size: 100%;
    color: ${({ theme }) => theme.colors.text.yankeesBlue};
    transition: background 0.3s ease, border 0.3s ease;

    &:hover {
      border-color: ${({ error, theme }) =>
        error
          ? theme.colors.status.maximumRed
          : theme.colors.primary.richElectricBlue};
    }
  }

  .vrc-select__control--is-disabled {
    cursor: not-allowed;
    pointer-events: auto;
    background: #fafbfd;
    border: 1px solid #c1c1c4;
    &:hover {
      border-color: #c1c1c4;
    }
  }

  .vrc-select__placeholder {
    color: ${({ isDisabled, theme }) =>
      isDisabled ? theme.colors.text.silverSand : `hsl(0, 0%, 50%)`};
    opacity: ${({ isDisabled }) => (isDisabled ? "0.64" : "1")};
  }

  .vrc-select__placeholder {
    white-space: nowrap;
  }

  .vrc-select__control--is-focused {
    transition: border-color 0.3s;
    box-shadow: none;
    border-color: ${({ error, theme }) =>
      error
        ? theme.colors.status.maximumRed
        : theme.colors.primary.richElectricBlue};
  }

  .vrc-select__indicators {
    width: 40px;
  }

  .vrc-select__menu {
    top: auto;
    margin-top: 0;
    border-radius: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .vrc-select__option {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #fff;
    transition: color 0.3s ease, background-color 0.3s ease,
      border-color 0.2s ease;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.primary.richElectricBlue};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.secondary.azure};
    }
  }

  .vrc-select__option--is-selected {
    background-color: ${({ theme }) => theme.colors.secondary.azure};
    color: ${({ theme }) => theme.colors.primary.richElectricBlue};
    border: 1px solid ${({ theme }) => theme.colors.primary.richElectricBlue};
    outline: 0;
  }

  .vrc-select__option--is-focused {
    border: 1px solid ${({ theme }) => theme.colors.primary.richElectricBlue};
    outline: 0;
  }
`;
