import axios from "axios";
import fileSaver from "file-saver";

import config from "../../../config";
import getFileNameFromContentDisposition from "../../utils/get-file-name-from-content-disposition";
import { useQuery } from "react-query";

const useArticle52Pdf = (token, holderId, pdfExportRequestId) =>
  useQuery(
    ["article-52-pdf", pdfExportRequestId],
    async () => {
      const { data, headers } = await axios.get(
        `${config.archiveApiBasePath}/v1/archive/${holderId}/certificate/art52/pdf`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: token,
            "X-App-Name": config.appName,
            "X-App-Version": config.appVersion,
            "X-Request-ID": pdfExportRequestId,
            "X-Correlation-ID": pdfExportRequestId,
            Accept: "application/pdf",
          },
        }
      );

      const blob = new Blob([data], {
        type: "application/pdf",
      });
      const fileName = getFileNameFromContentDisposition(
        headers["content-disposition"]
      );
      fileSaver.saveAs(blob, fileName);
    },
    {
      enabled: !!token && !!holderId && !!pdfExportRequestId,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

export default useArticle52Pdf;
