import { Typography } from "@ts-digital/vrc";
import { ConfigProvider } from "antd";
import locale from "antd/lib/locale/it_IT";
import "moment/locale/it";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ErrorLabel from "../error-label";

import {
  AdditionalLabel,
  FieldLabel,
  InputContainer,
  LabelText,
  StyledInput,
} from "./styled";

const DatePicker = ({
  error,
  errorMessage,
  label,
  additionalLabel,
  onChange,
  placeholder,
  required,
  showTime,
  value,
  ...rest
}) => {
  const [isAdditionalLabelVisible, setAdditionalLabelVisible] = useState(false);

  return (
    <div
      {...rest}
      onMouseEnter={() => setAdditionalLabelVisible(true)}
      onMouseLeave={() => setAdditionalLabelVisible(false)}
    >
      <FieldLabel>
        <Typography variant={"bodyRegular"}>
          <LabelText>{`${label} ${required ? "*" : ""}`}</LabelText>
          {additionalLabel && isAdditionalLabelVisible && (
            <AdditionalLabel>{additionalLabel}</AdditionalLabel>
          )}
        </Typography>
        <Typography variant={"descriptive"}></Typography>
      </FieldLabel>
      <InputContainer>
        <ConfigProvider locale={locale}>
          {showTime ? (
            <StyledInput
              onChange={onChange}
              placeholder={placeholder}
              showTime={{ format: "HH:mm" }}
              format="DD/MM/YYYY HH:mm"
              value={value}
              style={{
                border: `${error ? "1px solid #d82829" : ""}`,
                marginBottom: "3px",
              }}
            />
          ) : (
            <StyledInput
              onChange={onChange}
              placeholder={placeholder}
              format={"DD/MM/YYYY"}
              value={value}
              showTime={showTime}
              style={{
                border: `${error ? "1px solid #d82829" : ""}`,
                marginBottom: "3px",
              }}
            />
          )}
        </ConfigProvider>
        {error && (
          <ErrorLabel variant={"descriptive"}>{errorMessage}</ErrorLabel>
        )}
      </InputContainer>
    </div>
  );
};

DatePicker.defaultProps = {
  error: false,
};

DatePicker.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  additionalLabel: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showTime: PropTypes.bool,
  value: PropTypes.object,
};

export default DatePicker;
