import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

import ErrorLabel from "../../../../../../../components/error-label";

import { Container, GridInput } from "./styled";

const SwInputs = ({ control, index, item, unregister }) => {
  useEffect(() => {
    return function cleanup() {
      unregister([`details.soggetti.${index}.denominazione`], {
        keepValue: false,
      });
    };
  }, [index, unregister]);

  return (
    <Container>
      <Controller
        name={`details.soggetti.${index}.denominazione`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.denominazione || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"name"}
            id={`details.soggetti.${index}.denominazione`}
            label={"Sistema software"}
            placeholder={
              "Inserisci la denominazione del sistema software che ha prodotto il documento"
            }
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {
                  "Inserisci la denominazione del sistema software che ha prodotto il documento"
                }
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    </Container>
  );
};

SwInputs.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default SwInputs;
