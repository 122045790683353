import PropTypes from "prop-types";
import React from "react";

import { Container } from "./styled";

const Link = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
);

Link.propTypes = {
  children: PropTypes.node,
};

export default Link;
