import { Tab } from "@ts-digital/vrc";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";

import config from "../../../config";
import DistributionPackagesView from "../distribution-packages";
import InputPackagesView from "../input-packages";
import ReceivedInputPackagesView from "../received-input-packages";
import RectifyDocument from "../rectify-document";
import UploadDocumentView from "../upload-document";
import Article52 from "../article-52";
import { Tabs } from "./styled";

let routes = [
  {
    path: "/input-packages",
    description: "Documenti conservati",
    component: <InputPackagesView />,
  },
  ...(config.enableReceivedInputPackagesPage === "enabled"
    ? [
        {
          path: "/upload-input-packages",
          description: "Documenti da processare",
          component: <ReceivedInputPackagesView />,
        },
      ]
    : []),
  {
    path: "/upload-input-package",
    description: "Carica documento",
    component: <UploadDocumentView />,
  },
  {
    path: "/distribution-packages",
    description: "Pacchetti di distribuzione",
    component: <DistributionPackagesView />,
  },
  {
    path: "/article-52",
    description: "Dichiarazione del conservatore",
    component: <Article52 />,
  },
  {
    hide: true,
    path: "/rectify-input-package",
    description: "Rettifica documento",
    component: <RectifyDocument />,
  },
];

const Root = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <Tabs
        activeIndex={routes
          .filter((route) => !route.hide)
          .findIndex((route) => route.path === location.pathname)}
        onChange={(i) => history.push(routes[i].path)}
        size="small"
      >
        {routes
          .filter((route) => !route.hide)
          .map((route) => (
            <Tab key={route.path}>{route.description}</Tab>
          ))}
      </Tabs>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path} path={route.path}>
            {route.component}
          </Route>
        ))}
        <Route path="/">
          <Redirect to={routes[0].path} />
        </Route>
      </Switch>
    </>
  );
};

export default Root;
