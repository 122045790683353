import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { CloseButton, Content, InfoIcon, Text, Title } from "./styled";

const InfoTooltip = ({ className, title, text }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleOnTooltipClose = () => {
    setTooltipOpen(false);
  };
  const handleOnTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const TooltipContent = (
    <Content>
      <Title variant={"descriptive"}>{title}</Title>
      <Text variant={"descriptive"}>{text}</Text>
      <CloseButton kind={"tertiary"} onClick={handleOnTooltipClose}>
        {"Chiudi"}
      </CloseButton>
    </Content>
  );

  return (
    <Tooltip
      title={TooltipContent}
      visible={isTooltipOpen}
      trigger={"click"}
      color={"#005075"}
      overlayStyle={{ width: "100%", maxWidth: "288px" }}
    >
      <InfoIcon
        className={className}
        icon={faInfoCircle}
        onClick={handleOnTooltipOpen}
      />
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default InfoTooltip;
