const getFileNameFromContentDisposition = (contentDisposition) => {
  if (!contentDisposition) {
    return "download";
  }

  return contentDisposition
    .split(";")
    .find((n) => n.includes("filename="))
    .replace("filename=", "")
    .trim();
};

export default getFileNameFromContentDisposition;
