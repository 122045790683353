import axios from "axios";
import { useQuery } from "react-query";
import { v4 } from "uuid";

import config from "../../../config";
import { convertDates, buildFile, buildSubjects, buildHolder } from "./utils";

const useInputPackage = (token, holderId, inputPackageId) =>
  useQuery(
    ["input-packages", token, holderId, inputPackageId],
    async () => {
      if (!token || !holderId || !inputPackageId) {
        return;
      }

      const requestId = v4();
      const inputPackageResponse = await axios.get(
        `${config.archiveApiBasePath}/v1/archive/${holderId}/documents/${inputPackageId}`,
        {
          headers: {
            Authorization: token,
            "X-App-Name": config.appName,
            "X-App-Version": config.appVersion,
            "X-Request-ID": requestId,
            "X-Correlation-ID": requestId,
          },
        }
      );

      const inputPackageContentResponse = await axios.get(
        `${config.archiveApiBasePath}/v1/archive/${holderId}/documents/${inputPackageId}/content`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: token,
            "X-App-Name": config.appName,
            "X-App-Version": config.appVersion,
            "X-Request-ID": requestId,
            "X-Correlation-ID": requestId,
          },
        }
      );

      const pipe =
        (...functions) =>
        (payload) =>
          functions.reduce((result, next) => next(result), payload);

      const rawPayload = {
        ...inputPackageResponse.data,
        file: {
          ...inputPackageResponse.data.file,
          content: inputPackageContentResponse.data,
        },
      };

      return pipe(
        convertDates,
        buildFile,
        buildSubjects,
        buildHolder
      )(rawPayload);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 3,
    }
  );

export default useInputPackage;
