import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import PropTypes from "prop-types";
import { useRecoilState } from "recoil";

import userAtom from "../../../../core/atoms/user";
import usePreviewByContent from "../../../../core/hooks/preview-by-content";

import { Content, Iframe, LoadingContainer, SendingIcon } from "./styled";
import NoPreview from "../no-preview";

const Preview = ({ fileContent, typology }) => {
  const [user] = useRecoilState(userAtom);
  const preview = usePreviewByContent(
    user.token,
    Buffer.from(fileContent).toString("base64"),
    typology
  );

  return preview.isFetching ? (
    <LoadingContainer>
      {
        <div>
          <SendingIcon icon={faCircleNotch} />
          <p>{"Caricamento file in corso..."}</p>
        </div>
      }
    </LoadingContainer>
  ) : preview.data ? (
    <Content>
      <Iframe title={"Preview"} srcDoc={preview.data} />
    </Content>
  ) : (
    <NoPreview />
  );
};

Preview.propTypes = {
  fileContent: PropTypes.string.isRequired,
  typology: PropTypes.string,
};

export default Preview;
