const roles = {
  ASSEGNATARIO: { label: "Assegnatario", types: ["AS"] },
  AUTORE: { label: "Autore", types: ["PF", "PG"] },
  DESTINATARIO: { label: "Destinatario", types: ["PF", "PG", "PA"] },
  MITTENTE: { label: "Mittente", types: ["PF", "PG", "PA"] },
  OPERATORE: { label: "Operatore", types: ["PF"] },
  PRODUTTORE: { label: "Produttore", types: ["SW"] },
  RGD: {
    label: "Responsabile della Gestione Documentale (RGD)",
    types: ["PF"],
  },
  RSP: {
    label: "Responsabile del Servizio di Protocollo (RSP)",
    types: ["PF"],
  },
  SER: { label: "Soggetto che effettua la registrazione", types: ["PF", "PG"] },
  ALTRO: { label: "Altro", types: ["PF", "PG", "PA"] },
};

const rolesMapping = {
  assegnatari: "ASSEGNATARIO",
  autori: "AUTORE",
  destinatari: "DESTINATARIO",
  mittenti: "MITTENTE",
  operatori: "OPERATORE",
  produttori: "PRODUTTORE",
  rgd: "RGD",
  rsp: "RSP",
  ser: "SER",
  altri: "ALTRO",
};

const rolesMappingToLowerCase = {
  ASSEGNATARIO: "assegnatari",
  AUTORE: "autori",
  DESTINATARIO: "destinatari",
  MITTENTE: "mittenti",
  OPERATORE: "operatori",
  PRODUTTORE: "produttori",
  RGD: "rgd",
  RSP: "rsp",
  SER: "ser",
  ALTRO: "altri,",
};

const rolesLabelMapping = {
  assegnatari: "Assegnatario",
  autori: "Autore",
  destinatari: "Destinatario",
  mittenti: "Mittente",
  operatori: "Operatore",
  produttori: "Produttore",
  rgd: "Responsabile della Gestione Documentale (RGD)",
  rsp: "Responsabile del Servizio di Protocollo (RSP)",
  ser: "Soggetto che effettua la registrazione",
  altri: "Altro",
};

const rolesTypesMapping = {
  assegnatari: ["AS"],
  autori: ["PF", "PG"],
  destinatari: ["PF", "PG", "PA"],
  mittenti: ["PF", "PG", "PA"],
  operatori: ["PF"],
  produttori: ["SW"],
  rgd: ["PF"],
  rsp: ["PF"],
  ser: ["PF", "PG"],
  altri: ["PF", "PG", "PA"],
};

const typeLabelMapping = {
  PF: "Persona fisica",
  PG: "Persona giuridica",
  PA: "Pubblica amministrazione",
};

export {
  roles,
  rolesMapping,
  rolesMappingToLowerCase,
  rolesLabelMapping,
  rolesTypesMapping,
  typeLabelMapping,
};
