import qs from "qs";
import axios from "axios";
import fileSaver from "file-saver";

import getFileNameFromContentDisposition from "../../utils/get-file-name-from-content-disposition";
import config from "../../../config";
import { useQuery } from "react-query";
import omitEmpty from "../../utils/omit-empty";

export const fetchInputPackagesCSV = async ({
  token,
  holderId,
  documentTypeCode,
  searchString,
  referenceYear,
  from,
  to,
  requestId,
}) => {
  if (!holderId || !documentTypeCode || !referenceYear || !token) {
    return;
  }

  const searchParams = {
    documentTypeCode,
    searchString,
    referenceYear,
    from,
    to,
  };

  const { data, headers } = await axios.get(
    `${config.archiveApiBasePath}/v1/archive/${holderId}/export?${qs.stringify(
      omitEmpty(searchParams)
    )}`,
    {
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );

  const blob = new Blob([data], {
    type: "text/csv;charset=utf-8",
  });
  const fileName = getFileNameFromContentDisposition(
    headers["content-disposition"]
  );
  fileSaver.saveAs(blob, fileName);
};

const useInputPackagesCSV = (
  token,
  holderId,
  documentTypeCode,
  searchString,
  referenceYear,
  from,
  to,
  csvExportRequestId
) =>
  useQuery(
    "input-packages-csv",
    () =>
      fetchInputPackagesCSV({
        token,
        holderId,
        documentTypeCode,
        searchString,
        referenceYear,
        from: from ? from.startOf("day").valueOf() : null,
        to: to ? to.endOf("day").valueOf() : null,
        requestId: csvExportRequestId,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

export default useInputPackagesCSV;
