import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";
import { docNumber, registryCode } from "../../../../../utils/regex";

import DatePicker from "../../../../components/date-picker";
import ErrorLabel from "../../../../components/error-label";
import InfoTooltip from "../../../../components/info-tooltip";
import SectionHeader from "../../../../components/section-header";

import {
  ClassificationDescription,
  ClassifcationIndex,
  ClassificationPlan,
  ClassificationGridContainer,
  Container,
  FlowTypeSelect,
  RegistryCode,
  RegistryNumber,
  RegistryTypeSelect,
  Spacing,
  SubscriptionDataGridContainer,
} from "./styled.js";

const flowTypesMapping = [
  {
    label: "Entrata",
    value: "E",
  },
  {
    label: "Uscita",
    value: "U",
  },
  {
    label: "Interno",
    value: "I",
  },
];

const registryTypesMapping = [
  {
    label: "Nessuno",
    value: "NESSUNO",
  },
  {
    label: "Protocollo",
    value: "PROTOCOLLO_ORDINARIO_EMERGENZA",
  },
  {
    label: "Repertorio o registro",
    value: "REPERTORIO_REGISTRO",
  },
];

const Step4 = ({ control, unregister, watch }) => {
  const watchRegistry = watch("details.dati_registrazione.tipo_registro");

  const onRegistryTypeChange = (value, onChange) => {
    onChange(value);

    if (value === "NESSUNO")
      unregister("details.dati_registrazione.codice_registro");
  };

  return (
    <Container>
      <Spacing>
        <SectionHeader
          title={"REGISTRAZIONE"}
          subtitle={"Tipo di flusso e altri dati di registrazione."}
        />
        <SubscriptionDataGridContainer>
          <Controller
            name={"details.dati_registrazione.tipologia_flusso"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <FlowTypeSelect
                label={"Tipo di flusso"}
                additionalLabel={
                  <InfoTooltip
                    title={"Tipo di flusso"}
                    text={
                      "Indica se si tratta di un documento in uscita, in entrata o interno. Per es: per i privati i documenti del ciclo attivo sono qualificati come “In uscita”, i documenti del ciclo passivo “In entrata” ; i Libri e registri contabili come “Interno” ecc."
                    }
                  />
                }
                error={!!error}
                errorMessage={"Seleziona un tipo di flusso"}
                options={flowTypesMapping.map((flowType) => ({
                  value: flowType.value,
                  label: flowType.label,
                }))}
                placeholder={"Seleziona un tipo di flusso"}
                required={true}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Controller
            name={"details.dati_registrazione.tipo_registro"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <RegistryTypeSelect
                label={"Tipo di registro"}
                additionalLabel={
                  <InfoTooltip
                    title={"Tipo di registro"}
                    text={"Per i Privati valorizzare con Nessuno."}
                  />
                }
                error={!!error}
                errorMessage={"Seleziona un tipo di registro"}
                options={registryTypesMapping.map((flowType) => ({
                  value: flowType.value,
                  label: flowType.label,
                }))}
                placeholder={"Seleziona un tipo di registro"}
                required={true}
                onChange={(value) =>
                  onRegistryTypeChange(value, field.onChange)
                }
                value={field.value}
              />
            )}
          />
          <Controller
            name={"details.dati_registrazione.data"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label={"Data documento"}
                additionalLabel={
                  <InfoTooltip
                    title={"Data documento"}
                    text={
                      "Senza protocollo come nel caso di Privati: valorizzare con data e ora registrazione, ossia il momento in cui al documento è stata attribuita una data e un numero (per es.  la data del documento oppure l’ultimo giorno del periodo fiscale di riferimento, ecc.). Con protocollo come nel caso delle PA: valorizzare con Data e ora della registrazione di protocollo."
                    }
                  />
                }
                required={true}
                error={!!error}
                errorMessage={"Seleziona una data"}
                placeholder={"Seleziona una data"}
                onChange={field.onChange}
                value={field.value ? moment(field.value) : null}
              />
            )}
          />
          <Controller
            name={"details.dati_registrazione.numero"}
            control={control}
            defaultValue={""}
            rules={{
              required: true,
              pattern:
                watchRegistry === "PROTOCOLLO_ORDINARIO_EMERGENZA"
                  ? docNumber
                  : null,
            }}
            render={({ field, fieldState: { error } }) => (
              <RegistryNumber
                id={"details.dati_registrazione.numero"}
                label={"Numero documento"}
                additionalLabel={
                  <InfoTooltip
                    title={"Numero documento"}
                    text={
                      "Senza protocollo come nel caso di Privati: valorizzare con numero Registrazione documento (per es. numero del documento; ultimo giorno del periodo di riferimento per un registro o libro contabile, ecc). Con protocollo come nel caso delle PA: valorizzare con numero Protocollo."
                    }
                  />
                }
                error={!!error}
                errorMessage={
                  <ErrorLabel>
                    {error && error.type === "pattern"
                      ? "Il formato del valore non è corretto, deve essere un numero di almeno 7 cifre, esempio: '1234567'"
                      : "Inserisci il numero del documento"}
                  </ErrorLabel>
                }
                placeholder={"Inserisci un numero documento"}
                required={true}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          {(watchRegistry === "PROTOCOLLO_ORDINARIO_EMERGENZA" ||
            watchRegistry === "REPERTORIO_REGISTRO") && (
            <Controller
              name={"details.dati_registrazione.codice_registro"}
              control={control}
              defaultValue={""}
              rules={{ required: true, pattern: registryCode }}
              render={({ field, fieldState: { error } }) => (
                <RegistryCode
                  id={"details.dati_registrazione.codice_registro"}
                  label={"Codice registro"}
                  additionalLabel={
                    <InfoTooltip
                      title={"Codice registro"}
                      text={
                        "Codice identificativo del registro, lasciare vuoto se il documento non è registrato."
                      }
                    />
                  }
                  error={!!error}
                  errorMessage={
                    <ErrorLabel>
                      {error && error.type === "pattern"
                        ? "Il formato del valore non è corretto, può avere una lunghezza massima di 16 caratteri e contenere lettere e numero, oltre ai caratteri speciali (_ . -)"
                        : "Inserisci il codice del registro"}
                    </ErrorLabel>
                  }
                  placeholder={"Inserisci un codice di registro"}
                  required={true}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          )}
        </SubscriptionDataGridContainer>
      </Spacing>
      <Spacing>
        <SectionHeader
          title={"CLASSIFICAZIONE"}
          subtitle={"Dati relativi alla classificazione del documento."}
        />
        <ClassificationGridContainer>
          <Controller
            name={"details.classificazione.indice"}
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <ClassifcationIndex
                id={"details.classificazione.indice"}
                label={"Indice di classificazione"}
                additionalLabel={
                  <InfoTooltip
                    title={"Indice di classificazione"}
                    text={
                      "Codifica del documento secondo il Piano di Classificazione."
                    }
                  />
                }
                placeholder={"Inserisci l’indice di classificazione"}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Controller
            name={"details.classificazione.descrizione"}
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <ClassificationDescription
                id={"details.classificazione.descrizione"}
                label={"Descrizione"}
                additionalLabel={
                  <InfoTooltip
                    title={"Descrizione"}
                    text={
                      "Descrizione per esteso dell'Indice di Classificazione."
                    }
                  />
                }
                placeholder={
                  "Inserisci la descrizione dell’indice di classificazione"
                }
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Controller
            name={"details.classificazione.piano"}
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <ClassificationPlan
                id={"details.classificazione.piano"}
                label={"Piano di classificazione"}
                additionalLabel={
                  <InfoTooltip
                    title={"Piano di classificazione"}
                    text={"URI del Piano di Classificazione pubblicato."}
                  />
                }
                placeholder={"Iserisci l’URI del piano di classificazione"}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
        </ClassificationGridContainer>
      </Spacing>
    </Container>
  );
};

Step4.propTypes = {
  control: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};

export default Step4;
