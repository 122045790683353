import axios from "axios";
import { useQuery } from "react-query";
import { v4 } from "uuid";

import config from "../../../config/index";

export const fetchHolders = async ({ token }) => {
  const requestId = v4();
  const { data } = await axios.get(
    `${config.archiveApiBasePath}/v1/archive/subjects`,
    {
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );
  return data;
};

const useHolders = (token) =>
  useQuery("holders", () => fetchHolders({ token }), {
    enabled: !!token,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 3,
  });

export default useHolders;
