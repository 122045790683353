import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../fa-icon";

export const Error = styled(Typography)`
  color: ${({ theme }) => theme.colors.status.maximumRed};
  font-weight: 200;
  font-size: 12px;
`;

export const Icon = styled(FaIcon)`
  font-size: 10px;
  margin-right: 8px;
`;
