import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";

import userAtom from "./core/atoms/user";
import inheritedDocumentTypeCodeAtom from "./core/atoms/inheritedDocumentTypeCode";
import "./App.css";

import Root from "./ui/views/root";

function App({ inheritedDocumentTypeCode, token }) {
  const setInheritedDocumentTypeCode = useSetRecoilState(
    inheritedDocumentTypeCodeAtom
  );
  const setUser = useSetRecoilState(userAtom);

  useEffect(() => {
    setInheritedDocumentTypeCode(inheritedDocumentTypeCode);
    setUser({ token });
  }, [setInheritedDocumentTypeCode, setUser, inheritedDocumentTypeCode, token]);

  return (
    <Router>
      <Root />
    </Router>
  );
}

App.propTypes = {
  inheritedDocumentTypeCode: PropTypes.string,
  token: PropTypes.string,
};

export default App;
