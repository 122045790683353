import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

import KeywordInput from "../../../../../../../../components/keyword-input";

export const Container = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-template-areas:
    "ipaCode name"
    "ipaCodeAoo nameAoo"
    "ipaCodeUor nameUor"
    "taxId vatNumber"
    "emails emails";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 100px);
`;

export const GridInput = styled(Input)`
  grid-area: ${({ area }) => area};
`;

export const EmailsInput = styled(KeywordInput)`
  grid-area: emails;
`;
