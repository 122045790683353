import { Button, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  height: 150px;
`;

export const ButtonsContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing.unit}px`};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
`;

export const AbortButton = styled(Button)`
  margin-right: ${({ theme }) => `${theme.spacing.unit * 2}px`};
`;

export const Text = styled(Typography)`
  font-weight: normal;
`;
