import { DatePicker } from "antd";
import styled from "styled-components";

export const FieldLabel = styled.div`
  display: block;
`;

export const LabelText = styled.label`
  color: ${(props) => props.theme.colors.text.yankeesBlue};
`;

export const InputContainer = styled.div``;

export const StyledInput = styled(DatePicker)`
  background: ${(props) => props.theme.colors.background.whiteSmoke};
  width: 100%;
  height: 40px;
  border: ${(props) => `1px solid ${props.theme.colors.primary.columbiaBlue}`};
  border-radius: 0px;
  color: ${(props) => props.theme.colors.text.yankeesBlue};
  &:placeholder {
    color: ${(props) => props.theme.colors.text.quickSilver};
  }
  &:hover,
  &:focus,
  &:active {
    border: ${(props) =>
      `1px solid ${props.theme.colors.primary.richElectricBlue}`};
    outline: 0px;
    box-shadow: none;
  }
`;

export const AdditionalLabel = styled.div`
  display: inline-block;
  margin-left: 8px;
`;
