import axios from "axios";
import { useInfiniteQuery } from "react-query";
import { v4 } from "uuid";

import config from "../../../config";

const useReceivedInputPackages = (token, holderId) =>
  useInfiniteQuery(
    ["received-input-packages", holderId],
    async () => {
      const requestId = v4();
      const { data } = await axios.get(
        `${config.archiveApiBasePath}/v1/archive/${holderId}/received-documents?size=10`,
        {
          headers: {
            Authorization: token,
            "X-App-Name": config.appName,
            "X-App-Version": config.appVersion,
            "X-Request-ID": requestId,
            "X-Correlation-ID": requestId,
          },
        }
      );

      return data;
    },
    {
      enabled: !!token && !!holderId,
      refetchOnWindowFocus: false,
      retry: 0,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

export default useReceivedInputPackages;
