import { faFile } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { Content, FileIcon, NoPreviewContainer, Text } from "./styled";

const NoPreview = () => (
  <Content>
    <NoPreviewContainer>
      <FileIcon icon={faFile} />
      <Text>
        {
          "Non è possibile visualizzare l’anteprima dei documenti in questo formato"
        }
      </Text>
    </NoPreviewContainer>
  </Content>
);

export default NoPreview;
