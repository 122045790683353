import { Toggle, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import InfoTooltipBase from "../info-tooltip";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const SpacedToggle = styled(Toggle)`
  margin-right: 8px;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.yankeesBlue};
  font-weight: normal;
`;

export const InfoTooltip = styled(InfoTooltipBase)`
  margin-left: 8px;
`;
