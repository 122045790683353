import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";

import {
  BoldText,
  CheckIcon,
  Container,
  SpacedTag,
  StatusInfo,
  DocumentDate,
  ReceivedDate,
  Title,
} from "./styled.js";

const ReceivedInputPackagePanel = React.memo(
  ({
    number,
    typologyDescription,
    documentDate,
    rectifiedInputPackageId,
    rectificationReason,
    receivedDate,
  }) => (
    <Container>
      <Title data-testid={"title"} variant="h4">
        {`${typologyDescription} ${number}`}
      </Title>
      <StatusInfo>
        {rectifiedInputPackageId && (
          <Tooltip
            title={`${rectificationReason
              .charAt(0)
              .toUpperCase()}${rectificationReason.slice(1)}`}
          >
            <SpacedTag color={"RoyalFuchsia"}>{"Rettificante"}</SpacedTag>
          </Tooltip>
        )}
        <DocumentDate data-testid={"document-date"} variant="bodyRegular">
          {`Documento del ${new Date(documentDate).toLocaleDateString("it-IT", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}`}
        </DocumentDate>
        <ReceivedDate data-testid={"received-date"} variant="bodyRegular">
          <CheckIcon icon={faCheck} />
          {"Inviato il "}
          <BoldText>
            {new Date(receivedDate).toLocaleDateString("it-IT", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </BoldText>
          {" ore "}
          <BoldText>
            {new Date(receivedDate).toLocaleTimeString("it-IT", {
              hour: "numeric",
              minute: "numeric",
            })}
          </BoldText>
        </ReceivedDate>
      </StatusInfo>
    </Container>
  )
);

ReceivedInputPackagePanel.propTypes = {
  number: PropTypes.string,
  typologyDescription: PropTypes.string,
  documentDate: PropTypes.number,
  rectifiedInputPackageId: PropTypes.string,
  rectificationReason: PropTypes.string,
  receivedDate: PropTypes.number,
};

export default ReceivedInputPackagePanel;
