import { faDoNotEnter } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import React from "react";

import { Error, Icon } from "./styled.js";

const ErrorLabel = ({ children }) => (
  <Error>
    <Icon icon={faDoNotEnter} /> {children}
  </Error>
);

ErrorLabel.propTypes = {
  children: PropTypes.node,
};

export default ErrorLabel;
