import { faExchange, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import crypto from "crypto";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import ErrorLabel from "../error-label";
import Preview from "./preview";

import {
  ActionsContainer,
  Container,
  DeleteButton,
  Error,
  FileName,
  Header,
  Hint,
  InputContainer,
  Label,
  LoadingContainer,
  SwitchButton,
} from "./styled.js";

const loadingContent = ({ loadingText }) => (
  <LoadingContainer>{loadingText}</LoadingContainer>
);

const errorContent = ({ getInputProps, open, disabled, error }) => (
  <div>
    <input {...getInputProps()} />
    <>
      <div>
        <Error variant={"bodyRegular"}>{error}</Error>
      </div>
      <Button kind={"secondary"} onClick={open} disabled={disabled}>
        Carica file
      </Button>
    </>
  </div>
);

const previewContent = ({ getInputProps, value, typology }) => (
  <>
    <input {...getInputProps()} />
    <Preview fileContent={value.content} typology={typology} />
  </>
);

const defaultContent = ({ getInputProps, disabled, open, hint }) => (
  <div>
    <input {...getInputProps()} />
    <>
      <div>
        <Hint variant={"bodyRegular"} disabled={disabled}>
          {hint}
        </Hint>
      </div>
      <Button kind={"secondary"} onClick={open} disabled={disabled}>
        Carica file
      </Button>
    </>
  </div>
);

const Uploader = ({
  acceptedExtensions,
  disabled,
  error,
  errorMessage,
  label,
  hint,
  onFileLoaded,
  onReset,
  showFilesList,
  showPreview,
  showActionButton,
  typology,
  loadingText,
  value,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dropError, setDropError] = useState(null);

  const handleOnFileLoaded = useCallback(
    (content, fileName, fileHash) => {
      setIsLoading(false);
      if (onFileLoaded) {
        onFileLoaded(content, fileName, fileHash);
      }
    },
    [onFileLoaded]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onloadstart = () => {
          setIsLoading(true);
        };
        reader.onloadend = () => {
          const fileHash = crypto
            .createHash("sha256")
            .update(Buffer.from(reader.result))
            .digest("hex");

          handleOnFileLoaded(reader.result, file.name, fileHash);
        };

        reader.readAsArrayBuffer(file);
      });
    },
    [handleOnFileLoaded]
  );

  const onDropRejected = (filesRejection) => {
    if (filesRejection[0].errors[0].code === "file-too-large") {
      setDropError("Il file supera la dimensione massima di 300MB");
    }
  };

  const onDropAccepted = () => {
    setDropError(null);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onDrop,
    disabled,
    maxFiles: 1,
    maxSize: 316870912,
    noClick: true,
    noKeyboard: true,
    accept: acceptedExtensions ? acceptedExtensions.join(",") : "",
  });

  const handleOnDelete = () => {
    onReset();
  };

  const handleOnChange = () => {
    open();
  };

  const isPreviewVisible = value && value.content && showPreview;

  const getContent = () => {
    if (isLoading) {
      return loadingContent({ loadingText });
    }

    if (!isLoading && isPreviewVisible) {
      return previewContent({ getInputProps, value, typology });
    }

    if (dropError) {
      return errorContent({ getInputProps, disabled, open, error: dropError });
    }

    return defaultContent({
      getInputProps,
      disabled,
      open,
      hint,
    });
  };

  return (
    <InputContainer>
      <Header>
        <Label disabled={disabled}>
          {label}
          <FileName>{value && value.content && `: ${value.name}`}</FileName>
        </Label>
        {showActionButton && value && value.content && (
          <ActionsContainer>
            <SwitchButton
              kind={"tertiary"}
              icon={<FontAwesomeIcon icon={faExchange} />}
              onClick={handleOnChange}
            >
              {"Cambia"}
            </SwitchButton>
            <DeleteButton
              kind={"tertiary"}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={handleOnDelete}
            >
              {"Elimina"}
            </DeleteButton>
          </ActionsContainer>
        )}
      </Header>
      <Container
        error={error}
        value={value}
        showPreview={showPreview}
        disabled={disabled}
        {...rest}
        {...getRootProps()}
      >
        {getContent()}
      </Container>
      {error && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </InputContainer>
  );
};

Uploader.defaultProps = {
  acceptedExtensions: [],
  showFilesList: false,
  showPreview: false,
  showActionButton: false,
  onReset: () => {},
};

Uploader.propTypes = {
  acceptedExtensions: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  onFileLoaded: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  loadingText: PropTypes.node,
  showActionButton: PropTypes.bool,
  showFilesList: PropTypes.bool,
  showPreview: PropTypes.bool,
  typology: PropTypes.string,
  value: PropTypes.object,
};

export default Uploader;
