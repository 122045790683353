import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { Button, Input, Modal } from "@ts-digital/vrc";
import { Radio, Space } from "antd";
import crypto from "crypto";
import PropTypes from "prop-types";
import React, { useState } from "react";

import BoldText from "../bold-text";
import ErrorLabel from "../error-label";
import Link from "../link";
import RadioNode from "./radio-node";

import {
  AttachmentUploader,
  ButtonsContainer,
  Container,
  Description,
  DiscardButton,
  SendingIcon,
} from "./styled";

const FileSha256Extractor = ({
  title,
  isVisible,
  isLoading,
  onClose,
  onConfirm,
}) => {
  const [inputType, setInputType] = useState("attachment");
  const [isHashMissing, setIsHashMissing] = useState(false);
  const [isHashWrong, setIsHashWrong] = useState(false);
  const [hash, setHash] = useState("");

  const handleInputTypeChange = ({ target: { value } }) => {
    setInputType(value);
    setIsHashMissing(false);
  };

  // generate hash in case of file upload
  const handleOnFileDrop = (binaryString) => {
    onConfirm(
      crypto
        .createHash("sha256")
        .update(Buffer.from(binaryString))
        .digest("base64")
    );
  };

  const handleInputChange = (value) => {
    setHash(value);
    setIsHashMissing(false);
  };

  // use the hash if provided directly
  const handleOnModalConfirm = () => {
    if (!hash) {
      setIsHashMissing(true);
      return;
    }

    if (hash.length !== 44) {
      setIsHashWrong(true);
      return;
    }

    onConfirm(hash);
    setHash("");
  };

  const handleOnModalClose = () => {
    onClose();
    setIsHashMissing(false);
    setHash("");
  };

  const getErrorMessage = () => {
    if (isHashMissing) {
      return "Inserisci l'hash del documento";
    }
    if (isHashWrong) {
      return "L'hash deve avere una lunghezza di 44 caratteri";
    }
  };

  const loadingText = (
    <div>
      <SendingIcon icon={faCircleNotch} />
      <p>{"Stiamo estraendo i caratteri di controllo..."}</p>
    </div>
  );

  return (
    <Modal
      visible={isVisible}
      title={title}
      onClose={handleOnModalClose}
      abortText={"Annulla"}
      confirmText={"Continua"}
      closable={true}
      outsideClosable={false}
      footer={
        <ButtonsContainer>
          {isLoading ? null : (
            <>
              <DiscardButton kind={"secondary"} onClick={handleOnModalClose}>
                {"Annulla"}
              </DiscardButton>
              <Button kind={"primary"} onClick={handleOnModalConfirm}>
                {"Avanti"}
              </Button>
            </>
          )}
        </ButtonsContainer>
      }
    >
      <Container>
        <Description variant="bodyRegular">
          Il file non sarà conservato in questa sezione. Qui{" "}
          <BoldText variant="bodyRegular">
            inseriamo solo la sua impronta crittografica{" "}
          </BoldText>
          calcolata con l’algoritmo{" "}
          <Link href={"https://en.wikipedia.org/wiki/SHA-2"} target={"_blank"}>
            SHA-256
          </Link>
          .
        </Description>
        <Radio.Group value={inputType} onChange={handleInputTypeChange}>
          <Space direction={"vertical"} size={"large"}>
            <Radio value={"attachment"}>
              <RadioNode
                title={"Carica file"}
                description={
                  "L’impronta crittografica sarà calcolata a partire dal file che carichi. Dimensione massima 300Mb."
                }
              />
              <AttachmentUploader
                disabled={inputType === "input"}
                hint={"Trascina l'allegato in quest'area, oppure"}
                onFileLoaded={handleOnFileDrop}
                loadingText={loadingText}
              />
            </Radio>
            <Radio value={"input"}>
              <RadioNode
                title={"Incolla impronta crittografica"}
                description={
                  "Se possiedi già l’impronta crittografica del file calcolata con algoritmo SHA-256 copiala e incollala qui."
                }
              />
              <Input
                disabled={inputType === "attachment"}
                placeholder={
                  "Incolla i 44 caratteri dell’impronta crittografica"
                }
                error={isHashMissing || isHashWrong}
                errorMessage={<ErrorLabel>{getErrorMessage()}</ErrorLabel>}
                onChange={handleInputChange}
                value={hash}
              />
            </Radio>
          </Space>
        </Radio.Group>
      </Container>
    </Modal>
  );
};

FileSha256Extractor.defaultProps = {
  isVisible: false,
  isLoading: false,
};

FileSha256Extractor.propTypes = {
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default FileSha256Extractor;
