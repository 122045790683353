import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  justify-items: start;
  grid-column-gap: 32px;
  grid-template-columns: 2.5fr 4.5fr 0.5fr 0.5fr;
`;

export const IdLabel = styled(Typography)`
  width: 100%;
`;

export const AggregationTypeLabel = styled(Typography)`
  width: 100%;
`;
