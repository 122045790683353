import axios from "axios";
import { useMutation } from "react-query";

import config from "../../../config";
import omitEmpty from "../../utils/omit-empty";
import {
  buildChangesTracking,
  buildFile,
  buildHolder,
  buildIdAgg,
  buildSubjects,
} from "./utils";

export const uploadDocument = async ({ token, formPayload, requestId }) => {
  if (!token || !formPayload) {
    return;
  }

  const cleanPayload = omitEmpty(JSON.parse(JSON.stringify(formPayload)));
  const holderId = cleanPayload.holder.uuid;

  const pipe =
    (...functions) =>
    (payload) =>
      functions.reduce((result, next) => next(result), payload);

  const consistentPayload = pipe(
    buildFile,
    buildSubjects,
    buildIdAgg,
    buildChangesTracking,
    buildHolder
  )(cleanPayload);

  const formData = new FormData();
  formData.append("fileMetadata", JSON.stringify(consistentPayload.file));
  formData.append("details", JSON.stringify(consistentPayload.details));
  formData.append("holder", JSON.stringify(consistentPayload.holder));
  formData.append("file", new Blob([formPayload.file.content]));

  return await axios.post(
    `${config.archiveApiBasePath}/v2/archive/${holderId}/documents`,
    formData,
    {
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );
};

const useUploadDocument = (token, formPayload, uploadDocumentRequestId) =>
  useMutation(() =>
    uploadDocument({
      token,
      formPayload,
      requestId: uploadDocumentRequestId,
    })
  );

export default useUploadDocument;
