import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

import { sdiCode, taxId } from "../../../../../../../../utils/regex";
import ErrorLabel from "../../../../../../../components/error-label";

import { Container, EmailsInput, GridInput } from "./styled";

const AsInputs = ({ control, index, item, unregister }) => {
  useEffect(() => {
    return function cleanup() {
      unregister(
        [
          `details.soggetti.${index}.denominazione`,
          `details.soggetti.${index}.denominazione_ufficio`,
        ],
        {
          keepValue: false,
        }
      );
    };
  }, [index, unregister]);

  return (
    <Container>
      <Controller
        name={`details.soggetti.${index}.denominazione`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.denominazione || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"name"}
            id={`details.soggetti.${index}.denominazione`}
            label={"Organizzazione"}
            placeholder={"Iserisci la denominazione dell’organizzazione"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {"Inserisci la denominazione dell’organizzazione"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.denominazione_ufficio`}
        control={control}
        defaultValue={item.denominazione_ufficio || ""}
        render={({ field }) => (
          <GridInput
            area={"office"}
            id={`details.soggetti.${index}.denominazione_ufficio`}
            label={"Ufficio"}
            placeholder={"Inserisci la denominazione dell’ufficio"}
            errorMessage={
              <ErrorLabel>
                {"Inserisci la denominazione dell’ufficio"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_sdi`}
        control={control}
        defaultValue={item.codice_sdi || ""}
        rules={{ pattern: sdiCode }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"sdiCode"}
            id={`details.soggetti.${index}.codice_sdi`}
            label={"Codice SDI"}
            placeholder={"Inserisci il codice destinatario SDI"}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {"Il formato del valore non è corretto, esempio: 'abc1234'"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.cognome`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.cognome || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"lastName"}
            id={`details.soggetti.${index}.cognome`}
            label={"Cognome"}
            placeholder={"Inserisci il cognome"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Inserisci il cognome del soggetto"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.nome`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.nome || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"firstName"}
            id={`details.soggetti.${index}.nome`}
            label={"Nome"}
            placeholder={"Inserisci il nome"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Inserisci il nome del soggetto"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_fiscale`}
        control={control}
        defaultValue={item.codice_fiscale || ""}
        rules={{ pattern: taxId }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"taxId"}
            id={`details.soggetti.${index}.codice_fiscale`}
            label={"Codice fiscale"}
            placeholder={"Inserisci il codice fiscale"}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Codice fiscale non valido"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
        control={control}
        defaultValue={item.indirizzi_digitali_riferimento || []}
        render={({ field }) => (
          <EmailsInput
            area={"emails"}
            id={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
            label={"Indirizzi digitali di riferimento"}
            placeholder={"Inserisci un indirizzo e-mail"}
            maximum={5}
            onChange={field.onChange}
            values={field.value}
          />
        )}
      />
    </Container>
  );
};

AsInputs.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default AsInputs;
