import { Button } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../fa-icon";

export const Container = styled.div`
  padding-top: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  width: 480px;
  height: 164px;
  text-align: center;
`;

export const ErrorIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.status.maximumRed};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  font-size: 32px;
`;

export const ButtonsContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacing.unit}px`};
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
`;

export const CloseButton = styled(Button)``;
