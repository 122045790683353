import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";

import ErrorLabel from "../../../../../components/error-label";
import FileSha256Extractor from "../../../../../components/file-sha256-extractor";

import {
  AddIcon,
  AddAttachmentButton,
  Container,
  DescriptionInput,
  DescriptionLabel,
  IdInput,
  IdLabel,
  LabelContainer,
} from "./styled.js";

const AttachmentsAdder = ({ control, watch, setValue }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "details.allegati.indice_allegati",
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watch("details.allegati.indice_allegati")[index],
    };
  });

  useEffect(() => {
    setValue("details.allegati.numero_allegati", controlledFields.length);
  }, [controlledFields, setValue]);

  const handleOnAddClick = () => {
    if (controlledFields.length < 10) {
      setIsModalOpen(true);
    }
  };
  const handleOnDeleteClick = (index) => {
    remove(index);
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
  };
  const handleOnModalConfirm = (hash) => {
    append({
      id_doc: {
        identificativo: "",
        algoritmo: "SHA256",
        impronta: hash,
      },
      descrizione: "",
    });
    setIsModalOpen(false);
  };

  return (
    <div>
      {controlledFields.length > 0 && (
        <LabelContainer>
          <IdLabel>{"Identificativo *"}</IdLabel>
          <DescriptionLabel>{"Descrizione *"}</DescriptionLabel>
        </LabelContainer>
      )}
      {controlledFields.map((item, index) => (
        <Container key={item.id}>
          <Controller
            name={`details.allegati.indice_allegati.${index}.id_doc.identificativo`}
            control={control}
            defaultValue={item.id_doc.identificativo}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <IdInput
                id={`${item.id}_identificativo`}
                error={!!error}
                errorMessage={
                  <ErrorLabel>
                    {"Inserisci un identificativo per l'allegato"}
                  </ErrorLabel>
                }
                placeholder={"Inserisci l'ID dell'allegato"}
                required={true}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Controller
            name={`details.allegati.indice_allegati.${index}.descrizione`}
            control={control}
            defaultValue={item.descrizione}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <DescriptionInput
                id={`${item.id}_descrizione`}
                error={!!error}
                errorMessage={
                  <ErrorLabel>
                    {"Inserisci una descrizione per l'allegato"}
                  </ErrorLabel>
                }
                placeholder={"Inserisci la descrizione dell'allegato"}
                required={true}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Tooltip title={"Elimina"}>
            <Button
              kind={"tertiary"}
              onClick={() => handleOnDeleteClick(index)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </Tooltip>
        </Container>
      ))}
      <AddAttachmentButton
        disabled={fields.length === 10}
        kind={"tertiary"}
        onClick={handleOnAddClick}
      >
        <AddIcon icon={faPlus} />
        {"Aggiungi allegato"}
      </AddAttachmentButton>
      <FileSha256Extractor
        title={"Aggiungi allegato"}
        isVisible={isModalOpen}
        onClose={handleOnModalClose}
        onConfirm={handleOnModalConfirm}
      />
    </div>
  );
};

AttachmentsAdder.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default AttachmentsAdder;
