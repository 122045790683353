import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-right: 64px;
`;

export const Spacing = styled.div`
  margin-bottom: 48px;
`;

export const PrimaryDocument = styled(Input)`
  grid-area: primaryDocument;
`;
