import PropTypes from "prop-types";
import React from "react";
import { useRecoilState } from "recoil";

import usePreview from "../../../../../core/hooks/preview-by-id";
import userAtom from "../../../../../core/atoms/user";

import {
  ErrorLoadingInputPackage,
  InputPackageModal,
  LoadingInputPackage,
  Preview,
} from "./styled";

const PreviewModal = ({ inputPackageIdentifier, holderId, ...rest }) => {
  const [user] = useRecoilState(userAtom);

  const preview = usePreview(user.token, inputPackageIdentifier, holderId);

  if (preview.isError && !preview.isFetching) {
    return (
      <InputPackageModal {...rest} bodyScrollable={false}>
        <ErrorLoadingInputPackage
          message={"Errore nel caricamento dell'anteprima. Riprovare più tardi"}
          onRetry={preview.refetch}
        />
      </InputPackageModal>
    );
  }

  return (
    <InputPackageModal {...rest} bodyScrollable={false}>
      {preview.isFetching ? (
        <LoadingInputPackage data-testid="loading" />
      ) : (
        <Preview
          data-testid="preview"
          title={"Preview"}
          srcDoc={preview.data}
        />
      )}
    </InputPackageModal>
  );
};

PreviewModal.propType = {
  inputPackageIdentifier: PropTypes.string.isRequired,
  holderId: PropTypes.string.isRequired,
};

export default PreviewModal;
