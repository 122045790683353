import styled, { keyframes } from "styled-components";

import FaIcon from "../fa-icon";

export const DownloadIcon = styled(FaIcon)`
  margin-right: 8px;
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
  `;

export const LoadingIcon = styled(FaIcon)`
  margin-right: 8px;
  animation: ${rotate} 2s linear infinite;
`;
