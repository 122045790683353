import axios from "axios";
import { useQuery } from "react-query";
import { v4 } from "uuid";

import config from "../../../config/index";

export const fetchInputPackagePreviewByContent = async ({
  token,
  content,
  typology,
}) => {
  const requestId = v4();

  if (!["1001", "1002", "1005", "1006"].includes(typology)) {
    return;
  }

  const { data } = await axios.post(
    `${config.archiveApiBasePath}/v1/archive/documents/preview?type=asso`,
    { content },
    {
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );
  return data;
};

const usePreview = (token, content, typology) =>
  useQuery(
    ["preview", content],
    () => fetchInputPackagePreviewByContent({ token, content, typology }),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      initialData: "",
    }
  );

export default usePreview;
