import styled from "styled-components";

import ButtonWithIcon from "../button-with-icon";
import FaIcon from "../fa-icon";

export const Icon = styled(FaIcon)`
  font-size: 18px;
`;

export const StyledButtonWithIcon = styled(ButtonWithIcon)`
  & > span > div > div {
    margin-right: 0;
  }
`;
