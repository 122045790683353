import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";

import TypologiesSpecializationsMapOrig from "../../../../../common/typologies-specializations-map.json";
import getTypologiesSpecializationsMap from "../../../../../utils/get-typologies-specializations-map";
import ErrorLabel from "../../../../components/error-label";
import InfoTooltip from "../../../../components/info-tooltip";

import { Container, EmptyContent, GridDatePicker, GridInput } from "./styled";

const TypologiesSpecializationsMap = getTypologiesSpecializationsMap(
  TypologiesSpecializationsMapOrig
);

const Step2 = ({ control, getValues, setValue, unregister }) => {
  const typology = useWatch({ name: "details.tipologia_documentale", control });
  const customMetadatas =
    TypologiesSpecializationsMap[typology].custom_metadata.properties;

  // removes the custom metadata that don't belong
  // to the current selected typology
  useEffect(() => {
    const customMetadataFields = getValues("details.custom_metadata");
    if (customMetadataFields) {
      const filteredCustomMedatada = Object.keys(customMetadataFields).filter(
        (customMetadataKey) =>
          !Object.keys(customMetadatas).includes(customMetadataKey)
      );

      filteredCustomMedatada.forEach((metadataKey) => {
        unregister(`details.custom_metadata.${metadataKey}`);
        setValue(`details.custom_metadata.${metadataKey}`, "");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {Object.keys(customMetadatas).length > 0 ? (
        Object.keys(customMetadatas)
          .sort((a, b) => {
            return (
              customMetadatas[a].layoutSettings.order -
              customMetadatas[b].layoutSettings.order
            );
          })
          .map((customMetadataKey) => {
            const customMetadata =
              TypologiesSpecializationsMap[typology].custom_metadata;

            const isRequired =
              customMetadata.required &&
              TypologiesSpecializationsMap[
                typology
              ].custom_metadata.required.includes(customMetadataKey);

            const pattern = new RegExp(
              customMetadata.properties[customMetadataKey].rules.pattern
            );

            const maxLength =
              customMetadata.properties[customMetadataKey].rules.maxLength;

            const errorMessages =
              customMetadata.properties[customMetadataKey].errors;

            const isDate =
              customMetadata.properties[customMetadataKey].format ===
              "date-time";

            return (
              <Controller
                key={customMetadataKey}
                name={`details.custom_metadata.${customMetadataKey}`}
                control={control}
                defaultValue={isDate ? null : ""}
                rules={{ required: isRequired, pattern, maxLength }}
                render={({ field, fieldState: { error } }) => {
                  const handleOnDateChange = (value) => {
                    field.onChange(value ? value.startOf("day") : value);
                  };

                  const inputProps = {
                    "data-testid": `custom-metadata-input-${customMetadataKey}`,
                    id: `details.custom_metadata.${customMetadataKey}`,
                    gridparameter:
                      customMetadata.properties[customMetadataKey]
                        .layoutSettings.gridParameter,
                    label: customMetadata.properties[customMetadataKey].label,
                    additionalLabel: (
                      <InfoTooltip
                        title={
                          customMetadata.properties[customMetadataKey].label
                        }
                        text={
                          customMetadata.properties[customMetadataKey]
                            .description
                        }
                      />
                    ),
                    placeholder:
                      customMetadata.properties[customMetadataKey].placeholder,
                    required: isRequired,
                    error: !!error,
                    errorMessage: error && (
                      <ErrorLabel>
                        {errorMessages[error.type]
                          ? errorMessages[error.type]
                          : error.type === "required"
                          ? "Campo obbligatorio"
                          : ""}
                      </ErrorLabel>
                    ),
                    onChange: (value) =>
                      isDate
                        ? handleOnDateChange(value)
                        : field.onChange(value),
                    value: field.value,
                  };

                  if (isDate) {
                    return (
                      <GridDatePicker
                        {...inputProps}
                        value={field.value ? moment(field.value) : null}
                      />
                    );
                  }

                  return <GridInput {...inputProps} />;
                }}
              />
            );
          })
      ) : (
        <EmptyContent variant={"bodyRegular"}>
          {"In questa scheda non ci sono campi da compilare."}
        </EmptyContent>
      )}
    </Container>
  );
};

Step2.propTypes = {
  control: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default Step2;
