import { ParentApp } from "@mondora/microfrontends";
import { ThemeProvider } from "@ts-digital/vrc";
import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import "antd/dist/antd.css";
import "rc-select/assets/index.less";

import "./index.css";
import App from "./App";
import { ElevioContextProvider } from "./core/hooks/elevio-context-provider";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();

if (window.location.hostname === "localhost") {
  ReactDOM.render(
    <React.StrictMode>
      <ElevioContextProvider
        openElevioSidebar={() => console.log("OPEN ELEVIO SIDEBAR")}
      >
        <ThemeProvider>
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <App
                inheritedDocumentTypeCode={"1001"}
                token="000147F597710C6522AAD253A9E53904AEA89FD9F653E5DA62330D15000071202C58A127"
              />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </RecoilRoot>
        </ThemeProvider>
      </ElevioContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  new ParentApp({
    onLaunch(launchData) {
      ReactDOM.render(
        <React.StrictMode>
          <ElevioContextProvider
            openElevioSidebar={() => this.call("openElevioSidebar")}
          >
            <ThemeProvider>
              <RecoilRoot>
                <QueryClientProvider client={queryClient}>
                  <App
                    inheritedDocumentTypeCode={launchData.documentType}
                    token={launchData.authToken}
                  />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </RecoilRoot>
            </ThemeProvider>
          </ElevioContextProvider>
        </React.StrictMode>,
        document.getElementById("root")
      );
    },
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
