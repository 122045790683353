import {
  faTimesCircle,
  faCircleNotch,
} from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import React from "react";

import { Icon, Error, Loading } from "./styled";

const IconButton = React.memo(({ icon, isError, isLoading, ...rest }) => {
  if (isError) {
    return <Error {...rest} data-testid={"error"} icon={faTimesCircle} />;
  }

  if (isLoading) {
    return <Loading {...rest} data-testid={"loading"} icon={faCircleNotch} />;
  }

  return <Icon {...rest} data-testid={"icon"} icon={icon} />;
});

IconButton.propTypes = {
  icon: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
};

IconButton.defaultProps = {
  isError: false,
  isLoading: false,
};

export default IconButton;
