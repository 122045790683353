import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useState } from "react";

import ErrorLabel from "../error-label";

import {
  Action,
  AdditionalLabelContainer,
  BaseSelect,
  Container,
  Label,
  SearchIcon,
} from "./styled";

const Select = ({
  label,
  action,
  additionalLabel,
  disabled,
  error,
  errorMessage,
  onChange,
  options,
  placeholder,
  required,
  value,
  className,
}) => {
  const [selectValue, setSelectValue] = useState(value);
  const [showAdditionalLabel, setShowAdditionalLabel] = useState(false);

  // select the active option on mount
  useEffect(
    () => {
      const defaultOption = options.find((option) => option.value === value);
      setSelectValue(defaultOption);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleOnChange = (value) => {
    onChange(value.value);
    setSelectValue(value);
  };

  const handleOnMouseEnter = () => {
    setShowAdditionalLabel(true);
  };
  const handleOnMouseLeave = () => {
    setShowAdditionalLabel(false);
  };

  const components = {
    DropdownIndicator: ({ selectProps }) => {
      return (
        <SearchIcon icon={selectProps.menuIsOpen ? faAngleUp : faAngleDown} />
      );
    },
    IndicatorSeparator: () => <span />,
  };

  return (
    <Container
      className={className}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <label>
        {label && (
          <>
            <Label variant={"bodyRegular"} isDisabled={disabled}>
              {label}
              {required && " *"}
            </Label>
            <AdditionalLabelContainer>
              {showAdditionalLabel && additionalLabel}
            </AdditionalLabelContainer>
          </>
        )}
        <BaseSelect
          placeholder={placeholder}
          error={error}
          classNamePrefix={"vrc-select"}
          options={options}
          components={components}
          onChange={handleOnChange}
          value={selectValue}
          isDisabled={disabled}
        />
      </label>
      {error && <ErrorLabel>{errorMessage}</ErrorLabel>}
      <br />
      {error && <Action>{action}</Action>}
    </Container>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  action: PropTypes.node,
  additionalLabel: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  className: PropTypes.string,
};

Select.defaultProps = { disabled: false };

export default Select;
