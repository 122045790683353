import axios from "axios";
import fileSaver from "file-saver";

import config from "../../../config";
import getFileNameFromContentDisposition from "../../utils/get-file-name-from-content-disposition";
import { useQuery } from "react-query";

const useInputPackageDownload = (token, holderId, id, requestId) =>
  useQuery(
    ["inputut-package-download", requestId],
    async () => {
      const { data, headers } = await axios.get(
        `${config.archiveApiBasePath}/v1/archive/${holderId}/documents/${id}/content`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: token,
            "X-App-Name": config.appName,
            "X-App-Version": config.appVersion,
            "X-Request-ID": requestId,
            "X-Correlation-ID": requestId,
          },
        }
      );

      const blob = new Blob([data], {
        type: "application/octet-stream",
      });
      const fileName = getFileNameFromContentDisposition(
        headers["content-disposition"]
      );
      fileSaver.saveAs(blob, fileName);
    },
    {
      enabled: !!token && !!holderId && !!id && !!requestId,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

export default useInputPackageDownload;
