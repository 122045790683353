import { Button, Input, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../../../../../components/fa-icon";

export const LabelContainer = styled.div`
  display: grid;
  justify-items: start;
  grid-column-gap: 32px;
  grid-template-columns: 2.5fr 4.5fr 1fr;
`;

export const IdLabel = styled(Typography)`
  width: 100%;
`;

export const DescriptionLabel = styled(Typography)`
  width: 100%;
`;

export const Container = styled.div`
  height: 72px;
  display: grid;
  justify-items: start;
  grid-column-gap: 32px;
  grid-template-columns: 2.5fr 4.5fr 1fr;
`;

export const IdInput = styled(Input)`
  width: 100%;
`;

export const DescriptionInput = styled(Input)`
  width: 100%;
`;

export const AddAttachmentButton = styled(Button)`
  height: 53px;
  max-width: 200px;
`;

export const AddIcon = styled(FaIcon)`
  font-size: 16px;
  margin-right: 12px;
`;
