import axios from "axios";
import fileSaver from "file-saver";

import config from "../../../config";
import getFileNameFromContentDisposition from "../../utils/get-file-name-from-content-disposition";
import { useQuery } from "react-query";

export const downloadDistributionPackage = async ({
  token,
  id,
  holderId,
  requestId,
}) => {
  if (!id || !token || !holderId) {
    return;
  }

  const { data, headers } = await axios.get(
    `${config.archiveApiBasePath}/v1/archive/${holderId}/pdd/${id}/content`,
    {
      responseType: "arraybuffer",
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );

  const blob = new Blob([data], {
    type: "application/zip",
  });
  const fileName = getFileNameFromContentDisposition(
    headers["content-disposition"]
  );
  fileSaver.saveAs(blob, fileName);
};

const useDistributionPackageDownload = (token, id, holderId, requestId) =>
  useQuery(
    `distribution-package-zip-${id}`,
    () =>
      downloadDistributionPackage({
        token,
        id,
        holderId,
        requestId,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

export default useDistributionPackageDownload;
