import { Button, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../fa-icon";

export const InfoIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  cursor: pointer;
`;

export const Content = styled.div`
  padding: 12px 10px 6px 10px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

export const Text = styled(Typography)`
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-bottom: 16px;
  margin-top: 10px;
`;

export const CloseButton = styled(Button)`
  align-self: flex-end;
  border-radius: 0;
  height: 32px;
  width: 84px;
  color: white;
  &:hover {
    background-color: transparent;
  }
  & > span {
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
  }
`;
