import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { v4 } from "uuid";

import userAtom from "../../../core/atoms/user";
import inheritedDocumentTypeCodeAtom from "../../../core/atoms/inheritedDocumentTypeCode";
import useDistributionPackageRequest from "../../../core/hooks/distribution-package";
import useHolders from "../../../core/hooks/holders";
import useInputPackages from "../../../core/hooks/input-packages";
import useInputPackagesCSV from "../../../core/hooks/input-packages-csv";
import getReferenceYearRange from "../../../utils/get-reference-year-range";
import Error from "../../components/error";
import Buttons from "./buttons";
import {
  Container,
  DocumentsCount,
  From,
  HolderSelect,
  HoldersLoading,
  InputPackagesList,
  Paper,
  ReferenceYear,
  SearchInput,
  SearchButton,
  StyledTypologySelect,
  To,
} from "./styled.js";

const InputPackagesView = () => {
  const [user] = useRecoilState(userAtom);
  const [inheritedDocumentTypeCode] = useRecoilState(
    inheritedDocumentTypeCodeAtom
  );

  const [holder, setHolder] = useState(null);
  const [documentTypeCode, setDocumentTypeCode] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [searchRequestId, setSearchRequestId] = useState("");
  const [csvExportRequestId, setCSVExportRequestId] = useState("");
  const [distributionPackageRequestId, setDistributionPackageRequestId] =
    useState("");
  const [referenceYear, setReferenceYear] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  // filters of the last search used by csv and preservation package requests
  const [lastHolder, setLastHolder] = useState(null);
  const [lastDocumentTypeCode, setLastDocumentTypeCode] = useState(null);
  const [lastSearchString, setLastSearchString] = useState("");
  const [lastReferenceYear, setLastReferenceYear] = useState("");
  const [lastFrom, setLastFrom] = useState(null);
  const [lastTo, setLastTo] = useState(null);

  const holders = useHolders(user.token);

  const inputPackages = useInputPackages(
    user.token,
    holder && holder.uuid,
    documentTypeCode,
    searchString,
    referenceYear,
    from,
    to,
    searchRequestId
  );

  const inputPackagesCSV = useInputPackagesCSV(
    user.token,
    lastHolder && lastHolder.uuid,
    lastDocumentTypeCode,
    lastSearchString,
    lastReferenceYear,
    lastFrom,
    lastTo,
    csvExportRequestId
  );

  const submitDistributionPackageRequest = useDistributionPackageRequest(
    user.token,
    lastHolder && lastHolder.uuid,
    lastHolder && lastHolder.countryCode,
    lastDocumentTypeCode,
    lastSearchString,
    lastReferenceYear,
    lastFrom,
    lastTo,
    distributionPackageRequestId
  );

  useEffect(() => {
    submitDistributionPackageRequest.reset();

    setLastHolder(holder);
    setLastDocumentTypeCode(documentTypeCode);
    setLastReferenceYear(referenceYear);
    setLastSearchString(searchString);
    setLastFrom(from);
    setLastTo(to);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequestId]);

  useEffect(() => {
    inputPackagesCSV.refetch();
    submitDistributionPackageRequest.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvExportRequestId]);

  useEffect(() => {
    submitDistributionPackageRequest.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionPackageRequestId]);

  useEffect(() => {
    setDocumentTypeCode(inheritedDocumentTypeCode);
  }, [inheritedDocumentTypeCode]);

  if (holders.isIdle || holders.isFetching) {
    return <HoldersLoading />;
  }

  if (holders.isError) {
    return (
      <Error
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
        onRetry={holders.refetch}
      />
    );
  }

  return (
    <Container>
      <Paper>
        <HolderSelect
          label="Titolare"
          error={
            !!(
              (!holder && searchRequestId) ||
              (holder &&
                holder.uuid.length === 0 &&
                (searchRequestId ||
                  csvExportRequestId ||
                  distributionPackageRequestId))
            )
          }
          errorMessage="Seleziona un titolare"
          onChange={setHolder}
          options={
            holders.data &&
            holders.data.map((holder) => ({
              value: holder,
              label: `${holder.vatNumber} ${holder.description}`,
            }))
          }
          placeholder="Seleziona un titolare cercando per nome o partita IVA"
          required={true}
          value={holder}
        />
        <StyledTypologySelect
          id={"details.tipologia_documentale"}
          error={
            !!(
              !documentTypeCode &&
              (searchRequestId ||
                csvExportRequestId ||
                distributionPackageRequestId)
            )
          }
          required={true}
          onChange={setDocumentTypeCode}
          value={documentTypeCode}
        />
        <ReferenceYear
          label="Anno di riferimento"
          error={
            !!(
              !referenceYear &&
              (searchRequestId ||
                csvExportRequestId ||
                distributionPackageRequestId)
            )
          }
          errorMessage={"Seleziona un anno di riferimento"}
          onChange={setReferenceYear}
          options={getReferenceYearRange().map((year) => ({
            label: year.toString(),
            value: year.toString(),
          }))}
          placeholder="Seleziona un anno di riferimento"
          required={true}
          value={referenceYear}
        />
        <DocumentsCount count={get(inputPackages, "data.pages[0].total")} />
        <From
          label="Da"
          placeholder={"Seleziona una data"}
          value={from}
          onChange={setFrom}
        />
        <To
          label="A"
          placeholder={"Seleziona una data"}
          value={to}
          onChange={setTo}
        />
        <SearchInput
          label="Cerca per"
          value={searchString}
          onChange={(searchString) => setSearchString(searchString)}
          placeholder="Cerca per"
        />
        <SearchButton
          kind={"primary"}
          onClick={() => {
            setSearchRequestId(v4());
          }}
        >
          {"cerca"}
        </SearchButton>
        <Buttons
          distributionPackageCorrelationId={distributionPackageRequestId}
          csvExportCorrelationId={csvExportRequestId}
          distributionPackageQuery={submitDistributionPackageRequest}
          inputPackagesQuery={inputPackagesCSV}
          inputPackagesCount={get(inputPackages, "data.pages[0].total", 0)}
          isEnabled={
            !!lastHolder && !!lastReferenceYear && !!lastDocumentTypeCode
          }
          onCSVRequest={() => setCSVExportRequestId(v4())}
          onDistributionPackageRequest={() =>
            setDistributionPackageRequestId(v4())
          }
        />
        <InputPackagesList
          inputPackages={inputPackages.data}
          fetchNextPage={inputPackages.fetchNextPage}
          hasNextPage={inputPackages.hasNextPage}
          isHolderEnabled={lastHolder && lastHolder.isEnabled}
          isFetching={inputPackages.isFetching}
          isFetchingNextPage={inputPackages.isFetchingNextPage}
          isError={inputPackages.isError}
        />
      </Paper>
    </Container>
  );
};

export default InputPackagesView;
