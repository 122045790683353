import PropTypes from "prop-types";
import React, { useState } from "react";

import ErrorLabel from "../error-label";

import { Container, InfoTooltip, Label, SpacedToggle } from "./styled.js";

const LabeledToggle = ({
  infoTooltipTitle,
  infoTooltipText,
  label,
  disabled,
  checked,
  onChange,
  error,
  errorMessage,
  className,
}) => {
  const [isInfoTooltipVisible, setInfoTooltipVisible] = useState(false);

  return (
    <Container
      className={className}
      onMouseEnter={() => setInfoTooltipVisible(true)}
      onMouseLeave={() => setInfoTooltipVisible(false)}
    >
      <SpacedToggle disabled={disabled} checked={checked} onChange={onChange} />
      <Label variant={"bodyRegular"}>{label}</Label>
      {isInfoTooltipVisible && (
        <InfoTooltip title={infoTooltipTitle} text={infoTooltipText} />
      )}
      {error && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Container>
  );
};

LabeledToggle.propTypes = {
  infoTooltipTitle: PropTypes.string,
  infoTooltipText: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
};

export default LabeledToggle;
