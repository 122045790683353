import axios from "axios";

import config from "../../../config";
import { useQuery } from "react-query";

const useArticle52Html = (token, holderId, htmlExportRequestId) =>
  useQuery(
    ["article-52-html", holderId],
    async () => {
      const { data } = await axios.get(
        `${config.archiveApiBasePath}/v1/archive/${holderId}/certificate/art52/html`,
        {
          headers: {
            Authorization: token,
            "X-App-Name": config.appName,
            "X-App-Version": config.appVersion,
            "X-Request-ID": htmlExportRequestId,
            "X-Correlation-ID": htmlExportRequestId,
          },
        }
      );

      return data;
    },
    {
      enabled: !!token && !!holderId,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

export default useArticle52Html;
