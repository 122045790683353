import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import ErrorLabel from "../../../../components/error-label";
import InfoTooltip from "../../../../components/info-tooltip";

import PreviousDocumentAdder from "./previous-document-adder";

import {
  ChangeDatePicker,
  ChangeTypeSelect,
  Container,
  GridContainer,
  NotesInput,
  VersionInput,
} from "./styled.js";

const changeTypesMapping = [
  {
    value: "NESSUNO",
    label: "Nessuna",
  },
  {
    value: "ANNULLAMENTO",
    label: "Annullamento",
  },
  {
    value: "RETTIFICA",
    label: "Rettifica",
  },
  {
    value: "INTEGRAZIONE",
    label: "Integrazione",
  },
  {
    value: "ANNOTAZIONE",
    label: "Annotazione",
  },
];

const Step6 = ({ control, watch, setValue, register, unregister }) => {
  const watchChangesTracking = useWatch({
    control,
    name: "details.tracciature_modifiche",
  });

  // remove the empty object from the form state
  // when the change tracking gets removed
  useEffect(() => {
    if (watchChangesTracking && watchChangesTracking.tipo === "NESSUNO") {
      unregister("details.tracciature_modifiche");
    }
  }, [unregister, watchChangesTracking]);

  const handleOnTypeChange = (value, onChange) => {
    onChange(value);

    if (value === "NESSUNO") {
      setValue("details.tracciature_modifiche.data", "");
      setValue(
        "details.tracciature_modifiche.id_versione_precedente.impronta",
        ""
      );
      setValue(
        "details.tracciature_modifiche.id_versione_precedente.identificativo",
        ""
      );
      setValue(
        "details.tracciature_modifiche.id_versione_precedente.algoritmo",
        ""
      );
    }
  };

  return (
    <Container>
      <GridContainer>
        <Controller
          name={"details.versione"}
          control={control}
          defaultValue={"1"}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <VersionInput
              id={"details.versione"}
              label={"Versione del documento"}
              placeholder={"Imposta la versione del documento"}
              required={true}
              error={!!error}
              errorMessage={
                <ErrorLabel>{"Imposta una versione del documento"}</ErrorLabel>
              }
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        {watchChangesTracking && watchChangesTracking.tipo !== "NESSUNO" && (
          <PreviousDocumentAdder
            {...{ control, watch, setValue, register, unregister }}
          />
        )}
        <Controller
          name={"details.tracciature_modifiche.tipo"}
          control={control}
          defaultValue={"NESSUNO"}
          render={({ field, fieldState: { error } }) => (
            <ChangeTypeSelect
              id={"details.tracciature_modifiche.tipo"}
              label={"Tipo di modifica"}
              additionalLabel={
                <InfoTooltip
                  title={"Tipo di modifica"}
                  text={
                    "Si tratta delle modifiche apportate al documento nel sistema documentale o gestionale di provenienza e quindi avvenute prima dell’invio in conservazione; la selezione di questo metadato non ha alcun impatto sulla conservazione. Per esempio, selezionare “Annullamento” non implica annullare la conservazione di un documento; per esempio, selezionare “Rettifica” non implica la rettifica di una conservazione già avvenuta."
                  }
                />
              }
              options={changeTypesMapping.map(({ value, label }) => ({
                value,
                label,
              }))}
              error={!!error}
              errorMessage={
                "Una versione del documento superiore a 1 richiede la selezione del tipo di modifica"
              }
              placeholder={"Seleziona un tipo di modifica"}
              onChange={(value) => handleOnTypeChange(value, field.onChange)}
              value={field.value}
            />
          )}
        />
        {watchChangesTracking && watchChangesTracking.tipo !== "NESSUNO" && (
          <Controller
            name={"details.tracciature_modifiche.data"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <ChangeDatePicker
                showTime={true}
                error={!!error}
                required={true}
                errorMessage={"Seleziona una data"}
                label={"Data e ora modifica"}
                placeholder={"Seleziona una data"}
                onChange={field.onChange}
                value={field.value ? moment(field.value) : null}
              />
            )}
          />
        )}
        <Controller
          name={"details.note"}
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <NotesInput
              id={"details.note"}
              label={"Note"}
              placeholder={"Inserisci eventuali informazioni aggiuntive"}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
      </GridContainer>
    </Container>
  );
};

Step6.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default Step6;
