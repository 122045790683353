import styled, { keyframes } from "styled-components";

import FaIcon from "../../fa-icon";

export const Content = styled.div`
  max-width: 820px;
  height: 100%;
`;

export const Iframe = styled.iframe`
  height: 100%;
  border: none;
  width: 788px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  max-width: 850px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const SendingIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  font-size: 32px;
  animation: ${rotate} 2s linear infinite;
`;
