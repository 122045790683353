import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { v4 } from "uuid";

import Loading from "../../components/loading";
import userAtom from "../../../core/atoms/user";
import useRectifyDocument from "../../../core/hooks/rectify-document";
import useInputPackage from "../../../core/hooks/input-package";
import UploadDocument from "../upload-document";
import RectifyModal from "./rectify-modal";

const useQuery = () => new URLSearchParams(useLocation().search);

const RectifyInputPackage = () => {
  const [user] = useRecoilState(userAtom);
  const queryStringParameters = useQuery();
  const history = useHistory();

  const [formState, setFormState] = useState(null);
  const [changeReason, setChangeReason] = useState("");
  const [isRectifyModalVisible, setIsRectifyModalVisible] = useState(false);
  const [rectificationRequestId, setRectificationRequestId] = useState("");

  const rectifiedDocumentRequest = useRectifyDocument(
    user.token,
    queryStringParameters.get("rectifiedInputPackageId"),
    {
      ...formState,
      rectificationReason: changeReason ? changeReason : undefined,
    },
    rectificationRequestId
  );

  useEffect(() => {
    rectifiedDocumentRequest.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rectificationRequestId]);

  const handleRectification = useCallback((newFormState) => {
    setFormState(newFormState);
    setIsRectifyModalVisible(true);
  }, []);

  const inputPackage = useInputPackage(
    user.token,
    queryStringParameters.get("holderId"),
    queryStringParameters.get("rectifiedInputPackageId")
  );

  const defaultValues = useMemo(
    () => ({
      ...inputPackage.data,
      details: {
        id_agg: [{ tipo_aggregazione: "NESSUNO", id_aggregazione: "" }],
        tracciature_modifiche: { tipo: "NESSUNO" },
        ...inputPackage.data?.details,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputPackage?.data?.holder?.taxId]
  );

  const handleRectifyModalClose = () => {
    setIsRectifyModalVisible(false);
    rectifiedDocumentRequest.reset();
  };

  const handleRectifyModalConfirm = (changeReason) => {
    setRectificationRequestId(v4());
    setChangeReason(changeReason);
  };

  const handleGoToSearchPage = () => {
    handleRectifyModalClose();
    history.push("/input-packages");
  };

  if (inputPackage.isIdle || inputPackage.isFetching) {
    return <Loading />;
  }

  return (
    <>
      {isRectifyModalVisible && (
        <RectifyModal
          correlationId={rectificationRequestId}
          isError={rectifiedDocumentRequest.isError}
          isSending={rectifiedDocumentRequest.isLoading}
          isSuccess={
            rectifiedDocumentRequest.isSuccess &&
            !!rectifiedDocumentRequest.data
          }
          onClose={handleRectifyModalClose}
          onConfirm={handleRectifyModalConfirm}
          onGoBackToSearch={handleGoToSearchPage}
          visible={isRectifyModalVisible}
        />
      )}
      <UploadDocument
        handleRectification={handleRectification}
        isRectification={true}
        defaultValues={defaultValues}
      />
    </>
  );
};

export default RectifyInputPackage;
