import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

import ErrorLabel from "../../../../../../../../components/error-label";

import { Container, EmailsInput, GridInput } from "./styled";

const PaeInputs = ({ control, index, item, unregister }) => {
  useEffect(() => {
    return function cleanup() {
      unregister(
        [
          `details.soggetti.${index}.denominazione`,
          `details.soggetti.${index}.denominazione_ufficio`,
          `details.soggetti.${index}.indirizzi_digitali_riferimento`,
        ],
        { keepValue: false }
      );
    };
  }, [index, unregister]);

  return (
    <Container>
      <Controller
        name={`details.soggetti.${index}.denominazione`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.denominazione || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"name"}
            id={`details.soggetti.${index}.denominazione`}
            label={"Amministrazione"}
            placeholder={"Inserisci la denominazione dell’amministrazione"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {"Inserisci la denominazione dell’amministrazione"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.denominazione_ufficio`}
        control={control}
        defaultValue={item.denominazione_ufficio || ""}
        render={({ field }) => (
          <GridInput
            area={"office"}
            id={`details.soggetti.${index}.denominazione_ufficio`}
            label={"Ufficio"}
            placeholder={"Inserisci la denominazione dell’ufficio"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
        control={control}
        defaultValue={item.indirizzi_digitali_riferimento || []}
        render={({ field }) => (
          <EmailsInput
            id={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
            label={"Indirizzi digitali di riferimento"}
            placeholder={"Inserisci un indirizzo e-mail"}
            maximum={5}
            onChange={field.onChange}
            values={field.value}
          />
        )}
      />
    </Container>
  );
};

PaeInputs.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default PaeInputs;
