import { Input, Button, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import Tag from "../tag";

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    "label label"
    "input button"
    "keywords keywords";
  grid-template-columns: minmax(200px, 7fr) minmax(80px, 1fr);
  grid-template-rows: 24px 72px 72px;
  grid-column-gap: 32px;
`;

export const Label = styled(Typography)`
  grid-area: label;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.cadet : `inerith`};
  opacity: ${({ disabled }) => (disabled ? "0.64" : "1")};
`;

export const StyledInput = styled(Input)`
  grid-area: input;
`;

export const AddButton = styled(Button)`
  grid-area: button;
`;

export const Keywords = styled.div`
  grid-area: keywords;
`;

export const Keyword = styled(Tag)`
  margin-right: 8px;
`;

export const AdditionalLabel = styled.div`
  display: inline-block;
  margin-left: 8px;
`;
