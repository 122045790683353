import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { ActionButton, ActionIcon, Option, OptionText } from "./styled";

const TooltipSelect = ({ className, label, icon, onClick, options }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleOnAddClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleOptionClick = (value) => {
    onClick(value);
    setIsTooltipOpen(false);
  };

  return (
    <Tooltip
      className={className}
      visible={isTooltipOpen}
      color={"white"}
      overlayInnerStyle={{ width: "348px", padding: 0 }}
      title={
        <div data-testid={"tooltip-select"}>
          {options.map((option) => (
            <Option
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
            >
              <OptionText variant={"bodyRegular"}>
                {option.description}
              </OptionText>
            </Option>
          ))}
        </div>
      }
    >
      <ActionButton kind={"tertiary"} onClick={handleOnAddClick}>
        <ActionIcon icon={icon} />
        {label}
      </ActionButton>
    </Tooltip>
  );
};

TooltipSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, description: PropTypes.string })
  ),
};

TooltipSelect.defaultProps = {
  options: [],
};

export default TooltipSelect;
