import PropTypes from "prop-types";
import React from "react";
import { useWatch } from "react-hook-form";

import { AggregationTypeLabel, Container, IdLabel } from "./styled";

const Labels = ({ control }) => {
  const watchAggregationType = useWatch({
    control,
    name: "details.id_agg.0.tipo_aggregazione",
  });

  return (
    <Container>
      <AggregationTypeLabel>{"Tipo aggregazione"}</AggregationTypeLabel>
      {watchAggregationType && watchAggregationType !== "NESSUNO" && (
        <IdLabel>{"Identificativo *"}</IdLabel>
      )}
    </Container>
  );
};

Labels.propTypes = {
  control: PropTypes.object.isRequired,
};

export default Labels;
