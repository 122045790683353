import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

import Select from "../../../../../../../components/select";

export const Container = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-template-areas:
    "country ."
    "inputs inputs";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 88px auto;
`;

export const GridSelect = styled(Select)`
  grid-area: ${({ area }) => area};
`;

export const GridInput = styled(Input)`
  grid-area: ${({ area }) => area};
`;

export const InputsContainer = styled.div`
  grid-area: inputs;
`;
