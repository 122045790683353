import PropTypes from "prop-types";
import React from "react";

import { Container, LoadingText, RetryButton } from "./styled";

const Error = ({ message, onRetry, ...rest }) => (
  <Container {...rest}>
    <LoadingText variant="subtitleRegular">{message}</LoadingText>
    {onRetry && (
      <RetryButton
        data-testid={"retry-button"}
        kind={"secondary"}
        colorScheme={"alert"}
        onClick={onRetry}
      >
        {"riprova"}
      </RetryButton>
    )}
  </Container>
);

Error.propTypes = {
  message: PropTypes.string,
  onRetry: PropTypes.func,
};

export default Error;
