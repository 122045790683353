import { faEye, faFolder } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { v4 } from "uuid";

import PreviewModal from "../preview-modal";
import RegistryWarningModal from "../registry-warning-modal";
import userAtom from "../../../../../core/atoms/user";
import DropdownMenu from "../../../../components/dropdown-menu";
import typologiesSpecializationsMapOriginal from "../../../../../common/typologies-specializations-map.json";
import FileDownloadButton from "../../../../components/file-download-button";
import useDepositReportDownload from "../../../../../core/hooks/deposit-report-download";
import { useElevioContext } from "../../../../../core/hooks/elevio-context-provider";
import useInputPackageDownload from "../../../../../core/hooks/input-package-download";
import usePreservationPackageIndexDownload from "../../../../../core/hooks/preservation-package-index-download";
import useProofOfPreservationPdf from "../../../../../core/hooks/proof-of-preservation";
import getTypologiesSpecializationsMap from "../../../../../utils/get-typologies-specializations-map";

import {
  ActionButtons,
  AccordionIconContainer,
  Container,
  DocumentDate,
  Header,
  Label,
  Metadata,
  MetadataContainer,
  PreviewIcon,
  Value,
  Title,
  Tag,
  TagsContainer,
} from "./styled.js";

const typologiesSpecializationsMap = getTypologiesSpecializationsMap(
  typologiesSpecializationsMapOriginal
);

const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3,7}Z{1}$/;

const InputPackagePanel = React.memo(
  ({
    identifier,
    depositPackageIdentifier,
    metadata,
    documentTypeCode,
    documentStatus,
    errorMessage,
    documentDate,
    holderId,
    rectifiedInputPackageId,
    rectificationReason,
    rectifierInputPackageId,
    isHolderEnabled,
    ...rest
  }) => {
    const elevioContext = useElevioContext();
    const history = useHistory();

    const [user] = useRecoilState(userAtom);

    const [isPreviewModal, setIsPreviewModal] = useState(false);
    const [isRegistryWarningModalOpen, setIsRegistryWarningModalOpen] =
      useState(false);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [depositReportDownloadRequestId, setDepositReportDownloadRequestId] =
      useState("");
    const [
      preservationPackageIndexDownloadRequestId,
      setPreservationPackageIndexDownloadRequestId,
    ] = useState("");
    const [
      proofOfPreservationDownloadRequestId,
      setProofOfPreservationDownloadRequestId,
    ] = useState("");
    const [inputPackageDownloadRequestId, setInputPackageDownloadRequestId] =
      useState("");

    const depositReportDownload = useDepositReportDownload(
      user.token,
      holderId,
      identifier,
      depositReportDownloadRequestId
    );
    const preservationPackageIndexDownload =
      usePreservationPackageIndexDownload(
        user.token,
        holderId,
        identifier,
        preservationPackageIndexDownloadRequestId
      );
    const proofOfPreservationDownload = useProofOfPreservationPdf(
      user.token,
      holderId,
      identifier,
      proofOfPreservationDownloadRequestId
    );
    const inputPackageDownload = useInputPackageDownload(
      user.token,
      holderId,
      identifier,
      inputPackageDownloadRequestId
    );

    const metadataWithDepositPackageIdentifier = {
      ...metadata,
      depositPackageIdentifier: {
        label: "Identificativo pacchetto di versamento",
        value: depositPackageIdentifier,
      },
    };

    const rectifyDocument = useCallback(() => {
      history.push(
        `/rectify-input-package?holderId=${holderId}&rectifiedInputPackageId=${identifier}`
      );
    }, [history, holderId, identifier]);

    const showIncompleteRegistryModal = () => {
      setIsRegistryWarningModalOpen(true);
    };

    const hideIncompleteRegistryModal = () => {
      setIsRegistryWarningModalOpen(false);
    };

    const openElevioSidebar = () => {
      elevioContext.openElevioSidebar();
      hideIncompleteRegistryModal();
    };

    return (
      <Container isPanelOpen={isPanelOpen} {...rest}>
        {isRegistryWarningModalOpen && (
          <RegistryWarningModal
            isVisible={isRegistryWarningModalOpen}
            onClose={hideIncompleteRegistryModal}
            onConfirm={openElevioSidebar}
          />
        )}
        {isPreviewModal && (
          <PreviewModal
            data-testid={"preview-modal"}
            title={"Anteprima documento"}
            inputPackageIdentifier={identifier}
            holderId={holderId}
            visible={isPreviewModal}
            onClose={() => setIsPreviewModal(false)}
          />
        )}
        <Header>
          <Title data-testid={"title"} variant="h4">
            {`${typologiesSpecializationsMap[documentTypeCode].description}${
              metadata.numero_fattura
                ? ` nr ${metadata.numero_fattura.value}`
                : ""
            }`}
          </Title>
          <TagsContainer>
            {documentStatus === "PRESERVED" ? (
              <Tag color={"IslamicGreen"}>{"conservato"}</Tag>
            ) : errorMessage ? (
              <Tooltip
                title={`${errorMessage
                  .charAt(0)
                  .toUpperCase()}${errorMessage.slice(1)}`}
              >
                <Tag color={"RustyRed"}>{"Rifiutato"}</Tag>
              </Tooltip>
            ) : (
              <Tag color={"RustyRed"}>{"Rifiutato"}</Tag>
            )}
            {rectifiedInputPackageId &&
            rectifiedInputPackageId !== "0" &&
            rectificationReason ? (
              <Tooltip
                title={`${rectificationReason
                  .charAt(0)
                  .toUpperCase()}${rectificationReason.slice(1)}`}
              >
                <Tag color={"RoyalFuchsia"}>{"Rettificante"}</Tag>
              </Tooltip>
            ) : rectifierInputPackageId && rectifierInputPackageId !== "0" ? (
              <Tag color={"RoyalFuchsia"}>{"Rettificato"}</Tag>
            ) : null}
          </TagsContainer>
          <DocumentDate variant="bodyRegular">
            {documentDate ? new Date(documentDate).toLocaleDateString() : null}
          </DocumentDate>
          {isPanelOpen ? (
            <AccordionIconContainer
              data-testid={"close-accordion-icon"}
              icon={faTimes}
              onClick={() => setIsPanelOpen(false)}
            />
          ) : (
            <AccordionIconContainer
              data-testid={"open-accordion-icon"}
              icon={faFolder}
              onClick={() => setIsPanelOpen(true)}
            />
          )}
        </Header>
        {isPanelOpen && (
          <>
            <MetadataContainer>
              {Object.keys(metadataWithDepositPackageIdentifier)
                .filter(
                  (key) => !!metadataWithDepositPackageIdentifier[key].value
                )
                .map((key) => (
                  <Metadata key={key}>
                    <Label data-testid={"label"} variant="descriptive">
                      {metadataWithDepositPackageIdentifier[key].label}
                    </Label>
                    <Value data-testid={"value"} variant="descriptive">
                      {metadataWithDepositPackageIdentifier[key].type ===
                        "date" ||
                      dateRegex.exec(
                        metadataWithDepositPackageIdentifier[key].value
                      )
                        ? new Date(
                            metadataWithDepositPackageIdentifier[key].value
                          ).toLocaleDateString()
                        : metadataWithDepositPackageIdentifier[key].value}
                    </Value>
                  </Metadata>
                ))}
            </MetadataContainer>
            <ActionButtons>
              {documentStatus === "PRESERVED" &&
              !rectifiedInputPackageId &&
              !rectifierInputPackageId ? (
                <Button
                  data-testid={"rectify"}
                  kind={"tertiary"}
                  onClick={
                    isHolderEnabled
                      ? rectifyDocument
                      : showIncompleteRegistryModal
                  }
                >
                  {"Rettifica"}
                </Button>
              ) : null}
              {["1001", "1002", "1004", "1006"].includes(documentTypeCode) && (
                <Button
                  data-testid={"preview-icon"}
                  kind={"tertiary"}
                  onClick={() => setIsPreviewModal(true)}
                >
                  <PreviewIcon icon={faEye} />
                  {"Anteprima"}
                </Button>
              )}
              <DropdownMenu
                items={[
                  <FileDownloadButton
                    isFetching={depositReportDownload.isFetching}
                    isError={depositReportDownload.isError}
                    onClick={() => setDepositReportDownloadRequestId(v4())}
                    text={"Rapporto di versamento"}
                  />,
                  ...(documentStatus === "PRESERVED"
                    ? [
                        <FileDownloadButton
                          isFetching={
                            preservationPackageIndexDownload.isFetching
                          }
                          isError={preservationPackageIndexDownload.isError}
                          onClick={() =>
                            setPreservationPackageIndexDownloadRequestId(v4())
                          }
                          text={"Indice del pacchetto di archiviazione"}
                        />,
                        <FileDownloadButton
                          isFetching={proofOfPreservationDownload.isFetching}
                          isError={proofOfPreservationDownload.isError}
                          onClick={() =>
                            setProofOfPreservationDownloadRequestId(v4())
                          }
                          text={"Attestato corretta conservazione"}
                        />,
                      ]
                    : []),
                  <FileDownloadButton
                    isFetching={inputPackageDownload.isFetching}
                    isError={inputPackageDownload.isError}
                    onClick={() => {
                      setInputPackageDownloadRequestId(v4());
                    }}
                    text={"Documento versato"}
                  />,
                ]}
              />
            </ActionButtons>
          </>
        )}
      </Container>
    );
  }
);

InputPackagePanel.propTypes = {
  identifier: PropTypes.string.isRequired,
  depositPackageIdentifier: PropTypes.string,
  metadata: PropTypes.object,
  referenceYear: PropTypes.string,
  documentTypeCode: PropTypes.string,
  documentStatus: PropTypes.string,
  errorMessage: PropTypes.string,
  documentDate: PropTypes.number,
  holderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rectifiedInputPackageId: PropTypes.string,
  rectificationReason: PropTypes.string,
  rectifierInputPackageId: PropTypes.string,
  isHolderEnabled: PropTypes.bool,
};

export default InputPackagePanel;
