import { Button } from "@ts-digital/vrc";
import React from "react";
import PropTypes from "prop-types";

import { Container, Icon } from "./styled";

const ButtonWithIcon = React.forwardRef(({ icon, children, ...rest }, _) => (
  <Button {...rest}>
    <Container>
      <Icon>{icon}</Icon>
      {children}
    </Container>
  </Button>
));

ButtonWithIcon.propTypes = {
  icon: PropTypes.node,
};

export default ButtonWithIcon;
