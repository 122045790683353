import { Modal } from "@ts-digital/vrc";
import styled from "styled-components";

import Error from "../../../../components/error";
import Loading from "../../../../components/loading";

export const InputPackageModal = styled(Modal)``;

export const Preview = styled.iframe`
  width: 60vw;
  height: 80vh;
  border: none;
`;

export const LoadingInputPackage = styled(Loading)`
  width: 80vw;
  height: 80vh;
  border: none;
`;

export const ErrorLoadingInputPackage = styled(Error)`
  width: 80vw;
  height: 80vh;
  border: none;
`;
