import { faFolderDownload } from "@fortawesome/pro-regular-svg-icons";
import { Button } from "@ts-digital/vrc";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import config from "../../../../config";
import FileDownloadButton from "../../../components/file-download-button";
import DistributionPackageRequestModal from "../distribution-package-request-modal";
import { DownloadButtons, IconButton } from "./styled";

const Buttons = ({
  csvExportCorrelationId,
  distributionPackageCorrelationId,
  distributionPackageQuery,
  inputPackagesQuery,
  inputPackagesCount,
  onCSVRequest,
  onDistributionPackageRequest,
  isEnabled,
}) => {
  const [isDistributionPackageModalOpen, setDistributionPackageModalOpen] =
    useState(false);

  return (
    <>
      <DownloadButtons>
        <FileDownloadButton
          correlationId={csvExportCorrelationId}
          isDisabled={!isEnabled || inputPackagesCount === 0}
          isFetching={inputPackagesQuery.isFetching}
          isError={inputPackagesQuery.isError}
          onClick={onCSVRequest}
          text={"Scarica CSV"}
          tooltipTextIfDisabled={"Effettua una ricerca con almeno un risultato"}
        />
        <Tooltip
          title={
            inputPackagesCount > parseInt(config.maxDistributionPackageSize)
              ? `Puoi richiedere un pacchetto di distribuzione con al massimo ${config.maxDistributionPackageSize} file, affina i filtri di ricerca`
              : inputPackagesCount === 0
              ? "Effettua una ricerca con almeno un risultato"
              : "Richiedi il pacchetto di distribuzione"
          }
        >
          <div>
            <Button
              disabled={
                !isEnabled ||
                inputPackagesCount >
                  parseInt(config.maxDistributionPackageSize) ||
                inputPackagesCount === 0
              }
              kind={"tertiary"}
              onClick={() => setDistributionPackageModalOpen(true)}
            >
              <IconButton
                icon={faFolderDownload}
                isError={
                  distributionPackageQuery.isError &&
                  !distributionPackageQuery.isFetching
                }
                isLoading={distributionPackageQuery.isFetching}
              />
              {"Richiedi il pacchetto di distribuzione"}
            </Button>
          </div>
        </Tooltip>
      </DownloadButtons>
      <DistributionPackageRequestModal
        correlationId={distributionPackageCorrelationId}
        isError={distributionPackageQuery.isError}
        isSending={distributionPackageQuery.isLoading}
        isSuccess={
          !!(
            distributionPackageQuery.isSuccess && distributionPackageQuery.data
          )
        }
        onClose={() => setDistributionPackageModalOpen(false)}
        onConfirm={onDistributionPackageRequest}
        visible={isDistributionPackageModalOpen}
      />
    </>
  );
};

Buttons.propTypes = {
  csvExportCorrelationId: PropTypes.string,
  distributionPackageCorrelationId: PropTypes.string,
  distributionPackageQuery: PropTypes.object,
  inputPackagesCount: PropTypes.number,
  inputPackagesQuery: PropTypes.object,
  isEnabled: PropTypes.bool,
  onCSVRequest: PropTypes.func.isRequired,
  onDistributionPackageRequest: PropTypes.func.isRequired,
};

export default Buttons;
