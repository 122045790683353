import { Button, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../fa-icon";

export const Option = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  padding: 8px 16px;
  &:hover {
    border: ${(props) =>
      `1px solid ${props.theme.colors.primary.richElectricBlue}`};
  }
`;

export const OptionText = styled(Typography)`
  color: black;
  font-weight: normal;
`;

export const ActionButton = styled(Button)`
  height: 53px;
  max-width: 200px;
`;

export const ActionIcon = styled(FaIcon)`
  font-size: 16px;
  margin-right: 12px;
`;
