import { TypographyVariants } from "@ts-digital/vrc";
import PropTypes from "prop-types";
import React from "react";

import { Bold } from "./styled";

const BoldText = ({ children, variant }) => (
  <Bold variant={variant}>{children}</Bold>
);

BoldText.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(TypographyVariants)),
};

export default BoldText;
