import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ts-digital/vrc";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import ErrorLabel from "../../../../../components/error-label";

import FileSha256Extractor from "../../../../../components/file-sha256-extractor";
import Label from "./label";

import {
  AddIcon,
  AddPreviousDocumentButton,
  AddPreviousDocumentGrid,
  IdInput,
  PreviousDocumentContainer,
} from "./styled.js";

const PreviousDocumentAdder = ({
  control,
  watch,
  setValue,
  register,
  unregister,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const watchHashValue = watch(
    "details.tracciature_modifiche.id_versione_precedente.impronta"
  );
  const watchChangeType = watch("details.tracciature_modifiche.tipo");

  useEffect(() => {
    register(
      "details.tracciature_modifiche.id_versione_precedente.identificativo",
      { required: true, shouldUnregister: true }
    );
  }, [register, unregister, watchChangeType]);

  // remove the empty object from the form state
  // when the input gets deleted
  useEffect(() => {
    if (watchChangeType === "NESSUNO") {
      unregister(
        "details.tracciature_modifiche.id_versione_precedente.identificativo"
      );
    }
  }, [unregister, watchChangeType]);

  const handleOnAddClick = () => {
    setIsModalOpen(true);
  };
  const handleOnDeleteClick = () => {
    setValue(
      "details.tracciature_modifiche.id_versione_precedente.impronta",
      ""
    );
    setValue(
      "details.tracciature_modifiche.id_versione_precedente.identificativo",
      ""
    );
    setValue(
      "details.tracciature_modifiche.id_versione_precedente.algoritmo",
      ""
    );
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
  };
  const handleOnModalConfirm = (hash) => {
    setValue(
      "details.tracciature_modifiche.id_versione_precedente.impronta",
      hash
    );
    setValue(
      "details.tracciature_modifiche.id_versione_precedente.algoritmo",
      "SHA256"
    );
    setIsModalOpen(false);
  };

  return (
    <div>
      <Label control={control} />
      {watchChangeType !== "NESSUNO" && (
        <PreviousDocumentContainer>
          <Controller
            name={
              "details.tracciature_modifiche.id_versione_precedente.identificativo"
            }
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <>
                {!watchHashValue ? (
                  <AddPreviousDocumentGrid>
                    <AddPreviousDocumentButton
                      disabled={false}
                      kind={"tertiary"}
                      onClick={handleOnAddClick}
                    >
                      <AddIcon icon={faPlus} />
                      {"Aggiungi documento"}
                    </AddPreviousDocumentButton>
                    {!!error ? (
                      <ErrorLabel>
                        {"Inserisci un identificativo per il documento"}
                      </ErrorLabel>
                    ) : null}
                  </AddPreviousDocumentGrid>
                ) : (
                  <>
                    <IdInput
                      id={
                        "tracciature_modifiche.id_versione_precedente.identificativo"
                      }
                      placeholder={"Inserisci l'ID del documento"}
                      required={true}
                      error={!!error}
                      errorMessage={
                        <ErrorLabel>
                          {"Inserisci un identificativo per il documento"}
                        </ErrorLabel>
                      }
                      onChange={field.onChange}
                      value={field.value}
                    />
                    <Tooltip title={"Elimina"}>
                      <Button kind={"tertiary"} onClick={handleOnDeleteClick}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </Tooltip>
                  </>
                )}
              </>
            )}
          />
        </PreviousDocumentContainer>
      )}
      <FileSha256Extractor
        title={"Aggiungi documento precedente"}
        isVisible={isModalOpen}
        onClose={handleOnModalClose}
        onConfirm={handleOnModalConfirm}
      />
    </div>
  );
};

PreviousDocumentAdder.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default PreviousDocumentAdder;
