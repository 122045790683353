import PropTypes from "prop-types";
import React from "react";

import { Button } from "./styled";

const TextButton = ({ onClick, children, ...rest }) => {
  return (
    <Button onClick={onClick} {...rest}>
      {children}
    </Button>
  );
};

TextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default TextButton;
