import axios from "axios";
import { useMutation } from "react-query";

import config from "../../../config";
import omitEmpty from "../../utils/omit-empty";
import {
  buildChangesTracking,
  buildFile,
  buildHolder,
  buildIdAgg,
  buildSubjects,
} from "./utils";

export const rectifyDocument = async ({
  token,
  rectifiedInputPackageId,
  formPayload,
  requestId,
}) => {
  const cleanPayload = omitEmpty(JSON.parse(JSON.stringify(formPayload)));

  if (
    !token ||
    !rectifiedInputPackageId ||
    !cleanPayload ||
    Object.keys(cleanPayload).length === 0
  ) {
    return;
  }

  const holderId = cleanPayload.holder.uuid;

  const pipe =
    (...functions) =>
    (payload) =>
      functions.reduce((result, next) => next(result), payload);

  const consistentPayload = pipe(
    buildFile,
    buildSubjects,
    buildIdAgg,
    buildChangesTracking,
    buildHolder
  )(cleanPayload);

  const formData = new FormData();
  formData.append("fileMetadata", JSON.stringify(consistentPayload.file));
  formData.append("details", JSON.stringify(consistentPayload.details));
  formData.append("holder", JSON.stringify(consistentPayload.holder));
  formData.append("rectificationReason", consistentPayload.rectificationReason);
  formData.append(
    "file",
    new Blob([Buffer.from(formPayload.file.content, "base64")])
  );

  return await axios.post(
    `${config.archiveApiBasePath}/v2/archive/${holderId}/documents/${rectifiedInputPackageId}/rectify`,
    formData,
    {
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );
};

const useRectifyDocument = (
  token,
  rectifiedInputPackageId,
  formPayload,
  requestId
) =>
  useMutation(() =>
    rectifyDocument({
      token,
      rectifiedInputPackageId,
      formPayload,
      requestId,
    })
  );

export default useRectifyDocument;
