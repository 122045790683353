import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Controller, useWatch } from "react-hook-form";

import InputsWrapper from "./inputs-wrapper";
import ErrorLabel from "../../../../../components/error-label";
import SectionHeader from "../../../../../components/section-header";
import {
  typeLabelMapping,
  roles,
} from "../../../../../../common/subject-mapping";

import {
  Actions,
  Container,
  DeleteButton,
  DeleteButtonIcon,
  Header,
  TypeSelect,
} from "./styled";

const Subject = ({ control, index, item, onDelete, unregister }) => {
  const watchSubject = useWatch({
    control,
    name: `details.soggetti.${index}`,
  });

  const handleOnDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const handleSubjectTypeChange = (value, onChange) => {
    onChange(value);
  };

  const subjectTypesForCurrentRole = roles[watchSubject.ruolo].types.map(
    (role) => ({
      value: role,
      label: typeLabelMapping[role],
    })
  );

  return (
    <Container data-testid="subject">
      <Header>
        <SectionHeader title={roles[watchSubject.ruolo].label.toUpperCase()} />
        {!item.required && (
          <Actions>
            <DeleteButton kind={"tertiary"} onClick={handleOnDelete}>
              <DeleteButtonIcon icon={faTrashAlt} />
              {"Elimina"}
            </DeleteButton>
          </Actions>
        )}
      </Header>
      {!["ASSEGNATARIO", "PRODUTTORE", "OPERATORE", "RGD", "RSP"].includes(
        watchSubject.ruolo
      ) && (
        <Controller
          name={`details.soggetti.${index}.tipo`}
          control={control}
          defaultValue={item.tipo}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TypeSelect
              data-testid={"type-selector"}
              area={"type"}
              label={"Tipo"}
              error={!!error}
              errorMessage={
                <ErrorLabel>{"Seleziona un tipo di soggetto"}</ErrorLabel>
              }
              options={subjectTypesForCurrentRole}
              placeholder={"Seleziona una tipologia di soggetto"}
              required={true}
              onChange={(value) =>
                handleSubjectTypeChange(value, field.onChange)
              }
              value={field.value}
            />
          )}
        />
      )}
      <InputsWrapper
        control={control}
        item={item}
        index={index}
        unregister={unregister}
      />
    </Container>
  );
};

Subject.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default Subject;
