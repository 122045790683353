import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

import LabeledToggle from "../../../../components/labeled-toggle";
import Select from "../../../../components/select";

export const Container = styled.div`
  width: 100%;
  margin-right: 64px;
`;

export const Spacing = styled.div`
  margin-bottom: 48px;
`;

export const SubscriptionDataGridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "flowType registryType"
    "date number"
    "registryCode .";
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  grid-template-rows: 100px 100px 88px;
  column-gap: 32px;
`;

export const FlowTypeSelect = styled(Select)`
  grid-area: flowType;
`;

export const RegistryTypeSelect = styled(Select)`
  grid-area: registryType;
`;

export const RegistryNumber = styled(Input)`
  grid-area: number;
`;

export const RegistryCode = styled(Input)`
  grid-area: registryCode;
`;

export const SpacedLabeledToggle = styled(LabeledToggle)`
  margin-bottom: 32px;
`;

export const ClassificationGridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "index description"
    "plan plan";
  grid-template-columns: minmax(200px, 1.5fr) minmax(200px, 2fr);
  grid-template-rows: 88px 88px;
  column-gap: 32px;
`;

export const ClassifcationIndex = styled(Input)`
  grid-area: index;
`;

export const ClassificationDescription = styled(Input)`
  grid-area: description;
`;

export const ClassificationPlan = styled(Input)`
  grid-area: plan;
`;
