import omit from "lodash.omit";

const buildFile = (payload) => {
  return {
    ...omit(payload, "externalApplicationId"),
    file: {
      ...omit(payload.file, "content"),
      externalApplicationId: payload.externalApplicationId,
    },
  };
};

const buildSubjects = (payload) => {
  return {
    ...payload,
    details: {
      ...payload.details,
      soggetti: payload.details.soggetti.map((subject) => {
        if (subject.tipo === "PA") {
          return omit(
            {
              ...subject,
              tipo: subject.id_paese === "IT" ? "PAI" : "PAE",
            },
            ["required", "isManual"]
          );
        }

        return omit(subject, ["required", "isManual"]);
      }),
    },
  };
};

const buildIdAgg = (payload) => {
  const validAggregations = payload.details.id_agg.filter(
    (aggregation) => aggregation.tipo_aggregazione !== "NESSUNO"
  );

  return {
    ...payload,
    details: {
      ...payload.details,
      id_agg: validAggregations.length > 0 ? validAggregations : undefined,
    },
  };
};

const buildChangesTracking = (payload) => {
  return {
    ...payload,
    details: {
      ...payload.details,
      tracciature_modifiche:
        payload.details.tracciature_modifiche.tipo === "NESSUNO" ||
        !payload.details.tracciature_modifiche.tipo
          ? undefined
          : {
              ...payload.details.tracciature_modifiche,
              soggetto_autore: omit(
                payload.details.soggetti.find(
                  (subject) => subject.ruolo === "OPERATORE"
                ),
                ["id_paese", "ruolo", "tipo", "matricola", "codice_sdi"]
              ),
            },
    },
  };
};

const buildHolder = (payload) => {
  return {
    ...payload,
    holder: {
      ...omit(payload.holder, [
        "countryCode",
        "uuid",
        "description",
        "isEnabled",
      ]),
      countryId: payload.holder.countryCode,
    },
  };
};

export {
  buildFile,
  buildSubjects,
  buildIdAgg,
  buildChangesTracking,
  buildHolder,
};
