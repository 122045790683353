import PropTypes from "prop-types";
import React from "react";
import { Controller, useWatch } from "react-hook-form";

import InfoTooltip from "../../../../components/info-tooltip";
import getReferenceYearRange from "../../../../../utils/get-reference-year-range";
import TextButton from "../../../../components/text-button";
import { useElevioContext } from "../../../../../core/hooks/elevio-context-provider";

import {
  Container,
  DocumentIdInput,
  HolderSelect,
  KeywordsInput,
  ReferenceYear,
  ObjectInput,
  TypologySelect,
} from "./styled";
import ErrorLabel from "../../../../components/error-label";

const Step1 = ({ control, holders, isRectification }) => {
  const elevioContext = useElevioContext();
  const holder = useWatch({ name: "holder", control });
  const isHolderDisabled = !isRectification && holder && !holder.isEnabled;

  const openElevioSidebar = () => {
    elevioContext.openElevioSidebar();
  };

  return (
    <Container>
      <Controller
        name={"holder"}
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <HolderSelect
            id={"holder"}
            label={"Titolare"}
            error={!!error || isHolderDisabled}
            errorMessage={
              error && error.type === "required"
                ? "Seleziona un titolare"
                : "Anagrafica incompleta. Per proseguire è necessario completare l’inserimento dei dati anagrafici del titolare."
            }
            action={
              error && error.type === "required" ? (
                ""
              ) : (
                <TextButton onClick={openElevioSidebar}>
                  {"Contatta l’assistenza per inserire i dati mancanti."}
                </TextButton>
              )
            }
            options={holders.data.map((holder) => ({
              value: holder.uuid,
              label: `${holder.vatNumber} ${holder.description}`,
            }))}
            placeholder={
              "Seleziona un titolare cercando per nome o partita IVA"
            }
            required={true}
            onChange={(value) =>
              field.onChange(
                holders.data.find((holder) => holder.uuid === value)
              )
            }
            value={field.value ? field.value.uuid : null}
            disabled={isRectification}
          />
        )}
      />
      <Controller
        name={"details.anno_riferimento"}
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <ReferenceYear
            id={"reference_year"}
            label={"Anno esercizio"}
            error={!!error}
            errorMessage={"Seleziona un anno di esercizio"}
            options={getReferenceYearRange().map((year) => ({
              value: Number(year),
              label: year.toString(),
            }))}
            placeholder={"Seleziona un anno di esercizio"}
            required={true}
            onChange={field.onChange}
            value={field.value}
            disabled={isHolderDisabled}
          />
        )}
      />
      <Controller
        name={"details.tipologia_documentale"}
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <TypologySelect
            id={"details.tipologia_documentale"}
            error={!!error}
            required={true}
            onChange={field.onChange}
            value={field.value}
            disabled={isHolderDisabled}
          />
        )}
      />
      <Controller
        name={"externalApplicationId"}
        control={control}
        defaultValue={""}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <DocumentIdInput
            id={"file.externalApplicationId"}
            label={"Identificativo"}
            additionalLabel={
              <InfoTooltip
                title={"Identificativo"}
                text={
                  "Come da sistema di identificazione formalmente definito (per es: id assegnato dal sistema di gestione documentale, oppure id assegnato dalla piattaforma o dal gestionale di provenienza; in mancanza si può indicare il nome del file."
                }
              />
            }
            error={!!error}
            errorMessage={<ErrorLabel>{"Inserisci un ID"}</ErrorLabel>}
            placeholder={"Inserisci ID documento"}
            required={true}
            onChange={field.onChange}
            value={field.value}
            disabled={isHolderDisabled}
          />
        )}
      />
      <Controller
        name={"details.chiave_descrittiva.oggetto"}
        control={control}
        defaultValue={""}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <ObjectInput
            id={"details.chiave_descrittiva.oggetto"}
            label={"Oggetto"}
            additionalLabel={
              <InfoTooltip
                title={"Oggetto"}
                text={
                  "Testo libero che definisce il contenuto del documento o la natura."
                }
              />
            }
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Inserisci un oggetto per il documento"}</ErrorLabel>
            }
            placeholder={"Inserisci l'oggetto del documento"}
            required={true}
            onChange={field.onChange}
            value={field.value}
            disabled={isHolderDisabled}
          />
        )}
      />
      <Controller
        name={"details.chiave_descrittiva.parole_chiave"}
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <KeywordsInput
            id={"details.chiave_descrittiva.parole_chiave"}
            label={"Parole chiave"}
            additionalLabel={
              <InfoTooltip
                title={"Parole chiave"}
                text={
                  "Compilare facoltativamente attingendo da thesauri o da vocabolari controllati, per evitare ambiguità terminologiche e avere la possibilità di utilizzare il metadato come chiave di ricerca del documento. Il metadato è ricorsivo fino ad un massimo di 5 occorrenze."
                }
              />
            }
            placeholder={"Inserisci una parola chiave"}
            maximum={5}
            onChange={field.onChange}
            values={field.value}
            disabled={isHolderDisabled}
          />
        )}
      />
    </Container>
  );
};

Step1.propTypes = {
  control: PropTypes.object.isRequired,
  holders: PropTypes.object.isRequired,
  isRectification: PropTypes.bool,
};

export default Step1;
