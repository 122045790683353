import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import BaseError from "../../components/error";
import BaseLoading from "../../components/loading";
import Select from "../../components/select";
import DistributionPackagesListComponent from "./list";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Paper = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  width: ${({ theme }) => `calc(100% - ${theme.spacing.unit * 8}px`});
  box-sizing: border-box;
  background-color: #fff;
  flex-grow: 1;
  overflow: auto;
  display: grid;
  grid-template-areas:
    'holder cards cards'
    'typology cards cards'
    'reference-year cards cards'
    'from cards cards'
    'to cards cards';
  grid-template-columns: 300px 500px auto;
  grid-template-rows: 88px 88px 88px 88px 88px auto;
  align-items: start;
  column-gap: ${({ theme }) => `${theme.spacing.unit * 2}px`};
`;

export const HolderSelect = styled(Select)`
  grid-area: holder;
`;

export const HoldersLoading = styled(BaseLoading)``;

export const DistributionPackagesEmptyList = styled.div`
  grid-area: cards;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DistributionPackagesEmptyListMsg = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
`;

export const DistributionPackagesLoading = styled(BaseLoading)`
  grid-area: cards;
  height: 50px;
`;

export const DistributionPackagesError = styled(BaseError)`
  grid-area: cards;
`;

export const DistributionPackagesList = styled(
  DistributionPackagesListComponent
)`
  grid-area: cards;
`;
