import PropTypes from "prop-types";
import React from "react";

import SectionHeader from "../../../../components/section-header";
import AggregationsAdder from "./aggregations-adder";
import AttachmentsAdder from "./attachments-adder";
import PrimaryDocumentAdder from "./primary-document-adder";

import { Container, Spacing } from "./styled.js";

const Step5 = ({ control, watch, setValue, unregister }) => {
  return (
    <Container>
      <Spacing>
        <SectionHeader
          title={"ALLEGATI"}
          subtitle={
            "Informazioni sui file eventualmente allegati (massimo 10)."
          }
        />
        <AttachmentsAdder control={control} watch={watch} setValue={setValue} />
      </Spacing>
      <Spacing>
        <SectionHeader
          title={"DOCUMENTO PRIMARIO"}
          subtitle={
            "Se il documento che stai inviando è l’allegato di un altro, indica il codice identificativo di quel documento."
          }
        />
        <PrimaryDocumentAdder
          control={control}
          watch={watch}
          setValue={setValue}
          unregister={unregister}
        />
      </Spacing>
      <Spacing>
        <SectionHeader
          title={"AGGREGAZIONI"}
          subtitle={
            "Fascicoli, serie di fascicoli o di documenti di cui fa parte il documento che stai inviando (massimo 10)."
          }
        />
        <AggregationsAdder control={control} watch={watch} />
      </Spacing>
    </Container>
  );
};

Step5.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default Step5;
