import axios from "axios";
import { useInfiniteQuery } from "react-query";
import qs from "qs";

import config from "../../../config";
import convertInputPackageMetadata from "./convert-input-package-metadata";
import omitEmpty from "../../utils/omit-empty";

const useInputPackages = (
  token,
  holderId,
  documentTypeCode,
  searchString,
  referenceYear,
  from,
  to,
  requestId
) =>
  useInfiniteQuery(
    ["input-packages", requestId],
    async ({ pageParam = 0 }) => {
      const searchParams = {
        documentTypeCode,
        searchString,
        referenceYear,
        from: from ? from.startOf("day").valueOf() : null,
        to: to ? to.endOf("day").valueOf() : null,
        page: pageParam,
        size: 10,
      };

      const { data } = await axios.get(
        `${
          config.archiveApiBasePath
        }/v1/archive/${holderId}/documents?${qs.stringify(
          omitEmpty(searchParams)
        )}`,
        {
          headers: {
            Authorization: token,
            "X-App-Name": config.appName,
            "X-App-Version": config.appVersion,
            "X-Request-ID": requestId,
            "X-Correlation-ID": requestId,
          },
        }
      );

      return {
        data: data.data.map((inputPackage) => {
          const documentDate =
            inputPackage.metadata.DataEmissione ||
            inputPackage.metadata.data_emissione ||
            inputPackage.metadata.DataRegistrazione ||
            inputPackage.metadata.data_registrazione ||
            inputPackage.metadata.DataDocumento ||
            inputPackage.metadata.data_documento ||
            inputPackage.metadata.data_trasmissione ||
            inputPackage.metadata.data_ricevuta ||
            inputPackage.metadata.data_contratto;
          return {
            ...inputPackage,
            metadata: convertInputPackageMetadata(inputPackage.metadata),
            documentDate: documentDate ? documentDate.value : undefined,
            holderId,
          };
        }),
        nextPage: data.nextPage,
        total: data.total,
      };
    },
    {
      enabled:
        !!token &&
        !!holderId &&
        !!documentTypeCode &&
        !!referenceYear &&
        !!requestId,
      refetchOnWindowFocus: false,
      retry: 3,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );

export default useInputPackages;
