import { Button, Input } from "@ts-digital/vrc";
import styled from "styled-components";

import DatePicker from "../../components/date-picker";
import DocumentCountComponent from "../../components/document-count-badge";
import Loading from "../../components/loading";
import TypologySelect from "../../components/typology-select";
import Select from "../../components/select";
import InputPackagesListComponent from "./list";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Paper = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  width: ${({ theme }) => `calc(100% - ${theme.spacing.unit * 8}px`});
  box-sizing: border-box;
  background-color: #fff;
  flex-grow: 1;
  overflow: auto;
  display: grid;
  grid-template-areas:
    'typology reference-year search-input action-buttons'
    'holder count . icon-buttons'
    'from cards cards cards'
    'to cards cards cards';
  grid-template-columns: 300px 220px auto 240px;
  grid-template-rows: 100px 100px 100px auto;
  align-items: start;
  column-gap: ${({ theme }) => `${theme.spacing.unit * 4}px`};
`;

export const StyledTypologySelect = styled(TypologySelect)`
  grid-area: typology;
`;

export const HolderSelect = styled(Select)`
  grid-area: holder;
`;

export const ReferenceYear = styled(Select)`
  grid-area: reference-year;
`;

export const From = styled(DatePicker)`
  grid-area: from;
`;

export const To = styled(DatePicker)`
  grid-area: to;
`;

export const SearchInput = styled(Input)`
  grid-area: search-input;
`;

export const SearchButton = styled(Button)`
  margin-top: ${({ theme }) => `${theme.spacing.unit * 3}px`};
  grid-area: action-buttons;
  min-width: 140px;
  margin-right: ${({ theme }) => `${theme.spacing.unit * 2}px`};
`;

export const HoldersLoading = styled(Loading)``;

export const InputPackagesList = styled(InputPackagesListComponent)`
  grid-area: cards;
`;

export const DocumentsCount = styled(DocumentCountComponent)`
  grid-area: count;
`;
