import axios from "axios";
import fileSaver from "file-saver";

import config from "../../../config";
import getFileNameFromContentDisposition from "../../utils/get-file-name-from-content-disposition";
import { useQuery } from "react-query";

export const fetchProofOfPreservationPdf = async ({
  token,
  holderId,
  documentId,
  requestId,
}) => {
  if (!holderId || !documentId || !token || !requestId) {
    return;
  }

  const { data, headers } = await axios.get(
    `${config.archiveApiBasePath}/v1/archive/${holderId}/certificate/preservation/${documentId}/pdf`,
    {
      responseType: "arraybuffer",
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
        Accept: "application/pdf",
      },
    }
  );

  const blob = new Blob([data], {
    type: "application/pdf",
  });

  const fileName = getFileNameFromContentDisposition(
    headers["content-disposition"]
  );
  fileSaver.saveAs(blob, fileName);
};

const useProofOfPreservationPdf = (token, holderId, documentId, requestId) =>
  useQuery(
    ["proof-of-preservation-pdf", requestId],
    () =>
      fetchProofOfPreservationPdf({ token, holderId, documentId, requestId }),
    {
      refetchOnWindowFocus: false,
      retry: 3,
      initialData: "",
    }
  );

export default useProofOfPreservationPdf;
