import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { DevTool } from "@hookform/devtools";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { v4 } from "uuid";

import typologiesSpecializationsMapOrig from "../../../common/typologies-specializations-map.json";
import userAtom from "../../../core/atoms/user";
import useHolders from "../../../core/hooks/holders";
import useUploadDocument from "../../../core/hooks/upload-document";
import getTypologiesSpecializationsMap from "../../../utils/get-typologies-specializations-map";
import Error from "../../components/error";
import BaseLoading from "../../components/loading";
import FeedbackModal from "./feedback-modal";
import Step1 from "./steps/step-1";
import Step2 from "./steps/step-2";
import Step3 from "./steps/step-3";
import Step4 from "./steps/step-4";
import Step5 from "./steps/step-5";
import Step6 from "./steps/step-6";
import Step7 from "./steps/step-7";
import {
  StepContainer,
  DocumentUploader,
  GridContainer,
  SendingIcon,
  StyledActionFooter,
  UploadStep,
  UploadStepsContainers,
  PreviewContainer,
} from "./styled.js";

const typologiesSpecializationsMap = getTypologiesSpecializationsMap(
  typologiesSpecializationsMapOrig
);

const UploadDocument = ({
  handleRectification,
  isRectification,
  defaultValues,
}) => {
  const [uploadDocumentRequestId, setUploadDocumentRequestId] = useState("");
  const [isFeedBackModalVisible, setIsFeedBackModalVisible] = useState(false);
  const [formState, setFormState] = useState(null);
  const [step, setStep] = useState(0);

  const history = useHistory();
  const [user] = useRecoilState(userAtom);
  const holders = useHolders(user.token);

  const uploadDocumentRequest = useUploadDocument(
    user.token,
    formState,
    uploadDocumentRequestId
  );

  useEffect(() => {
    uploadDocumentRequest.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadDocumentRequestId]);
  const {
    control,
    setValue,
    trigger,
    getValues,
    register,
    unregister,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (user.submitterUsername) {
      setValue("submitter.username", user.submitterUsername);
    }
  }, [user.submitterUsername, setValue]);

  // in fase di rettifica il contenuto del file deve
  // essere impostato manualmente
  useEffect(() => {
    if (defaultValues && defaultValues.file && defaultValues.file.content) {
      setValue("file.content", defaultValues.file.content);
    }
  }, [defaultValues, setValue]);

  const onPreviousStep = () => {
    if (step === 0) {
      goToSearchPage();
      return;
    }

    setStep(step - 1);
  };

  const onNextStep = async () => {
    const isCurrentStepValid = await trigger();

    if (!isCurrentStepValid) {
      return;
    }

    if (step === 6) {
      if (isRectification) {
        handleSubmit(
          (data) => {
            handleRectification(data);
          },
          (errors) => console.log("ERROR SUBMIT FORM", errors)
        )();

        return;
      }

      handleSubmit(
        (data) => {
          setIsFeedBackModalVisible(true);
          setFormState(data);
        },
        (errors) => console.log("ERROR SUBMIT FORM", errors)
      )();

      return;
    }

    setStep(step + 1);
  };

  const handleOnDocumentUpload = (content, fileName, fileHash, onChange) => {
    setValue("file.hash_256", fileHash);
    setValue("file.name", fileName);
    setValue("file.content", content);

    onChange({
      hash_256: fileHash,
      name: fileName,
      content: content,
    });
  };

  const handleOnDocumentDelete = (onChange) => {
    setValue("file.hash_256", "");
    setValue("file.name", "");
    setValue("file.content", "");

    onChange(null);
  };

  const handleFeedbackModalClose = () => {
    setIsFeedBackModalVisible(false);
    uploadDocumentRequest.reset();
  };

  const goToSearchPage = () => {
    handleFeedbackModalClose();
    history.push("/input-packages");
  };

  const goToUploadPage = () => {
    handleFeedbackModalClose();
    history.push("/upload-input-package");

    reset({
      details: {
        tipologia_documentale: "1001",
        allegati: { indice_allegati: [] },
        id_agg: [{ tipo_aggregazione: "NESSUNO", id_aggregazione: "" }],
        tracciature_modifiche: { tipo: "NESSUNO" },
      },
    });
    setStep(0);
  };

  const handleFeedbackModalConfirm = () => {
    setUploadDocumentRequestId(v4());
  };

  const watchTypology = watch("details.tipologia_documentale");
  const holder = watch("holder");
  const isHolderDisabled = !isRectification && holder && !holder.isEnabled;

  const loadingFileText = (
    <div>
      <SendingIcon icon={faCircleNotch} />
      <p>{"Caricamento file in corso..."}</p>
    </div>
  );

  if (holders.isIdle || holders.isFetching) {
    return <BaseLoading />;
  }

  if (holders.isError) {
    return (
      <Error
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
        onRetry={holders.refetch}
      />
    );
  }

  return (
    <>
      <DevTool control={control} placement="bottom-right" />
      {isFeedBackModalVisible && (
        <FeedbackModal
          correlationId={uploadDocumentRequestId}
          isError={uploadDocumentRequest.isError}
          isSending={uploadDocumentRequest.isLoading}
          isSuccess={
            uploadDocumentRequest.isSuccess && !!uploadDocumentRequest.data
          }
          onClose={handleFeedbackModalClose}
          onGoBackToSearch={goToSearchPage}
          onGoBackToUpload={goToUploadPage}
          onConfirm={handleFeedbackModalConfirm}
          visible={isFeedBackModalVisible}
        />
      )}
      <UploadStepsContainers
        type="navigation"
        size="small"
        current={step}
        onChange={setStep}
        className="site-navigation-steps"
      >
        <UploadStep
          title="1. Generale"
          status={step === 0 ? "process" : "wait"}
          disabled
        />
        <UploadStep
          title="2. Contenuto"
          status={step === 1 ? "process" : "wait"}
          disabled
        />
        <UploadStep
          title="3. Formato, sicurezza e verifica"
          status={step === 2 ? "process" : "wait"}
          disabled
        />
        <UploadStep
          title="4. Dati di registrazione"
          status={step === 3 ? "process" : "wait"}
          disabled
        />
        <UploadStep
          title="5. Altri documenti"
          status={step === 4 ? "process" : "wait"}
          disabled
        />
        <UploadStep
          title="6. Modifiche e note"
          status={step === 5 ? "process" : "wait"}
          disabled
        />
        <UploadStep
          title="7. Soggetti"
          status={step === 6 ? "process" : "wait"}
          disabled
        />
      </UploadStepsContainers>
      <GridContainer>
        <StepContainer>
          {step === 0 && (
            <Step1
              control={control}
              getValues={getValues}
              isRectification={isRectification}
              holders={holders}
            />
          )}
          {step === 1 && (
            <Step2
              control={control}
              getValues={getValues}
              setValue={setValue}
              unregister={unregister}
            />
          )}
          {step === 2 && <Step3 control={control} />}
          {step === 3 && (
            <Step4 control={control} watch={watch} unregister={unregister} />
          )}
          {step === 4 && (
            <Step5
              control={control}
              watch={watch}
              setValue={setValue}
              unregister={unregister}
            />
          )}
          {step === 5 && (
            <Step6
              control={control}
              watch={watch}
              setValue={setValue}
              register={register}
              unregister={unregister}
            />
          )}
          {step === 6 && (
            <Step7 control={control} watch={watch} unregister={unregister} />
          )}
        </StepContainer>
        <PreviewContainer>
          <Controller
            name={"file"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <DocumentUploader
                label={"Documento"}
                hint={"Trascina un file in quest'area, oppure"}
                error={!!error}
                errorMessage={"Seleziona un file da conservare"}
                typology={watchTypology}
                acceptedExtensions={
                  watchTypology &&
                  typologiesSpecializationsMap[
                    watchTypology
                  ].enabledExtensions.map((extension) => `.${extension}`)
                }
                loadingText={loadingFileText}
                showActionButton={true}
                showPreview={true}
                onFileLoaded={(content, name, fileHash) =>
                  handleOnDocumentUpload(
                    content,
                    name,
                    fileHash,
                    field.onChange
                  )
                }
                onReset={() => handleOnDocumentDelete(field.onChange)}
                value={field.value}
                disabled={!watchTypology || isHolderDisabled}
              />
            )}
          />
        </PreviewContainer>
        <StyledActionFooter
          nextLabel={
            step === 6 ? (isRectification ? "Salva" : "Invia") : "Avanti"
          }
          onPreviousClick={onPreviousStep}
          onNextClick={onNextStep}
          disableNextButton={isHolderDisabled}
        />
      </GridContainer>
    </>
  );
};

UploadDocument.propTypes = {
  handleRectification: PropTypes.func,
  isRectification: PropTypes.bool,
  defaultValues: PropTypes.object,
};

UploadDocument.defaultProps = {
  isRectification: false,
  defaultValues: {
    details: {
      tipologia_documentale: "1001",
      allegati: { indice_allegati: [] },
      id_agg: [{ tipo_aggregazione: "NESSUNO", id_aggregazione: "" }],
      tracciature_modifiche: { tipo: "NESSUNO" },
    },
  },
};

export default UploadDocument;
