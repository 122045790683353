import styled from "styled-components";

import BaseLoading from "../../components/loading";
import Select from "../../components/select";
import PreviewBase from "./preview";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Paper = styled.div`
  margin-top: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  width: ${({ theme }) => `calc(100% - ${theme.spacing.unit * 8}px`});
  box-sizing: border-box;
  background-color: #fff;
  overflow: auto;
  flex-grow: 1;
  display: grid;
  grid-template-areas:
    'holder preview'
    '. preview';
  grid-template-columns: 300px auto;
  grid-template-rows: 88px auto;
  align-items: start;
  column-gap: ${({ theme }) => `${theme.spacing.unit * 2}px`};
`;

export const HolderSelect = styled(Select)`
  grid-area: holder;
`;

export const HoldersLoading = styled(BaseLoading)``;

export const Preview = styled(PreviewBase)`
  grid-area: preview;
  border: none;
`;
