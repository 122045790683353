import { Button, Input } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../../../../../components/fa-icon";

export const PrimaryDocumentContainer = styled.div`
  height: 72px;
  display: grid;
  justify-items: start;
  grid-column-gap: 32px;
  grid-template-columns: 7fr 1fr;
`;

export const IdInput = styled(Input)`
  width: 100%;
`;

export const ActionButton = styled(Button)`
  margin-top: 24px;
`;

export const AddPrimaryDocumentButton = styled(Button)`
  height: 53px;
`;

export const AddIcon = styled(FaIcon)`
  font-size: 16px;
  margin-right: 12px;
`;
