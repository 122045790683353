import { Steps } from "antd";
import styled, { keyframes } from "styled-components";

import FaIcon from "../../components/fa-icon";
import FileUploader from "../../components/file-uploader";
import ActionFooter from "./action-footer";

const { Step } = Steps;

export const UploadStepsContainers = styled(Steps)`
  background-color: #fff;
`;

export const GridContainer = styled.div`
  height: calc(100vh - 96px);
  background: #fff;
  display: grid;
  grid-template-areas:
    "step preview"
    "footer footer";
  grid-template-columns: minmax(200px, 5fr) minmax(850px, 4fr);
  grid-template-rows: auto 72px;
`;

export const StepContainer = styled.div`
  grid-area: step;
  padding: 44px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const PreviewContainer = styled.div`
  background-color: #fff;
  grid-area: preview;
  padding: 44px 44px 44px 0;
`;

export const StyledActionFooter = styled(ActionFooter)`
  grid-area: footer;
`;

export const UploadStep = styled(Step)`
  .ant-steps-item-icon {
    display: none;
  }
`;

export const DocumentUploader = styled(FileUploader)`
  width: 100%;
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const SendingIcon = styled(FaIcon)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  font-size: 32px;
  animation: ${rotate} 2s linear infinite;
`;
