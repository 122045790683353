import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

import FileDownloadIcon from "../../../../components/file-download-icon";
import Tag from "../../../../components/tag";

export const Container = styled.div`
  margin: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  box-shadow: 0px 0px 7px 0px #bfbfbf;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
  padding: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const ReferenceYear = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.yankeesBlue};
  padding: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const Identifier = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.quickSilver};
  padding: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const Metadata = styled.div`
  width: 220px;
`;

export const MetadataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
  margin-top: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-left: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  display: block;
`;

export const Value = styled(Typography)`
  margin-left: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  display: block;
`;

export const StatusContainer = styled.div`
  display: flex;
`;

export const Status = styled(Tag)`
  margin: 0;
  max-height: 24px;
`;

export const StatusDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.accent.maximumPurple};
  margin-left: 8px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DownloadIcon = styled(FileDownloadIcon)`
  color: ${({ theme }) => theme.colors.primary.richElectricBlue};
  font-size: 24px;
`;
