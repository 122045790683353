import React, { useState } from "react";
import { useRecoilState } from "recoil";

import userAtom from "../../../core/atoms/user";
import useHolders from "../../../core/hooks/holders";
import Error from "../../components/error";

import {
  Container,
  HolderSelect,
  HoldersLoading,
  Paper,
  Preview,
} from "./styled.js";

const Article52 = () => {
  const [user] = useRecoilState(userAtom);
  const [holder, setHolder] = useState(null);

  const holders = useHolders(user.token);

  if (holders.isIdle || holders.isFetching) {
    return <HoldersLoading />;
  }

  if (holders.isError) {
    return (
      <Error
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
        onRetry={holders.refetch}
      />
    );
  }

  return (
    <Container>
      <Paper>
        <HolderSelect
          label="Titolare"
          onChange={setHolder}
          options={
            holders.data &&
            holders.data.map((holder) => ({
              value: holder,
              label: `${holder.vatNumber} ${holder.description}`,
            }))
          }
          placeholder="Seleziona un titolare cercando per nome o partita IVA"
          required={true}
          value={holder}
        />
        <Preview holderId={holder && holder.uuid} />
      </Paper>
    </Container>
  );
};

export default Article52;
