import PropTypes from "prop-types";
import React from "react";
import { useWatch } from "react-hook-form";

import { IdLabel } from "./styled";

const Labels = ({ control }) => {
  const watchChangesTracking = useWatch({
    control,
    name: "details.tracciature_modifiche",
  });

  return (
    <div>
      {watchChangesTracking && watchChangesTracking.tipo !== "NESSUNO" && (
        <IdLabel>{"Identificativo documento precedente *"}</IdLabel>
      )}
    </div>
  );
};

Labels.propTypes = {
  control: PropTypes.object.isRequired,
};

export default Labels;
