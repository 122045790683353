import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

import ErrorLabel from "../../../../../components/error-label";
import FileSha256Extractor from "../../../../../components/file-sha256-extractor";

import {
  ActionButton,
  AddIcon,
  AddPrimaryDocumentButton,
  IdInput,
  PrimaryDocumentContainer,
} from "./styled.js";

const PrimaryDocumentAdder = ({ control, watch, setValue, unregister }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const watchHashValue = watch(
    "details.identificativo_documento_primario.impronta"
  );

  // remove the empty object from the form state
  // when the input gets deleted
  useEffect(() => {
    if (watchHashValue === "") {
      unregister("details.identificativo_documento_primario");
    }
  }, [unregister, watchHashValue]);

  const handleOnAddClick = () => {
    setIsModalOpen(true);
  };
  const handleOnDeleteClick = () => {
    setValue("details.identificativo_documento_primario.impronta", "");
    setValue("details.identificativo_documento_primario.identificativo", "");
    setValue("details.identificativo_documento_primario.algoritmo", "");
  };

  const handleOnModalClose = () => {
    setIsModalOpen(false);
  };
  const handleOnModalConfirm = (hash) => {
    setValue("details.identificativo_documento_primario.impronta", hash);
    setValue("details.identificativo_documento_primario.algoritmo", "SHA256");
    setIsModalOpen(false);
  };

  return (
    <div>
      {watchHashValue && (
        <PrimaryDocumentContainer>
          <Controller
            name={"details.identificativo_documento_primario.identificativo"}
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <IdInput
                id={"identificativo_documento_primario.identificativo"}
                error={!!error}
                errorMessage={
                  <ErrorLabel>
                    {"Inserisci un identificativo per il documento"}
                  </ErrorLabel>
                }
                label={"Identificativo"}
                placeholder={"Inserisci l'ID del documento"}
                required={true}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <Tooltip title={"Elimina"}>
            <ActionButton kind={"tertiary"} onClick={handleOnDeleteClick}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </ActionButton>
          </Tooltip>
        </PrimaryDocumentContainer>
      )}
      {!watchHashValue && (
        <AddPrimaryDocumentButton
          disabled={false}
          kind={"tertiary"}
          onClick={handleOnAddClick}
        >
          <AddIcon icon={faPlus} />
          {"Aggiungi documento primario"}
        </AddPrimaryDocumentButton>
      )}
      <FileSha256Extractor
        title={"Aggiungi documento primario"}
        isVisible={isModalOpen}
        onClose={handleOnModalClose}
        onConfirm={handleOnModalConfirm}
      />
    </div>
  );
};

PrimaryDocumentAdder.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default PrimaryDocumentAdder;
