import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

import KeywordInput from "../../../../components/keyword-input";
import Select from "../../../../components/select";
import DocumentTypologySelect from "../../../../components/typology-select";

export const Container = styled.div`
  width: 100%;
  margin-right: 64px;
  display: grid;
  grid-template-areas:
    "holder holder referenceYear"
    "ducumentType ducumentType ducumentType"
    "ducumentId object object"
    "keywords keywords keywords";
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(
      200px,
      1fr
    );
  grid-template-rows: 140px 100px 100px;
  column-gap: ${({ theme }) => `${theme.spacing.unit * 4}px`};
`;

export const HolderSelect = styled(Select)`
  grid-area: holder;
`;

export const ReferenceYear = styled(Select)`
  grid-area: referenceYear;
`;

export const TypologySelect = styled(DocumentTypologySelect)`
  grid-area: ducumentType;
`;

export const DocumentIdInput = styled(Input)`
  grid-area: ducumentId;
`;

export const ObjectInput = styled(Input)`
  grid-area: object;
`;

export const KeywordsInput = styled(KeywordInput)`
  grid-area: keywords;
`;
