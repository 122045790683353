import PropTypes from "prop-types";
import { useSetRecoilState } from "recoil";
import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";

import firstVisitAtom from "../../../../core/atoms/firstVisit";
import { ReactComponent as HumanEmptySearch } from "../../../../svgs/humanEmptySearch.svg";
import { ReactComponent as HumanFirstSearch } from "../../../../svgs/humanStartSearch.svg";
import InputPackagePanel from "./panel";
import {
  Container,
  EmptyList,
  EmptyListMsg,
  EmptyListTitle,
  Error,
  Loading,
  LoadMoreAnchor,
} from "./styled";

const InputPackagesList = ({
  className,
  fetchNextPage,
  inputPackages,
  hasNextPage,
  isHolderEnabled,
  isFetching,
  isFetchingNextPage,
  isError,
}) => {
  const setFirstVisit = useSetRecoilState(firstVisitAtom);
  const [isFirstVisit] = useRecoilState(firstVisitAtom);
  const loader = useRef(null);

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  });

  useEffect(() => {
    return function cleanup() {
      setFirstVisit(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      fetchNextPage();
    }
  };

  if (isFetching && !isFetchingNextPage) {
    return <Loading data-testid={"loading"} />;
  }

  if (isError) {
    return (
      <Error
        message={"Errore nel caricamento della pagina. Riprovare più tardi"}
      />
    );
  }

  if (isFirstVisit && !inputPackages) {
    return (
      <EmptyList>
        <HumanFirstSearch />
        <EmptyListTitle variant={"subtitleLarge"}>
          {"Cerca documenti"}
        </EmptyListTitle>
        <EmptyListMsg variant="bodyLarge">
          {"Scegli tipologia, anno e titolare per iniziare la ricerca"}
        </EmptyListMsg>
      </EmptyList>
    );
  }

  if (
    !inputPackages ||
    inputPackages.pages[0].length === 0 ||
    inputPackages.pages[0].data.length === 0
  ) {
    return (
      <EmptyList>
        <HumanEmptySearch />
        <EmptyListTitle variant={"subtitleLarge"}>
          {"Nessun risultato"}
        </EmptyListTitle>
        <EmptyListMsg variant="bodyLarge">
          {"Nessun documento trovato, modifica i filtri di ricerca"}
        </EmptyListMsg>
      </EmptyList>
    );
  }

  return (
    <Container data-testid={"container"} className={className}>
      {inputPackages.pages.map((inputPackagePage) =>
        inputPackagePage.data.map((inputPackage) => (
          <InputPackagePanel
            data-testid="input-package"
            key={inputPackage.identifier}
            identifier={inputPackage.identifier}
            depositPackageIdentifier={inputPackage.pdvIdentifier}
            metadata={inputPackage.metadata}
            referenceYear={inputPackage.referenceYear}
            documentTypeCode={inputPackage.documentTypeCode}
            documentStatus={inputPackage.status}
            errorMessage={inputPackage.errorMessage}
            documentDate={inputPackage.documentDate}
            holderId={inputPackage.holderId}
            isHolderEnabled={isHolderEnabled}
            rectifiedInputPackageId={inputPackage.rectifiedPdIId}
            rectificationReason={inputPackage.rectificationReason}
            rectifierInputPackageId={inputPackage.rectifierPdIId}
          />
        ))
      )}
      {isFetchingNextPage && <Loading data-testid="loading-more" />}
      {!isFetchingNextPage && hasNextPage && <LoadMoreAnchor ref={loader} />}
    </Container>
  );
};

InputPackagesList.propTypes = {
  className: PropTypes.string,
  fetchNextPage: PropTypes.func.isRequired,
  inputPackages: PropTypes.object,
  hasNextPage: PropTypes.bool,
  isHolderEnabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool,
  isError: PropTypes.bool,
};

export default InputPackagesList;
