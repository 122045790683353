import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

import KeywordInput from "../../../../../../../components/keyword-input";
import Select from "../../../../../../../components/select";

export const Container = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-template-areas:
    "lastName firstName"
    "taxId registrationNumber"
    "country sdiCode"
    "emails emails";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 100px);
`;

export const GridSelect = styled(Select)`
  grid-area: ${({ area }) => area};
`;

export const GridInput = styled(Input)`
  grid-area: ${({ area }) => area};
`;

export const EmailsInput = styled(KeywordInput)`
  grid-area: emails;
`;
