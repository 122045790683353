import { Button, Modal } from "@ts-digital/vrc";
import PropTypes from "prop-types";
import React from "react";

import { AbortButton, ButtonsContainer, Container, Text } from "./styled";

const RegistryWarningModal = ({ isVisible, onClose, onConfirm }) => {
  return (
    <Modal
      visible={isVisible}
      title={"Anagrafica del titolare incompleta"}
      onClose={onClose}
      abortText={"Annulla"}
      confirmText={"Contatta l'assistenza"}
      closable={true}
      outsideClosable={false}
      footer={
        <ButtonsContainer>
          <AbortButton kind={"secondary"} onClick={onClose}>
            {"Annulla"}
          </AbortButton>
          <Button kind={"primary"} onClick={onConfirm}>
            {"Contatta l'assistenza"}
          </Button>
        </ButtonsContainer>
      }
    >
      <Container>
        <Text variant={"bodyLarge"}>
          {
            "Per rettificare i documenti è necessario completare l’inserimento dei dati anagrafici del titolare."
          }
        </Text>
        <br />
        <Text variant={"bodyLarge"}>
          {"Contatta l’assistenza per inserire i dati mancanti."}
        </Text>
      </Container>
    </Modal>
  );
};

RegistryWarningModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default RegistryWarningModal;
