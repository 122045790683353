import PropTypes from "prop-types";
import React from "react";
import { useRecoilState } from "recoil";
import { v4 } from "uuid";

import typologiesSpecializationsMapOriginal from "../../../../../common/typologies-specializations-map.json";
import userAtom from "../../../../../core/atoms/user";
import getTypologiesSpecializationsMap from "../../../../../utils/get-typologies-specializations-map";
import useDistributionPackageDownload from "../../../../../core/hooks/distribution-package-download";

import {
  Container,
  DownloadIcon,
  Header,
  Identifier,
  Label,
  Metadata,
  MetadataContainer,
  ReferenceYear,
  Status,
  Value,
  Title,
  StatusContainer,
  StatusDescription,
} from "./styled.js";

const typologiesSpecializationsMap = getTypologiesSpecializationsMap(
  typologiesSpecializationsMapOriginal
);

const statusMapping = {
  ACCEPTED: { color: "BlueSapphire", label: "accettato" },
  PROCESSING: { color: "BlueSapphire", label: "in lavorazione" },
  DONE: { color: "IslamicGreen", label: "disponibile" },
  CANCELLED: { color: "MaximumPurple", label: "cancellato" },
  ERROR: { color: "RustyRed", label: "errore" },
};

const DistributionPackagePanel = ({
  holderId,
  identifier,
  metadata,
  referenceYear,
  status,
  description,
  typology,
  ...rest
}) => {
  const [user] = useRecoilState(userAtom);
  const distributionPackageDownload = useDistributionPackageDownload(
    user.token,
    identifier,
    holderId,
    v4()
  );

  return (
    <Container {...rest}>
      <Header>
        <StatusContainer>
          <Status color={statusMapping[status].color} type={"ghost"}>
            {statusMapping[status].label}
          </Status>
          {status === "CANCELLED" && (
            <StatusDescription variant={"bodyRegular"}>
              {description}
            </StatusDescription>
          )}
        </StatusContainer>
        {status === "DONE" && (
          <DownloadIcon
            isFetching={distributionPackageDownload.isFetching}
            isError={distributionPackageDownload.isError}
            onClick={() => distributionPackageDownload.refetch()}
            tooltipTextIfEnabled={"Download"}
          />
        )}
      </Header>
      <ReferenceYear variant="h3">{referenceYear}</ReferenceYear>
      <Identifier variant="bodyRegular">{`Id: ${identifier}`}</Identifier>
      <Title data-testid={"title"} variant="h4">
        {`Pacchetto di distribuzione: ${typologiesSpecializationsMap[typology].description}`}
      </Title>
      <MetadataContainer>
        {Object.keys(metadata).map((key) => (
          <Metadata key={key}>
            <Label data-testid={"label"} variant="descriptive">
              {metadata[key].label}
            </Label>
            <Value data-testid={"value"} variant="descriptive">
              {key.toLowerCase().indexOf("data") !== -1 ||
              key.toLowerCase().indexOf("date") !== -1
                ? `${new Date(
                    metadata[key].value
                  ).toLocaleDateString()} ${new Date(
                    metadata[key].value
                  ).toLocaleTimeString()}`
                : metadata[key].value}
            </Value>
          </Metadata>
        ))}
      </MetadataContainer>
    </Container>
  );
};

DistributionPackagePanel.propTypes = {
  holderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  identifier: PropTypes.string.isRequired,
  metadata: PropTypes.object,
  referenceYear: PropTypes.number,
  status: PropTypes.string,
  typology: PropTypes.string.isRequired,
};

export default DistributionPackagePanel;
