import { Radio, Space } from "antd";
import PropTypes from "prop-types";
import React from "react";

import ErrorLabel from "../../../../../components/error-label";
import RadioNode from "./radio-node";

const CreationModeRadio = ({
  value,
  onChange,
  error,
  errorMessage,
  inputRef,
}) => {
  return (
    <Radio.Group value={value} onChange={onChange} ref={inputRef}>
      <Space direction="vertical">
        <Radio value={"A"}>
          <RadioNode
            title={"Creazione tramite software"}
            // TODO: link allegato linee guida
            description={
              "Creazione tramite l’utilizzo di strumenti software che assicurino la produzione di documenti nei formati previsti nell’Allegato 2 delle Linee Guida"
            }
          />
        </Radio>
        <Radio value={"B"}>
          <RadioNode
            title={"Digitalizzazione di documento cartaceo"}
            description={
              "Acquisizione di un documento informatico per via telematica o su supporto informatico, acquisizione della copia per immagine su supporto informatico di un documento analogico, acquisizione della copia informatica di un documento analogico"
            }
          />
        </Radio>
        <Radio value={"C"}>
          <RadioNode
            title={"Acquisizione tramite moduli o formulari"}
            description={
              "Memorizzazione su supporto informatico in formato digitale delle informazioni risultanti da transazioni o processi informatici o dalla presentazione telematica di dati attraverso moduli o formulari resi disponibili all’utente"
            }
          />
        </Radio>
        <Radio value={"D"}>
          <RadioNode
            title={"Generazione a partire da banche dati"}
            description={
              "Generazione o raggruppamento anche in via automatica di un insieme di dati o registrazioni, provenienti da una o più banche dati, anche appartenenti a più soggetti interoperanti, secondo una struttura logica predeterminata e memorizzata in forma statica."
            }
          />
        </Radio>
      </Space>
      {/* TODO: implementare errore nel componente vapor */}
      {error && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Radio.Group>
  );
};

CreationModeRadio.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.func,
};

export default CreationModeRadio;
