import axios from "axios";
import { useMutation } from "react-query";

import config from "../../../config";
import omitEmpty from "../../utils/omit-empty";

export const submitDistributionPackageRequest = async ({
  token,
  holderId,
  holderCountryCode,
  documentTypeCode,
  searchString,
  referenceYear,
  from,
  to,
  requestId,
}) => {
  if (!holderId || !documentTypeCode || !referenceYear || !token) {
    return;
  }

  return await axios.post(
    `${config.archiveApiBasePath}/v1/archive/${holderId}/pdd`,
    {
      ...omitEmpty({
        countryCode: holderCountryCode,
        documentTypeCode,
        searchString,
        referenceYear,
        from,
        to,
      }),
    },
    {
      headers: {
        Authorization: token,
        "X-App-Name": config.appName,
        "X-App-Version": config.appVersion,
        "X-Request-ID": requestId,
        "X-Correlation-ID": requestId,
      },
    }
  );
};

const useDistributionPackageRequest = (
  token,
  holderId,
  holderCountryCode,
  documentTypeCode,
  searchString,
  referenceYear,
  from,
  to,
  distributionPackageRequestId
) =>
  useMutation(() =>
    submitDistributionPackageRequest({
      token,
      holderId,
      holderCountryCode,
      documentTypeCode,
      searchString,
      referenceYear,
      from: from ? from.startOf("day").valueOf() : null,
      to: to ? to.endOf("day").valueOf() : null,
      requestId: distributionPackageRequestId,
    })
  );

export default useDistributionPackageRequest;
