import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag, Typography } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  box-shadow: 0px 0px 7px 0px #bfbfbf;
  ${(props) => props.theme.colors.background.whiteSmoke};
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
  padding: ${({ theme }) => `${theme.spacing.unit}px`};
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const SpacedTag = styled(Tag)`
  margin-right: 40px;
`;

export const StatusInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentDate = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.quickSilver};
  margin-right: 40px;
`;

export const ReceivedDate = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.yankeesBlue};
`;

export const BoldText = styled(Typography)`
  font-weight: 900;
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.accent.islamicGreen};
  margin-right: 8px;
`;
