import { Button, Input } from "@ts-digital/vrc";
import styled from "styled-components";

import FaIcon from "../../../../../components/fa-icon";

export const PreviousDocumentContainer = styled.div`
  height: 72px;
  display: grid;
  justify-items: start;
  grid-column-gap: 32px;
  grid-template-columns: 7fr 1fr;
`;

export const AddPreviousDocumentGrid = styled.div`
  height: 72px;
  display: grid;
  justify-items: start;
`;

export const IdInput = styled(Input)`
  width: 100%;
`;

export const AddPreviousDocumentButton = styled(Button)`
  height: 40px;
`;

export const AddIcon = styled(FaIcon)`
  font-size: 16px;
  margin-right: 12px;
`;
