import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";
import { Controller } from "react-hook-form";

import countries from "../../../../../../../../common/countries-code.json";
import { sdiCode, taxId } from "../../../../../../../../utils/regex";
import ErrorLabel from "../../../../../../../components/error-label";

import { Container, EmailsInput, GridInput, GridSelect } from "./styled";

const PfInputs = ({ control, index, item, unregister }) => {
  useEffect(() => {
    return function cleanup() {
      unregister(
        [
          `details.soggetti.${index}.cognome`,
          `details.soggetti.${index}.nome`,
          `details.soggetti.${index}.codice_fiscale`,
          `details.soggetti.${index}.matricola`,
          `details.soggetti.${index}.id_paese`,
          `details.soggetti.${index}.codice_sdi`,
          `details.soggetti.${index}.indirizzi_digitali_riferimento`,
        ],
        { keepValue: false }
      );
    };
  }, [index, unregister]);

  return (
    <Container>
      <Controller
        name={`details.soggetti.${index}.cognome`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.cognome || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"lastName"}
            id={`details.soggetti.${index}.cognome`}
            label={"Cognome"}
            placeholder={"Inserisci il cognome"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Inserisci il cognome del soggetto"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.nome`}
        control={control}
        rules={{ required: true }}
        defaultValue={item.nome || ""}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"firstName"}
            id={`details.soggetti.${index}.nome`}
            label={"Nome"}
            placeholder={"Inserisci il nome"}
            required={true}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Inserisci il nome del soggetto"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_fiscale`}
        control={control}
        defaultValue={item.codice_fiscale || ""}
        rules={{ pattern: taxId }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"taxId"}
            id={`details.soggetti.${index}.codice_fiscale`}
            label={"Codice fiscale"}
            placeholder={"Inserisci il codice fiscale"}
            error={!!error}
            errorMessage={
              <ErrorLabel>{"Codice fiscale non valido"}</ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.matricola`}
        control={control}
        defaultValue={item.matricola || ""}
        render={({ field }) => (
          <GridInput
            area={"registrationNumber"}
            id={`details.soggetti.${index}.matricola`}
            label={"Matricola"}
            placeholder={"Inserisci il numero di matricola"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.id_paese`}
        control={control}
        defaultValue={item.id_paese || "IT"}
        render={({ field }) => (
          <GridSelect
            area={"country"}
            label={"Paese"}
            options={countries}
            placeholder={"Scegli"}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.codice_sdi`}
        control={control}
        defaultValue={item.codice_sdi || ""}
        rules={{ pattern: sdiCode }}
        render={({ field, fieldState: { error } }) => (
          <GridInput
            area={"sdiCode"}
            id={`details.soggetti.${index}.codice_sdi`}
            label={"Codice SDI"}
            placeholder={"Inserisci il codice destinatario SDI"}
            error={!!error}
            errorMessage={
              <ErrorLabel>
                {"Il formato del valore non è corretto, esempio: 'abc1234'"}
              </ErrorLabel>
            }
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
      <Controller
        name={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
        control={control}
        defaultValue={item.indirizzi_digitali_riferimento || []}
        render={({ field }) => (
          <EmailsInput
            id={`details.soggetti.${index}.indirizzi_digitali_riferimento`}
            label={"Indirizzi digitali di riferimento"}
            placeholder={"Inserisci un indirizzo e-mail"}
            maximum={5}
            onChange={field.onChange}
            values={field.value}
          />
        )}
      />
    </Container>
  );
};

PfInputs.propTypes = {
  control: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  unregister: PropTypes.func.isRequired,
};

export default PfInputs;
