import { Button } from "@ts-digital/vrc";
import styled from "styled-components";

import IconButton from "../../../components/icon-button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PreviewContainer = styled.iframe`
  border: none;
  height: 80vh;
`;

export const DownloadIcon = styled(IconButton)`
  margin-right: 8px;
`;

export const DownloadButton = styled(Button)`
  width: 200px;
  margin-bottom: 16px;
  margin-top: 24px;
`;
