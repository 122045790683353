const getReferenceYearRange = () => {
  const currentYear = new Date().getFullYear();
  const getReferenceYearRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  return getReferenceYearRange(currentYear + 1, 2000, -1);
};

export default getReferenceYearRange;
