const omitEmpty = (object) =>
  typeof object === "object" &&
  object &&
  (!object instanceof Date || !object._isAMomentObject)
    ? Object.keys(object).reduce(
        (acc, curr) =>
          object[curr] !== null &&
          object[curr] !== undefined &&
          object[curr] !== ""
            ? {
                ...acc,
                [curr]:
                  typeof object[curr] === "object"
                    ? Array.isArray(object[curr])
                      ? object[curr].map((k) => omitEmpty(k))
                      : omitEmpty(object[curr])
                    : object[curr],
              }
            : acc,
        {}
      )
    : object;

export default omitEmpty;
