import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-template-areas: "name name";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 88px;
`;

export const GridInput = styled(Input)`
  grid-area: ${({ area }) => area};
`;
