import {
  faFileDownload,
  faCircleNotch,
} from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ErrorModal from "../error-modal";
import { DownloadIcon, LoadingIcon } from "./styled";

const FileDownloadIcon = ({
  isDisabled,
  isError,
  isFetching,
  onClick,
  tooltipTextIfEnabled,
  tooltipTextIfDisabled,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
    }
  };

  return (
    <div {...props}>
      <ErrorModal
        message={"Errore nel download del file."}
        isVisible={isError && isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Tooltip
        title={isDisabled ? tooltipTextIfDisabled : tooltipTextIfEnabled}
      >
        <div>
          {isFetching ? (
            <LoadingIcon data-testid={"loading"} icon={faCircleNotch} />
          ) : (
            <DownloadIcon
              onClick={handleClick}
              data-testid={"download"}
              icon={faFileDownload}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

FileDownloadIcon.propTypes = {
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isFetching: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  tooltipTextIfEnabled: PropTypes.string,
  tooltipTextIfDisabled: PropTypes.string,
};

export default FileDownloadIcon;
