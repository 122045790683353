import { Input } from "@ts-digital/vrc";
import styled from "styled-components";

import LabeledToggle from "../../../../components/labeled-toggle";

export const Container = styled.div`
  width: 100%;
  margin-right: 64px;
`;

export const Spacing = styled.div`
  margin-bottom: 48px;
`;

export const AuditGridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "name version"
    "software software";
  grid-template-columns: minmax(200px, 3fr) minmax(200px, 1fr);
  grid-template-rows: 88px 88px;
  column-gap: 32px;
`;

export const NameInput = styled(Input)`
  grid-area: name;
`;

export const VersionInput = styled(Input)`
  grid-area: version;
`;

export const SoftwareInput = styled(Input)`
  grid-area: software;
`;

export const SpacedLabeledToggle = styled(LabeledToggle)`
  margin-bottom: 32px;
`;
