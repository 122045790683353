import React from "react";
import PropTypes from "prop-types";

import { Container, Title, Subtitle } from "./styled.js";

const SectionHeader = ({ title, subtitle }) => (
  <Container>
    <Title variant="bodyLarge">{title}</Title>
    <Subtitle variant="bodyRegular">{subtitle}</Subtitle>
  </Container>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default SectionHeader;
