import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.yankeesBlue};
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.cadet};
  font-weight: normal;
`;
