import { Typography } from "@ts-digital/vrc";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LoadingText = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary.darkImperialBlue};
`;
